import { dateFormat, dayDifference } from 'utils';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import React from 'react';

export default ({ data }) => {
  const { support_sfc_sim, usages, expiresAt } = data;

  return (
    <>
      {support_sfc_sim ? (
        <>
          {!isEmpty(usages) ? (
            <div className={cx('flex pl-1 ml-auto', { 'text-danger': dayDifference(expiresAt) < 0 })}>
              <span className="flex items-center">
                {dayDifference(expiresAt) >= 0 ? dateFormat(expiresAt) : 'Expired'}
              </span>
            </div>
          ) : (
            <>-</>
          )}
        </>
      ) : (
        <>-</>
      )}
    </>
  );
};
