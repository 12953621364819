import React, { forwardRef, useEffect, useRef } from 'react';
import shortid from 'shortid';

const TableCheckbox = forwardRef(({ indeterminate, ...rest }, ref, id = shortid.generate()) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div className="check-box" style={{ top: '2px' }}>
      <input type="checkbox" id={id} ref={resolvedRef} {...rest} />
      <label htmlFor={id}></label>
    </div>
  );
});

export default TableCheckbox;
