import { getFormatedStringFromDays, quotaSizeFromGB } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Modal, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import Loading from 'components/common/Loading';
import React from 'react';

const PoolPlanTopup = ({ onToggle, saStore, poolPlanStore }) => {
  const methods = useForm();
  const { handleSubmit, watch, register } = methods;
  const productId = watch();

  const planData = React.useMemo(() => saStore.poolPlan, [saStore.poolPlan]);
  const isLoading = React.useMemo(() => saStore.isLoading, [saStore.isLoading]);

  React.useEffect(() => {
    saStore.getPoolProducts();
  }, [saStore]);

  const onSave = async ({ productId }) => {
    const payload = {
      planID: productId,
    };

    poolPlanStore
      .topup(payload)
      .then(() => (window.location.href = process.env.REACT_APP_ESTORE_CART))
      .catch((err) => {
        if (err?.errors[0]?.message?.errorCode === 400) {
          window.location.href = process.env.REACT_APP_ESTORE_CART;
        }
      })
      .finally(() => {
        onToggle();
      });
  };
  return (
    <Modal containerClass="bg-black with-scroll" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalHeader onToggle={onToggle}></ModalHeader>
        {isLoading && (
          <div div className="pt-6 pb-2">
            <Loading />
          </div>
        )}
        {!isLoading && (
          <>
            {!isEmpty(planData.content) ? (
              <div className="px-8 text-white">
                <h3 className="modal-title">Select Peplink eSIM Data Plans Pooled Plan</h3>
                <p className="mb-6">You will be redirected to Peplink eStore to check out.</p>
                <table className="table w-full simple-responsive data-plans">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col" className="col-right">
                        eSIM Usage
                      </th>
                      <th scope="col" className="col-right">
                        Validity
                      </th>
                      <th scope="col" className="col-right col-price">
                        Listed Price ({planData.content[0].price.currency})
                      </th>
                      <th scope="col" className="col-right col-price">
                        Unit Price ({planData.content[0].price.currency})
                      </th>
                    </tr>
                  </thead>
                  <tbody className="mb-6">
                    {planData?.content?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <label htmlFor={item?.id} className="flex items-center">
                            <input
                              name="productId"
                              ref={register({ required: true })}
                              checked={item?.checked}
                              className="mr-2"
                              type="radio"
                              methods={methods}
                              defaultValue={item?.id}
                            />
                            <span>{item?.productCode}</span>
                          </label>
                        </td>
                        <td data-label="Usage" className="text-right">
                          {quotaSizeFromGB(item?.usage)}
                        </td>
                        <td data-label="Lifetime" className="text-right">
                          {getFormatedStringFromDays(item?.lifetime)}
                        </td>
                        <td
                          data-label={`Listed Price (${planData?.content[0]?.price?.currency})`}
                          className="text-right line-through"
                        >
                          &nbsp;&nbsp;{item?.price?.amount}&nbsp;&nbsp;
                        </td>
                        <td data-label={`Unit Price (${planData?.content[0]?.price?.currency})`} className="text-right">
                          {item?.discountedPrice ? item?.discountedPrice?.amount : item?.price?.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="px-8 text-white">
                <h3>Top up is not available in your region. Please try again later.</h3>
              </div>
            )}

            {!isEmpty(planData?.content) ? (
              <div className="flex items-center justify-end mr-6">
                <div className="p-6">
                  <button onClick={() => onToggle()} className="ml-auto btn btn-modal">
                    Cancel
                  </button>
                  <button disabled={!productId.productId} className="ml-2 btn btn-modal">
                    Add to cart
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-end p-6">
                <button onClick={() => onToggle()} className="ml-auto uppercase btn btn-modal">
                  OK
                </button>
              </div>
            )}
          </>
        )}
      </form>
    </Modal>
  );
};
export default inject(({ saStore, poolPlanStore }) => ({ saStore, poolPlanStore }))(observer(PoolPlanTopup));
