import { sortBy } from 'lodash';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, register, handleFilters, items }) => {
  const [modelOptions, setModelOptions] = React.useState([]);

  React.useEffect(() => {
    setModelOptions(
      sortBy(
        Object.values(
          items.reduce((acc, value) => {
            const product = value.product || {};
            return acc[product.name] ? { ...acc } : { ...acc, ...(product.name && { [product.name]: product.name }) };
          }, {})
        ).map((i) => ({ label: i, value: i })),
        (i) => i.label
      )
    );
  }, [items]);

  return (
    <FilterGroup label="Model" isLoading={isLoading}>
      <select
        id="select-model"
        name="model"
        ref={register()}
        onChange={(e) => handleFilters('product.name', e.target.value)}
      >
        <option value="">All</option>
        {modelOptions.map((i) => (
          <option key={`model-${i.value}`} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
    </FilterGroup>
  );
};
