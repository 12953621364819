import { dayDifference } from 'utils';
import { sortBy, uniqBy } from 'lodash';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, register, onSliderChange, items }) => {
  const [lifetimeOptions, setLifetimeOptions] = React.useState([]);

  React.useEffect(() => {
    let arr;
    arr = sortBy(
      Object.values(
        items
          .filter((i) => i.sfwan_license && i.sfwan_license.exp && dayDifference(i.sfwan_license.exp) >= 0)
          .reduce((acc, value) => {
            return acc[value.sfwan_license.exp]
              ? { ...acc }
              : { ...acc, [value.sfwan_license.exp]: value.sfwan_license.exp };
          }, {})
      ).map((i) => ({ label: new Date(i).getFullYear().toString(), value: new Date(i).getFullYear().toString() })),
      (i) => i.label
    );

    arr.push(
      {
        value: 'expired',
        label: 'Expired',
      },
      {
        value: 'nodate',
        label: 'No Expiry Date',
      }
    );

    setLifetimeOptions(uniqBy(arr, 'value'));
  }, [items]);

  return (
    <FilterGroup label="Expiration" isLoading={isLoading}>
      <select name="lifetime" ref={register()} onChange={(e) => onSliderChange(e.target.value, 'lifetime')}>
        <option value="">All</option>
        {lifetimeOptions.map((i) => (
          <option key={`lifetime-${i.value}`} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
    </FilterGroup>
  );
};
