import { ESIM_DATA_COL_NAME, PRODUCT_INPUT_NAME } from 'definitions';
import { flatten, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import Connect5GLTEProductTable from 'components/TopUp/components/Connect5GLTEProductTable';
import cx from 'classnames';
import Loading from 'components/common/Loading';
import React from 'react';

const Connect5GLTETopup = ({ onToggle, prepaidStore, saStore }) => {
  const methods = useForm();
  const { handleSubmit, watch, register, reset } = methods;
  const productId = watch();

  const [isAllowed, setIsAllowed] = React.useState(true);
  // localToggle is true show US plans, otherwise show global plans
  const [localToggle, setLocalToggle] = React.useState(true);

  const planDataUs = React.useMemo(() => saStore.connect5GLteUs, [saStore.connect5GLteUs]);
  const planDataGlobal = React.useMemo(() => saStore.connect5GLteGlobal, [saStore.connect5GLteGlobal]);
  const isLoading = React.useMemo(() => saStore.isLoading, [saStore.isLoading]);

  const onSave = async ({ eSimPlanUs, eSimPlanGlobal }) => {
    const productId = eSimPlanUs ? eSimPlanUs : eSimPlanGlobal;
    saStore.setProductId(productId);
    saStore.setSNS(flatten(prepaidStore.selectedConnectDevices.map((i) => i.selected)));
    saStore
      .post(false)
      .then((res) => {
        if (res?.data?.eSIMDetails == null) {
          onToggle();
          window.location.href = process.env.REACT_APP_ESTORE_CART;
          return;
        }

        let productCode = '';
        if (eSimPlanUs) {
          productCode = planDataUs.content.filter((plan) => plan.id === eSimPlanUs)[0].productCode;
        } else if (eSimPlanGlobal) {
          productCode = planDataGlobal.content.filter((plan) => plan.id === eSimPlanGlobal)[0].productCode;
        } else {
          throw { error: true, statusCode: 400, message: ['No eSIM plan was selected.'] };
        }

        onToggle();
        window.location.href = `${
          process.env.REACT_APP_ESTORE
        }/products/${productCode.toLowerCase()}?serial=${saStore.sns.join(',')}&addToCart=true`;
        return;
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 400:
            prepaidStore.setServerErrors(err?.message);
            break;
          default:
            prepaidStore.setServerErrors([err?.errors[0]?.message?.errorMessages[0]?.message]);
        }
        onToggle();
      });
  };

  React.useEffect(() => {
    saStore.get();
  }, [saStore]);

  React.useEffect(() => {
    setIsAllowed(!prepaidStore.selectedConnectDevices?.map((i) => i?.support_sfc_sim).some((i) => i === true));
  }, [prepaidStore.selectedConnectDevices]);

  React.useEffect(() => {
    reset();
  }, [localToggle, reset]);

  return (
    <Modal containerClass="bg-black with-scroll" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalHeader onToggle={onToggle}></ModalHeader>

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {!isEmpty(planDataUs.content) || !isEmpty(planDataGlobal.content) ? (
              <div className="px-8 text-white">
                <h3 className="modal-title">Select Peplink eSIM Data Plan</h3>
                <p className="mb-6">
                  Choose your Peplink eSIM Data Plan. You will be redirected to Peplink eStore to check out.
                </p>

                <div className="flex mb-6">
                  <div
                    className={cx(
                      'flex items-center w-auto px-2 py-2 mr-2 text-xl font-semibold rounded-md cursor-pointer md:px-4 md:mr-4 justify-items-center',
                      {
                        'text-black': localToggle,
                        'bg-primary': localToggle,
                        'text-white': !localToggle,
                        'bg-black': !localToggle,
                        'border-2': !localToggle,
                        'border-gray-500': !localToggle,
                      }
                    )}
                    onClick={() => setLocalToggle(true)}
                  >
                    <i
                      className={cx('md:mr-2 icons', {
                        icon_check_circle_black: localToggle,
                        icon_check_circle_white: !localToggle,
                      })}
                    ></i>
                    <div className="hidden md:block">NA & EU eSIM Data</div>
                  </div>

                  <div
                    className={cx(
                      'flex items-center w-auto px-2 py-2 mr-2 text-xl font-semibold rounded-md cursor-pointer md:px-4 md:mr-4 justify-items-center',
                      {
                        'text-black': !localToggle,
                        'bg-primary': !localToggle,
                        'text-white': localToggle,
                        'bg-black': localToggle,
                        'border-2': localToggle,
                        'border-gray-500': localToggle,
                      }
                    )}
                    onClick={() => setLocalToggle(false)}
                  >
                    <i
                      className={cx('md:mr-2 icons', {
                        icon_globe_black: !localToggle,
                        icon_globe_white: localToggle,
                      })}
                    ></i>
                    <div className="hidden md:block">Global eSIM Data</div>
                  </div>
                </div>

                {localToggle ? (
                  <Connect5GLTEProductTable
                    planData={planDataUs}
                    methods={methods}
                    register={register}
                    dataColName={ESIM_DATA_COL_NAME.us}
                    inputName={PRODUCT_INPUT_NAME.eSimPlanUs}
                  />
                ) : (
                  <Connect5GLTEProductTable
                    planData={planDataGlobal}
                    methods={methods}
                    register={register}
                    dataColName={ESIM_DATA_COL_NAME.global}
                    inputName={PRODUCT_INPUT_NAME.eSimPlanGlobal}
                  />
                )}
              </div>
            ) : (
              <div className="px-8 text-white">
                <h3>Top up is not available in your region. Please try again later.</h3>
              </div>
            )}
            <div className="flex items-center justify-end mr-6">
              {(localToggle && !isEmpty(planDataUs.content)) || (!localToggle && !isEmpty(planDataGlobal.content)) ? (
                <div className="p-6">
                  <button onClick={() => onToggle()} className="ml-auto btn btn-modal">
                    Cancel
                  </button>
                  <button
                    disabled={(!productId.eSimPlanUs && !productId.eSimPlanGlobal) || isAllowed}
                    className="ml-2 btn btn-modal"
                  >
                    Add to cart
                  </button>
                </div>
              ) : (
                <div className="p-6">
                  <button onClick={() => onToggle()} className="ml-auto uppercase btn btn-modal">
                    OK
                  </button>
                </div>
              )}
            </div>
          </>
        )}
        <div className="mb-6">
          {isAllowed && (
            <div className="p-6 text-danger">
              <i className="text-lg material-icons">error_outline</i> Serial number is invalid or does not match the
              product, please verify (
              {prepaidStore.selectedConnectDevices
                ?.filter((i) => !i.support_sfc_sim)
                ?.map((i) => i.sn)
                ?.toString()}
              )
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default inject(({ prepaidStore, saStore }) => ({ prepaidStore, saStore }))(observer(Connect5GLTETopup));
