import IsPooledTitle from './IsPooledTitle';
import React, { useEffect, useState } from 'react';
import RemainingTitle from './RemainingTitle';
import ZeroRemainingTitle from './ZeroRemainingTitle';

export default ({ data }) => {
  const [totalUsage, setTotalUsage] = useState(0);
  const [totalQuota, setTotalQuota] = useState(0);

  useEffect(() => {
    let total = 0;
    if (data?.modules?.length > 0) {
      data.modules.forEach((module) => {
        if (typeof module.totalPeriodFreeKb === 'number') total += module.totalPeriodFreeKb;
      });
    }
    if (typeof data.totalPeriodFreeKb === 'number') total += data.totalPeriodFreeKb;

    setTotalUsage(total);
  }, [data.modules, data.totalPeriodFreeKb]);

  useEffect(() => {
    let total = 0;
    if (data?.modules?.length > 0) {
      data.modules.forEach((module) => {
        if (typeof module.totalPeriodQuotaKb === 'number') total += module.totalPeriodQuotaKb;
      });
    }
    if (typeof data.totalPeriodQuotaKb === 'number') total += data.totalPeriodQuotaKb;

    setTotalQuota(total);
  }, [data.modules, data.totalPeriodQuotaKb]);

  return (
    <>
      <>
        {data.isPool && <IsPooledTitle item={data} />}
        {!data.isPool && totalUsage !== 0 && (
          <RemainingTitle item={data} totalUsage={totalUsage} totalQuota={totalQuota} />
        )}
        {!data.isPool && totalUsage === 0 && <ZeroRemainingTitle item={data} />}
      </>
    </>
  );
};
