import { inject, observer } from 'mobx-react';
import { isArray } from 'lodash';
import React, { useMemo } from 'react';
import Table from './Table';

const ErrorPage = ({ data, onToggle }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Serial Number',
        accessor: 'sn',
        className: 'col-add-device-sn',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div className=" whitespace-no-wrap">{data.value}</div>
            {data?.row?.original?.type === 'module' && (
              <div
                className="text-gray-500 whitespace-no-wrap cursor-default hover:text-primary transition-all duration-300"
                style={{
                  fontSize: 10,
                }}
              >
                Expansion module
              </div>
            )}
          </div>
        ),
      },
      {
        Header: 'Model',
        accessor: 'product',
        className: 'col-add-device-model',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            {data?.value ? <div>{data?.value?.name}</div> : '-'}
          </div>
        ),
      },
      {
        Header: 'Device Name',
        accessor: 'name',
        className: 'col-postpaid-device-name',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div>{data.value || data?.row?.original?.masterName}</div>
          </div>
        ),
      },
      {
        Header: 'ICCID',
        id: 'iccid',
        className: 'col-postpaid-iccid',
        Cell: (data) => {
          const { iccids } = data?.row?.original;
          return isArray(iccids) && iccids.length > 0 ? (
            <div className="flex flex-col">
              {iccids.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          ) : (
            <div>-</div>
          );
        },
      },
    ],
    []
  );

  const onClose = () => {
    onToggle && onToggle(false);
  };

  return (
    <div>
      <div className="table-container">
        <div className="flex pl-2 mt-1 mb-5 gap-4">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.4019 4.5C16.5566 2.5 19.4434 2.5 20.5981 4.5L30.9904 22.5C32.1451 24.5 30.7017 27 28.3923 27H7.60769C5.29829 27 3.85492 24.5 5.00962 22.5L15.4019 4.5Z"
              fill="#FFB81C"
            />
            <path
              d="M19.3125 10.8555L19.0418 19.1488H17.073L16.7941 10.8555H19.3125ZM16.7285 21.7164C16.7285 21.3664 16.8488 21.0766 17.0895 20.8469C17.3355 20.6117 17.6582 20.4941 18.0574 20.4941C18.4621 20.4941 18.7848 20.6117 19.0254 20.8469C19.266 21.0766 19.3863 21.3664 19.3863 21.7164C19.3863 22.0555 19.266 22.3426 19.0254 22.5777C18.7848 22.8129 18.4621 22.9305 18.0574 22.9305C17.6582 22.9305 17.3355 22.8129 17.0895 22.5777C16.8488 22.3426 16.7285 22.0555 16.7285 21.7164Z"
              fill="black"
            />
          </svg>
          <div>
            An unexpected error occurred while adding the below Serial Numbers.
            <br />
            Please try to add again later.
          </div>
        </div>
        <Table tableType="addDevice" data={data} columns={columns} />
      </div>
      <div className="flex justify-end mt-10">
        <button className="ml-6 btn btn-outline-primary" onClick={() => onClose()}>
          Close
        </button>
      </div>
    </div>
  );
};

export default inject(({ poolPlanStore, prepaidStore, userStore }) => ({ poolPlanStore, prepaidStore, userStore }))(
  observer(ErrorPage)
);
