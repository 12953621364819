import { isEmpty } from 'lodash';
import { ROUTES } from 'definitions';
import Loading from 'components/common/Loading';
import React from 'react';

const POSTPAID = 'postpaid';
const POOL = 'pool';

export default ({ history }) => {
  const SF_CLOUD = localStorage.getItem(process.env.REACT_APP_APP_NAME);
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState();
  const [tokenValid, setTokenValid] = React.useState();

  const onClearStorage = () => {
    let consent = localStorage.getItem('consent');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('consent', consent);
  };

  const onLogout = async () => {
    onClearStorage();
    window.location.href = process.env.REACT_APP_LOGOUT_URL;
  };

  const handleActivate = async () => {
    setIsLoading(true);

    localStorage.removeItem('pool_notification');
    // for reset notification every login

    try {
      if (SF_CLOUD) {
        const { refresh_token, isIC2User, isPostpaidUser, landing } = JSON.parse(SF_CLOUD);

        let is_refresh_token_valid = false;
        const refresh_token_payload = JSON.parse(window.atob(refresh_token.split('.')[1]));
        is_refresh_token_valid = new Date(refresh_token_payload.exp * 1000) > new Date() || false;

        if (is_refresh_token_valid) {
          if (JSON.parse(isIC2User)) {
            window.scrollTo(0, 0);
            if (JSON.parse(isPostpaidUser) && landing === POSTPAID) {
              history.push(ROUTES.SIM);
            } else if (landing === POOL) {
              history.push(ROUTES.POOL_MANAGEMENT);
            } else {
              history.push(ROUTES.TOPUP);
            }
          } else {
            const confirmAction = window.confirm(
              'No associated device with PeplinkID. \nWould you like to associate your devices with PeplinkID?'
            );
            if (confirmAction) {
              document.location = process.env.REACT_APP_REDIRECT_IC2_PAGE;
            } else {
              document.location = process.env.REACT_APP_REDIRECT_ESTORE_PAGE;
            }
          }
        } else {
          onClearStorage();
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }
      } else {
        window.location.href = process.env.REACT_APP_LOGIN_URL;
      }
    } catch (e) {
      onClearStorage();
      window.location.href = process.env.REACT_APP_LOGIN_URL;
    }
  };

  React.useEffect(() => {
    // If SF_CLOUD isEmpty then clear storage then return.
    if (isEmpty(SF_CLOUD)) {
      onClearStorage();
      return;
    }

    const sfAuth = JSON.parse(SF_CLOUD);

    if (Number(sfAuth?.statusCode) === 200) {
      const { name, refresh_token } = JSON.parse(SF_CLOUD);
      if (name) setUser(name);
      let is_refresh_token_valid = false;
      const refresh_token_payload = JSON.parse(window.atob(refresh_token.split('.')[1]));
      is_refresh_token_valid = new Date(refresh_token_payload.exp * 1000) > new Date();
      setTokenValid(is_refresh_token_valid);
    } else if (Number(sfAuth?.statusCode) === 500) {
      alert('Invalid access token.');
      onClearStorage();
    }
  }, [SF_CLOUD]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <section className="h-screen activate-screen bg-primary">
        <header className="flex w-full">
          <nav className="flex items-center ml-auto">
            <a href="https://peplink.com" target="_blank" rel="noopener noreferrer">
              Peplink.com
            </a>
            {tokenValid ? (
              <div className="btn btn-logout" id="logout" onClick={onLogout}>
                Log out, {user}
              </div>
            ) : (
              <a className="btn btn-link" id="create_id" href={process.env.REACT_APP_SIGNUP_URL}>
                Create a Peplink ID
              </a>
            )}
          </nav>
        </header>
        <div className="absolute flex items-center w-full h-screen overflow-hidden hero-section">
          <div className="absolute w-full h-screen clouds">
            <i className="icons icon-graphics_cloud1" />
            <i className="icons icon-graphics_cloud2" />
            <div className="absolute inline-block cloud-group cloud-group-1">
              <i className="icons icon-graphics_cloud4 bg-cloud" />
              <i className="icons icon-graphics_cloud3" />
              <i className="icons icon-graphics_cloud4" />
            </div>
            <div className="absolute inline-block cloud-group cloud-group-2">
              <i className="icons icon-graphics_cloud4 bg-cloud" />
              <i className="icons icon-graphics_cloud3" />
              <i className="icons icon-graphics_cloud4" />
            </div>
            <div className="absolute inline-block cloud-group cloud-group-3">
              <i className="icons icon-graphics_cloud4 bg-cloud" />
              <i className="icons icon-graphics_cloud3" />
              <i className="icons icon-graphics_cloud4" />
            </div>
          </div>
          <div className="container flex mx-auto">
            <div className="w-2/3 mx-auto">
              <img
                width="500"
                src="/assets/graphics_sf_services.svg"
                alt="SpeedFusion Services"
                className="mx-auto mb-3"
              />
              {/* <h1 className="mb-12 text-center">Introducing SpeedFusion Services</h1> */}
              <div className="text-center">
                <button
                  id="login"
                  className="relative items-center px-6 py-3 text-2xl font-semibold tracking-wider text-center uppercase bg-white btn"
                  onClick={handleActivate}
                >
                  {tokenValid ? 'Get In' : 'Login'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="md:text-xl learn-more">
        <p>Learn more about our</p>
        <div>
          <a
            className="btn btn-outline md:text-xl"
            href="https://www.peplink.com/services/products-sfc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            SpeedFusion Connect Usage Plan
          </a>
          <a
            className="btn btn-outline md:text-xl"
            href="https://www.peplink.com/services/products-esim-data-plans/"
            target="_blank"
            rel="noopener noreferrer"
          >
            eSIM Data Plans
          </a>
        </div>
      </div>
    </>
  );
};
