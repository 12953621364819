import { quotaGBSizeFromKB } from 'utils';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ poolUsage }) => {
  return (
    <>
      {/* inside pooled */}
      <div className="flex items-center justify-between sfc-card-title">
        <div className="flex items-center">eSIM Quota</div>
      </div>
      <div className="sfc-card-content">
        {poolUsage > 0 ? (
          <div className="flex items-center">
            <i className="mr-3 icons icon-sim-data-normal" />
            <div className="items-end">
              <span className="remaining-sfc-usage-number text-primary">
                {quotaGBSizeFromKB(poolUsage).resultNum + ' '}
              </span>
              <span className="remaining-sfc-usage-type text-primary">{quotaGBSizeFromKB(poolUsage).resultDim}</span>
            </div>
          </div>
        ) : (
          <Tooltip
            placement="bottom"
            trigger="hover"
            tooltip={
              <div>
                <span className="tooltip-firmware">Your quota is used up.</span>
                <br />
                <span className="tooltip-firmware">Top up Pooled Plan now!</span>
              </div>
            }
          >
            <div className="flex items-center">
              <i className="mr-3 icons icon-sim-data" />
              <div className="items-end cursor-pointer">
                <span className="remaining-sfc-usage-number sn-expire-text-gray">0</span>
                <span className="remaining-sfc-usage-type sn-expire-text-gray">MB</span>
                <span className="mb-2 mr-1 material-icons icon-firmware-error md-16 text-danger">error_outline</span>
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};
