import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const Refresh = ({ userStore, prepaidStore }) => {
  const handleRefresh = async () => {
    await prepaidStore.invalidateItems();
    await prepaidStore.refreshIC2({}, userStore.viewAs);
    await prepaidStore.refresh({}, userStore.viewAs);
    await prepaidStore.getRoles(userStore.viewAs);
    await prepaidStore.getData(userStore.viewAs);
  };

  return (
    <Tooltip
      placement="top"
      trigger="hover"
      tooltip={<div className="tooltip-refresh">Refresh to view the newly added devices</div>}
    >
      <button
        className={cx('btn btn-refresh flex items-center bg-white text-primary mr-1 whitespace-no-wrap', {
          'cursor-not-allowed': prepaidStore.items.isLoading,
        })}
        disabled={prepaidStore.items.isLoading}
        onClick={handleRefresh}
      >
        <i className="mr-1 material-icons md-22">sync</i>
        Refresh Devices
      </button>
    </Tooltip>
  );
};

export default inject(({ userStore, prepaidStore }) => ({ userStore, prepaidStore }))(observer(Refresh));
