import { dayDifference, quotaSize } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Checkbox from 'components/common/form/Checkbox';
import cx from 'classnames';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const HasQuota = ({ item, prepaidStore, userStore }) => {
  const quota = React.useMemo(() => quotaSize(item?.sfwan_license?.quota_mb, item?.sfwan_license?.latest_top_up_mb), [
    item.sfwan_license.latest_top_up_mb,
    item.sfwan_license.quota_mb,
  ]);

  const checkUnlimited = (item) => {
    if (
      item?.care_summary_general_type !== null &&
      new Date() < new Date(item?.care_summary_general_expiration_date) &&
      parseFloat(item?.fw || item?.fw_ver) >= 8.3
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex items-center">
      {isEmpty(userStore.viewAs) && (
        <Checkbox
          onChange={() => prepaidStore.onSelectCloudDevice(item)}
          checked={prepaidStore.isCloudDeviceSelected(item)}
          className="mr-4"
        />
      )}
      <div>
        {/* less then 10% */}
        {quota.resultPercent > 0 && quota.resultPercent <= 10 ? (
          <>
            {/* high speed  */}
            {checkUnlimited(item) ? (
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={
                  <>
                    <div className="leading-tight text-white">SFC usage is nearly used up.</div>
                    <div className="leading-tight text-white">It is time to top up!</div>
                  </>
                }
              >
                <div className="flex items-center cursor-pointer">
                  <i className="mr-2 icons icon-5g-plan" />
                  <div>
                    <div className="items-end">
                      <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                      <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                      <span className="pt-2 pb-3 mr-1 material-icons icon-firmware-error md-12 text-danger">
                        error_outline
                      </span>
                    </div>
                    <span className="text-primary">(up to 200Mbps)</span>
                  </div>
                </div>
              </Tooltip>
            ) : (
              // normal speed
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={
                  <>
                    <div className="leading-tight text-white">SFC usage is nearly used up.</div>
                    <div className="leading-tight text-white">It is time to top up!</div>
                  </>
                }
              >
                <div className="flex items-end cursor-pointer">
                  <i className={cx('icons mr-2', quota.iconColor)} />
                  <div className="items-end">
                    <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                    <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                  </div>
                  <span className="py-2 mr-1 material-icons icon-firmware-error md-12 text-danger">error_outline</span>
                </div>
              </Tooltip>
            )}
          </>
        ) : item?.sfwan_license?.quota_mb > 0 ? (
          // high speed
          <div className="flex flex-center">
            {checkUnlimited(item) && dayDifference(item?.sfwan_license?.exp) >= 0 ? (
              <div className="flex items-center">
                <i className="mr-2 icons icon-5g-plan" />
                <div>
                  <div className="items-end">
                    <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                    <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                  </div>
                  <span className="text-primary">(up to 200Mbps)</span>
                </div>
              </div>
            ) : checkUnlimited(item) ? (
              <div className="flex items-center">
                <i className="mr-2 icons icon-5g-plan" />
                <div className="sfc-list-view-content">
                  <span className="remaining-sfc-usage-type text-primary">Fair Usage Policy</span>
                  <br />
                  <span className="text-primary">(up to 10Mbps)</span>
                </div>
              </div>
            ) : (
              // normal speed
              <div className="flex items-center">
                <i className={cx('icons mr-2', quota.iconColor)} />
                <div className="items-end">
                  <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                  <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {checkUnlimited(item) ? (
              // 0 MB left with fair usage policy
              <div className="flex items-center">
                <i className="mr-2 icons icon-5g-plan" />
                <div className="sfc-list-view-content">
                  <span className="remaining-sfc-usage-type text-primary">Fair Usage Policy</span>
                  <br />
                  <span className="text-primary">(up to 10Mbps)</span>
                </div>
              </div>
            ) : (
              // 0 MB left
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={
                  <div>
                    <span className="tooltip-firmware">Your quota is used up.</span>
                    <br />
                    <span className="tooltip-firmware">Top up SpeedFusion Connect Usage Plan now!</span>
                  </div>
                }
              >
                <div className="flex items-center cursor-pointer">
                  <i className={cx('icons mr-2', quota.iconColor)}></i>
                  <div className="items-end">
                    <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                    <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                    <span className="py-2 mr-1 material-icons icon-firmware-error md-12 text-danger">
                      error_outline
                    </span>
                  </div>
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(HasQuota));
