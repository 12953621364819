import { ANOTHER_CARRIER, FILTER_STATUS, PLAN, POOL_PLAN, ROUTES, SIM_STATUS } from 'definitions';
import { differenceInDays, format, parseISO } from 'date-fns';
import { isPast } from 'date-fns';
import moment from 'moment';
import semver from 'semver';
import XLSX from 'xlsx';

const cleanFW = (fw) => {
  const regex = /\d+\.\d+\.\d+/g;
  let match = regex.exec(fw);
  return match[0];
};

export const filterLessThan = (rows, id, filterValue) => {
  return rows.filter((row) => {
    var rowValue = differenceInDays(parseISO(row.values.service_exp), new Date());
    if (!rowValue) rowValue = differenceInDays(parseISO(row.values[id]), new Date());
    if (parseInt(filterValue) !== 0) {
      return rowValue <= parseInt(filterValue) && rowValue > 0;
    } else {
      return rowValue < 0;
    }
  });
};

export const filterLessThanQuota = (rows, id, filterValue) => {
  return rows.filter((row) => {
    if (row.original.sfwan_license) {
      var rowValue = row.original.sfwan_license.quota_mb;
      if (parseInt(filterValue) !== 0) {
        return id[0] === 'sfwan_license.quota_mbFrom'
          ? rowValue >= parseInt(filterValue)
          : rowValue < parseInt(filterValue);
      } else {
        return rowValue < 0;
      }
    } else {
      if (filterValue === parseInt(process.env.REACT_APP_MAX_QUOTA)) return row;
      else if (filterValue === '0') return row;
      else return !row;
    }
  });
};

export const filterUsageSim = (rows, id, filterValue) => {
  return rows.filter((row) => {
    if (row.original.usageMb) {
      var rowValue = row.original.usageMb;
      if (parseInt(filterValue) !== 0) {
        return id[0] === 'usage_mb_from' ? rowValue >= parseInt(filterValue) : rowValue < parseInt(filterValue);
      } else {
        return rowValue < 0;
      }
    } else {
      if (filterValue === parseInt(process.env.REACT_APP_MAX_QUOTA)) return row;
      else if (filterValue === '0') return row;
      else return !row;
    }
  });
};

export const dateInBetween = (from, to, check) => {
  var fDate, lDate, cDate;
  fDate = new Date(from);
  lDate = new Date(to);
  cDate = new Date(check);

  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
};

export const filterBySelect = (rows, id, filterValue) => {
  return rows.filter((row) => {
    var rowValue;
    if (row.original.sfwan_license && filterValue !== 'expired' && filterValue !== 'nodate') {
      let dateFrom = '',
        dateTo = '';

      if (new Date(filterValue).getFullYear() < '2021') {
        dateFrom = `01-01-${filterValue}`;
        dateTo = `01-01-${parseInt(filterValue) + 1}`;
      } else if (filterValue === '2021') {
        dateFrom = `${format(new Date(filterValue), 'dd-MM-yyyy')}`;
        dateTo = '01-01-2022';
      } else {
        dateFrom = `01-01-${filterValue}`;
        dateTo = `01-01-${parseInt(filterValue) + 1}`;
      }
      rowValue = dateInBetween(dateFrom, dateTo, row.values.service_exp) && row.original;
    } else if (row.original.sfwan_license && filterValue === 'expired') {
      rowValue = dayDifference(row.values.service_exp) < 0 && row.original;
    } else if (!row.original.sfwan_license && filterValue === 'nodate') {
      rowValue = row.original;
    }
    return rowValue;
  });
};

export const filterBySelectTopUp = (rows, id, filterValue) => {
  return rows.filter((row) => {
    var rowValue;
    if (row.original.sfwan_license && filterValue !== 'expired' && filterValue !== 'nodate') {
      let dateFrom = '',
        dateTo = '';

      if (new Date(filterValue).getFullYear() < '2021') {
        dateFrom = `01-01-${filterValue}`;
        dateTo = `01-01-${parseInt(filterValue) + 1}`;
      } else if (filterValue === '2021') {
        dateFrom = `${format(new Date(filterValue), 'dd-MM-yyyy')}`;
        dateTo = '01-01-2022';
      } else {
        dateFrom = `01-01-${filterValue}`;
        dateTo = `01-01-${parseInt(filterValue) + 1}`;
      }
      rowValue = dateInBetween(dateFrom, dateTo, row.values.sfwan_license.exp) && row.original;
    } else if (row.original.sfwan_license && filterValue === 'expired') {
      rowValue = dayDifference(row.values.sfwan_license.exp) < 0 && row.original;
    } else if (!row.original.sfwan_license && filterValue === 'nodate') {
      rowValue = row.original;
    }
    return rowValue;
  });
};

export const filterActivated = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return filterValue === PLAN.ACTIVATED // && dayDifference(row.original[row.original.plan].exp) < 0
      ? rowValue === true && dayDifference(row.original[row.original.plan].exp) > 0
      : rowValue === false || dayDifference(row.original[row.original.plan].exp) < 0;
  });
};

export const filterSubscribed = (rows, id, filterValue) => {
  return rows.filter((row) => {
    return filterValue === POOL_PLAN.SUBSCRIBED
      ? row.original.sfwan_license && !isPast(new Date(row.original.sfwan_license.exp))
      : !row.original.sfwan_license || (row.original.sfwan_license && isPast(new Date(row.original.sfwan_license.exp)));
  });
};

export const filterFirmware = (rows, id, filterValue) => {
  return rows.filter((row) => {
    return filterValue === true ? parseFloat(row.original.fw_ver) < 8.1 : parseFloat(row.original.fw_ver) > 8.1;
  });
};

export const filterFirmwarePrepaid = (rows) => {
  return rows.filter((row) => {
    return parseFloat(row.original.fw_ver) < 8.1;
  });
};

export const filterStatusPostpaid = (rows, id, filterValue) => {
  return rows.filter((row) => {
    switch (filterValue) {
      case FILTER_STATUS.UNKNOWN:
        return row.original.status === SIM_STATUS.UNKNOWN;
      case FILTER_STATUS.IN_USE:
        return (
          row.original.status === SIM_STATUS.ONLINE ||
          (row.original.status === SIM_STATUS.OFFLINE && row.original.usageMb !== 0)
        );
      case FILTER_STATUS.NOT_IN_USE:
        return row.original.status === SIM_STATUS.OFFLINE && row.original.usageMb === 0;
      default:
        return true;
    }
  });
};

export const filterCarrierPostpaid = (rows, id, filterValue) => {
  return rows.filter((row) => {
    return filterValue === ANOTHER_CARRIER.value
      ? row.original.carrier === undefined
      : filterValue === row.original.carrier
      ? row.original
      : undefined;
  });
};

export const filterIccidPrepaid = (rows, id, filterValue) => {
  return rows.filter((row) => row.original?.iccids?.some((i) => i.startsWith(filterValue)));
};

export const filterQuotaSimPrepaid = (rows, id, filterValue) => {
  return rows.filter((row) => {
    return parseInt(row.original.totalPeriodFreeKb) > 0 && parseInt(row.original.totalPeriodFreeKb) <= filterValue
      ? row.original
      : undefined;
  });
};

export const dayDifference = (value) => differenceInDays(parseISO(value), new Date());

export const dateFormat = (date, formatString = 'd MMM yyyy') => {
  return date ? format(new Date(date), formatString) : '';
};

export const dayMonthYearDifference = (value) => {
  var a = moment(value);
  var b = moment();
  var years = a.diff(b, 'year');
  b.add(years, 'years');
  var months = a.diff(b, 'months');
  b.add(months, 'months');
  var days = a.diff(b, 'days');
  var remaining_exp_date = years > 0 ? years + ' years ' : '';
  remaining_exp_date += months > 0 ? months + ' months ' : '';
  remaining_exp_date += days > 0 ? days + ' days left' : '';

  return remaining_exp_date;
};

export const exportCSVFile = (items, fileName) => {
  var exportedFilename = fileName + '.csv' || 'export.csv';

  var blob = new Blob([items], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const exportXLSFile = (xls, fileName) => {
  var exportedFilename = fileName + '.xlsx' || 'export.xlsx';

  var workbook = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(xls.data);
  XLSX.utils.book_append_sheet(workbook, ws, 'License Key');
  XLSX.writeFile(workbook, exportedFilename, { type: 'file' });
};

export const quotaSize = (size, total) => {
  let resultNum;
  let resultDim = '';
  let availabilityColor = '';
  let percentageBox = '';

  if (size / 1024000 > 1) {
    resultNum = size / 1024000;
    resultDim = 'TB';
  } else if (size / 1024 > 1) {
    resultNum = size / 1024;
    resultDim = 'GB';
  } else {
    resultNum = size;
    resultDim = 'MB';
  }

  let textColor = '';
  let iconColor = '';
  let resultPercent = (size * 100) / total;

  if (size >= 102400) {
    availabilityColor = 'text-black';
    textColor = 'text-primary';
    iconColor = 'icon-5g-plan';
    percentageBox = 'percentage-box-normal';
  } else if (size > 0 && size < 102400) {
    availabilityColor = 'text-black';
    textColor = 'sn-expire-text-gradient';
    iconColor = 'icon-5g-plan-red';
    percentageBox = 'percentage-box-gradient';
  } else if (size === 0 || resultPercent === 0 || total === undefined) {
    availabilityColor = 'sn-expire-text-gray';
    textColor = 'sn-expire-text-gray';
    iconColor = 'icon-5g-plan-dimmed';
    percentageBox = 'percentage-box-dimmed';
  }

  let res = {
    availability: availabilityColor,
    resultNum: Number(resultNum?.toFixed(resultNum > 99 ? 0 : 1)),
    resultDim: resultDim,
    resultPercent: resultPercent >= 100 ? 100 : resultPercent,
    textColor: textColor,
    iconColor: iconColor,
    percentageBox: percentageBox,
  };

  return res;
};

export const quotaMB = (size) => {
  let resultNum;
  let resultDim = '';

  if (size / 1024000 > 1) {
    resultNum = size / 1024000;
    resultDim = 'TB';
  } else if (size / 1024 > 1) {
    resultNum = size / 1024;
    resultDim = 'GB';
  } else {
    resultNum = size;
    resultDim = 'MB';
  }

  return Number(resultNum?.toFixed(resultNum > 99 ? 0 : 1)) + ' ' + resultDim;
};

export const quotaKB = (size) => {
  let resultNum;
  let resultDim = '';

  if (size / 1048576 >= 1) {
    resultNum = size / 1048576;
    resultDim = 'GB';
  } else if (size / 1024 >= 1) {
    resultNum = size / 1024;
    resultDim = 'MB';
  } else if (size === 0) {
    resultNum = 0;
    resultDim = 'MB';
  } else {
    resultNum = size;
    resultDim = 'KB';
  }

  return Number(resultNum?.toFixed(resultNum > 99 ? 0 : 1)) + ' ' + resultDim;
};

export const quotaGBSizeFromKB = (size) => {
  let resultNum;
  let resultDim = '';

  if (size / 1048576 >= 1) {
    resultNum = size / 1048576;
    resultDim = 'GB';
  } else if (size / 1024 >= 1) {
    resultNum = size / 1024;
    resultDim = 'MB';
  } else if (size === 0) {
    resultNum = 0;
    resultDim = 'MB';
  } else {
    resultNum = size;
    resultDim = 'KB';
  }

  let res = {
    resultNum: resultDim === 'GB' ? Number(resultNum.toFixed(1)) : Number(resultNum.toFixed(resultNum > 99 ? 0 : 1)),
    resultDim: resultDim,
  };

  let splitNum = res.resultNum.toString().split('.');
  if (splitNum.length > 1) {
    res.resultNum = `${splitNum[0]}.${splitNum[1].substring(0, 1)}`;
  }

  return res;
};

export const quotaTBSizeFromKB = (size) => {
  let resultNum;
  let resultDim = '';

  if (size / 1048576000 >= 1) {
    resultNum = size / 1048576000;
    resultDim = 'TB';
  } else if (size / 1048576 >= 1) {
    resultNum = size / 1048576;
    resultDim = 'GB';
  } else if (size / 1024 >= 1) {
    resultNum = size / 1024;
    resultDim = 'MB';
  } else if (size === 0) {
    resultNum = 0;
    resultDim = 'MB';
  } else {
    resultNum = size;
    resultDim = 'KB';
  }

  let res = {
    resultNum:
      resultDim === 'GB' || resultDim === 'TB'
        ? Number(resultNum.toFixed(1))
        : Number(resultNum.toFixed(resultNum > 99 ? 0 : 1)),
    resultDim: resultDim,
  };

  let splitNum = res.resultNum.toString().split('.');
  if (splitNum.length > 1) {
    res.resultNum = `${splitNum[0]}.${splitNum[1].substring(0, 1)}`;
  }

  return res;
};

export const maybePluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const isNewerVersion = (oldVer, newVer) => {
  return oldVer ? (semver.gte(cleanFW(oldVer), newVer) ? true : false) : false;
};

export const toMb = (quota) => {
  let mb;
  if (quota.type === 'TB') {
    mb = quota.amount * 1000000;
  } else if (quota.type === 'GB') {
    mb = quota.amount * 1024;
  } else if (quota.type === 'MB') {
    mb = quota.amount;
  }
  return mb;
};

export const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const quotaDimension = (mb) => {
  let size, type;
  if (mb / 1024000 > 1) {
    size = mb / 1024000;
    type = 'TB';
  } else if (mb / 1024 > 1) {
    size = mb / 1024;
    type = 'GB';
  } else {
    size = mb;
    type = 'MB';
  }
  return { quota: size ? Number(size.toFixed(size > 99 ? 0 : 1)) : 0, type: type };
};

export const quotaSizeFromGB = (size) => {
  let num = parseInt(size);
  let result = num / 1024;

  if (result >= 1) {
    result = parseInt(result);
    result += ' TB';
  } else {
    result = result * 1024;
    result = result.toFixed(2);
    result = parseInt(result);
    result += ' GB';
  }

  return result;
};

export const removeEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const getLastSixMonths = () => {
  let arr = [];
  var monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  var today = new Date();
  var d;
  var month;

  for (var i = 6; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = monthNames[d.getMonth()];
    arr.push({
      label: month + ' ' + d.getFullYear(),
      value: {
        from: format(d, 'yyyy-MM-dd'),
        to: format(new Date(d.getFullYear(), d.getMonth() + 1, 0), 'yyyy-MM-dd'),
      },
    });
  }
  return arr;
};

export const getFormatedStringFromDays = (numberOfDays) => {
  var toDisplay;
  if (numberOfDays >= 365)
    toDisplay = `${(numberOfDays / 365).toString().substr(0, 3)} ${numberOfDays / 365 > 1 ? 'years' : 'year'}`;
  else if (numberOfDays >= 30)
    toDisplay = `${(numberOfDays / 30).toString().substr(0, 3)} ${numberOfDays / 30 > 1 ? 'months' : 'month'}`;
  else toDisplay = `${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'} `;
  return toDisplay;
};

export const snLink = (item) => {
  if (item.device_on_ica && item.ica_server_name) {
    return `//${item.ica_server_name}/sn/${item.sn}`;
  } else {
    return `${ROUTES.IC2DD}/${item.sn}`;
  }
};

export const addQueryParams = (key, value, history) => {
  const pathname = history.location.pathname;
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.set(key, value);
  history.push({
    pathname: pathname,
    search: searchParams.toString(),
  });
};

export const removeQueryParams = (key, history) => {
  const pathname = history.location.pathname;
  const searchParams = new URLSearchParams(history.location.search);

  if (searchParams.has(key)) {
    searchParams.delete(key);
    history.replace({
      pathname: pathname,
      search: searchParams.toString(),
    });
  }
};

export const formatValueWithUnit = (value) => {
  const formattedValue = value.replace(/\s/g, '');
  const number = formattedValue.match(/\d+(?:\.\d+)?/);
  const unit = formattedValue.replace(/[0-9.,]/g, '');
  if (number === null) {
    return value;
  }
  return `${number[0]?.replace(',', '.')} ${unit}`;
};

export const noFirmwareRequirements = (sku) => {
  const MAX_ADAPTER = new RegExp(/MAX-ADP*/i);

  return MAX_ADAPTER.test(sku);
};

// PoolPlan

export const formatDate = (date) => {
  if (date) {
    return format(new Date(date), 'dd MMM yyyy');
  }
};

export const formatDateTime = (date) => {
  return format(new Date(date), 'dd MMM yyyy, HH:mm');
};

export const toFixed = (num) => {
  return num.toFixed(1);
};

export const percent = (percent) => {
  return `${(percent * 100).toFixed()}%`;
};

export const checkExpired = (expiry) => {
  const now = new Date();
  const exp = new Date(expiry);
  return now > exp;
};

export const DQPoolPlan = (quota, expiry) => {
  if (checkExpired(expiry) || quota <= 0) {
    return true;
  } else {
    return false;
  }
};

export const calculatePagesCount = (pageSize, totalCount) => {
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
};

export const encodedEmail = (email) => {
  return encodeURIComponent(email);
};
