import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'definitions';
import React from 'react';
import ServicePlans from './ServicePlans';
import UsageRecord from './UsageRecord';

export default () => (
  <Switch>
    <Route path={`/:root${ROUTES.SIM_PLANS}`} component={ServicePlans} />
    <Route path="/:root" component={UsageRecord} />
  </Switch>
);
