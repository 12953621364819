import { encodedEmail } from 'utils';
import { isEmpty } from 'lodash';

export default class PoolPlanServices {
  constructor(props) {
    this.url = '/pool';
    this.ic2url = '/ic2';
    this.http = props.http;
  }

  getOrganizations = (email) =>
    this.http.get(!isEmpty(email) ? `/organizations?email=${encodedEmail(email)}` : `/organizations`);

  getDevices = (orgCode, email) =>
    this.http.get(
      !isEmpty(email) ? `${this.url}/${orgCode}/devices?email=${encodedEmail(email)}` : `${this.url}/${orgCode}/devices`
    );

  getPool = (orgCode, email) =>
    this.http.get(email ? `${this.url}/${orgCode}?email=${encodedEmail(email)}` : `${this.url}/${orgCode}`);

  refresh = (orgCode, email) =>
    this.http.post(
      !isEmpty(email) ? `${this.url}/${orgCode}/refresh?email=${encodedEmail(email)}` : `${this.url}/${orgCode}/refresh`
    );

  getHistory = (orgCode, onlyTopUp) => this.http.get(`${this.url}/${orgCode}/history?topups=${onlyTopUp}`);

  getBalance = (orgCode) => this.http.get(`${this.url}/${orgCode}/balance`);

  topup = (orgCode, payload) => {
    return this.http.post(`${this.url}/${orgCode}/topup`, payload);
  };

  clearIC2Cache = (email) => {
    return this.http.post(
      !isEmpty(email) ? `${this.ic2url}/clearCache?email=${encodedEmail(email)}` : `${this.ic2url}/clearCache`
    );
  };

  addDevices = (orgCode, params) => {
    return this.http.put(`${this.url}/${orgCode}/add`, params);
  };

  removeDevices = (orgCode, params) => {
    return this.http.put(`${this.url}/${orgCode}/remove`, params);
  };

  onSubscribe = (payload) => {
    return this.http.post(`${this.url}`, payload);
  };

  updatePooledSetting = (orgCode, payload) => {
    return this.http.put(`${this.url}/${orgCode}`, payload);
  };

  setAutoTopUp = (orgCode, payload) => {
    return this.http.post(`${this.url}/${orgCode}/setAutoTopUp`, payload);
  };
}
