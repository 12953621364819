import { flatMap, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import ConnectProtectProductTable from './ConnectProtectProductTable';
import Loading from 'components/common/Loading';
import React from 'react';

const TopupNotAvailable = () => {
  return (
    <div className="px-8 text-white">
      <h3>Top up is not available in your region. Please try again later.</h3>
    </div>
  );
};

const BulkConnectTopUpModal = ({ onToggle, selected, onToggleConfirmPurchaseModal, prepaidStore, saStore }) => {
  const methods = useForm();

  const connectProtect = React.useMemo(() => saStore.connectProtect, [saStore.connectProtect]);
  const isLoading = React.useMemo(() => saStore.isLoading, [saStore.isLoading]);
  const selectedWithProductData = React.useMemo(() => {
    return selected.map((item) => prepaidStore.DATA.find((data) => data.sn === item.sn)).filter((i) => !isEmpty(i));
  }, [prepaidStore.DATA, selected]);

  const isAllowedConnectProtect = React.useMemo(() => {
    return selectedWithProductData.every((item) => item.top_up_plan_eligible);
  }, [selectedWithProductData]);

  const invalidSNForConnectProtect = React.useMemo(() => {
    return selectedWithProductData.filter((item) => !item.top_up_plan_eligible);
  }, [selectedWithProductData]);

  const { handleSubmit, watch, register } = methods;
  const productId = watch();

  const getData = React.useCallback(async () => {
    await saStore.get();
  }, [saStore]);

  const onSubmitProceed = (proceed = false) => {
    saStore
      .post(proceed)
      .then((res) => {
        if (isEmpty(res.data.sfcPlanDetails)) {
          onToggle();
          window.location.href = process.env.REACT_APP_ESTORE_CART;
        } else {
          saStore.setSfcPlanDetails(res.data.sfcPlanDetails);
          onToggle();
          onToggleConfirmPurchaseModal();
        }
      })
      .catch((err) => {
        onToggle();
        prepaidStore.setServerErrors(
          flatMap(
            err.errors.map((i) => {
              if (!isEmpty(i?.message?.errorMessages)) {
                return i.message.errorMessages.map((errorMsg) => errorMsg.message);
              }

              return i.message;
            })
          )
        );
      });
  };

  const onInitialSubmit = async ({ productId }) => {
    saStore.setProductId(productId);
    saStore.setSNS(selected.map((i) => i.sn));
    onSubmitProceed(false);
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal containerClass="bg-black" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onInitialSubmit)}>
        <ModalHeader onToggle={onToggle}></ModalHeader>
        {isLoading && <Loading />}
        {!isLoading && (
          <div className="px-8 text-white">
            <h3 className="modal-title">Select SpeedFusion Connect Usage Plan</h3>
            <p className="mb-6">You will be redirected to Peplink eStore to check out.</p>

            {isAllowedConnectProtect && (
              <>
                {!isEmpty(connectProtect) ? (
                  <ConnectProtectProductTable planData={connectProtect} methods={methods} register={register} />
                ) : (
                  <TopupNotAvailable />
                )}
              </>
            )}
          </div>
        )}

        {!isLoading && (
          <div>
            {!isAllowedConnectProtect && !isEmpty(invalidSNForConnectProtect) && (
              <div className="p-6 text-danger">
                <i className="text-lg material-icons">error_outline</i> Serial number is invalid or does not match the
                product, please verify ({invalidSNForConnectProtect.map((i) => i.sn).join(', ')})
              </div>
            )}
          </div>
        )}

        {!isLoading && (
          <div className="flex items-center justify-end mr-6">
            {(isEmpty(connectProtect) || !isAllowedConnectProtect) && (
              <div className="p-6">
                <button onClick={() => onToggle()} className="ml-auto uppercase btn btn-modal">
                  OK
                </button>
              </div>
            )}

            {isAllowedConnectProtect && (
              <div className="p-6">
                <button onClick={() => onToggle()} className="ml-auto btn btn-modal">
                  Cancel
                </button>
                <button disabled={isEmpty(productId.productId)} className="ml-2 btn btn-modal">
                  Add to cart
                </button>
              </div>
            )}
          </div>
        )}
      </form>
    </Modal>
  );
};

export default inject(({ prepaidStore, saStore }) => ({ prepaidStore, saStore }))(observer(BulkConnectTopUpModal));
