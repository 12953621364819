import { DEFAULT_VISIBILITY } from 'definitions';
import { inject, observer } from 'mobx-react';
import { toMb } from 'utils';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { usePopper } from 'react-popper';
import cx from 'classnames';
import DeviceName from 'components/TopUp/TopUp/components/Filter/DeviceName';
import Expiration from 'components/TopUp/TopUp/components/Filter/Expiration';
import Groups from 'components/TopUp/TopUp/components/Filter/Groups';
import ICCID from 'components/TopUp/TopUp/components/Filter/ICCID';
import Model from 'components/TopUp/TopUp/components/Filter/Model';
import Organizations from 'components/TopUp/TopUp/components/Filter/Organizations';
import React from 'react';
import SerialNumber from 'components/TopUp/TopUp/components/Filter/SerialNumber';
import Status from 'components/TopUp/TopUp/components/Filter/Status';
import UnusedConnectivityUsage from 'components/TopUp/TopUp/components/Filter/UnusedConnectivityUsage';
import UnusedSFCQuota from 'components/TopUp/TopUp/components/Filter/UnusedSFCQuota';
import useClickOutside from 'hooks/useClickOutside';

const Filter = ({ data, items, handleFilters, setFilters, filterDefaults, isLoading, prepaidStore }) => {
  const history = useHistory();
  const [maxQuota, setMaxQuota] = React.useState({ type: 'TB' });
  const [maxQuotaSim, setMaxQuotaSim] = React.useState({ type: 'TB' });
  const buttonRef = React.useRef(null);
  const popperRef = React.useRef(null);
  const placement = 'bottom-start';
  const [visible, setVisibility] = React.useState(false);
  const [deviceVisibility, setDeviceVisibility] = React.useState(DEFAULT_VISIBILITY);

  useClickOutside({
    onClose: () => {
      setVisibility(false);
    },
    elemRef: buttonRef,
  });

  const { styles, attributes, update } = usePopper(buttonRef.current, popperRef.current, {
    placement,
    strategy: 'fixed',
  });

  const methods = useForm();
  const { register, reset } = methods;

  const defaultValues = () => ({
    sn: null,
    'device-name': null,
    fw_ver: null,
    lifetime: '',
    grp_name: '',
    model: '',
    iccid: '',
    status: '',
    isDeviceHidden: DEFAULT_VISIBILITY,
  });

  const onSliderChange = (value, type) => {
    type === 'lifetime'
      ? handleFilters('sfwan_license.exp', value)
      : handleFilters('sfwan_license.quota_mb', value ? value : '0');
  };

  const handleUsage = (quota) => {
    handleFilters('sfwan_license.quota_mbTo', quota ? toMb(quota) : '0');
    setMaxQuota(quota);
  };

  const handleSimUsage = (quota) => {
    handleFilters('quotaSim', quota ? toMb(quota) : '0');
    setMaxQuotaSim(quota);
  };

  const handleResetFilters = React.useCallback(() => {
    reset(defaultValues());
    setFilters(filterDefaults());
    setMaxQuota({ type: 'TB', amount: '' });
    setMaxQuotaSim({ type: 'TB', amount: '' });
    setDeviceVisibility(DEFAULT_VISIBILITY);
  }, [filterDefaults, reset, setFilters]);

  const handleStatus = (e) => {
    if (e.target.value === 'LEGACY') {
      handleFilters('status', '');
      handleFilters('fw_ver', e.target.value);
    } else {
      handleFilters('fw_ver', '');
      handleFilters('status', e.target.value);
    }
  };

  const handleToggleMenu = () => {
    setVisibility((prevState) => !prevState);
  };

  const handleHiddenDevices = (value) => {
    setDeviceVisibility(value);
    handleFilters('isDeviceHidden', value);
  };

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      setVisibility(false);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  React.useEffect(() => {
    update && update();
  }, [update]);

  React.useEffect(() => {
    if (prepaidStore.selectedOrganization) {
      handleResetFilters();
    }
  }, [handleResetFilters, prepaidStore.selectedOrganization]);

  return (
    <>
      <div className="relative form-group-top-up dropdown" ref={buttonRef}>
        <div
          className={cx('cursor-pointer', {
            'cursor-not-allowed': isLoading,
          })}
        >
          <div className="flex items-center btn btn-compact-filter" onClick={handleToggleMenu}>
            <i className="mr-1 material-icons material-icons-outlined md-20">filter_list</i>
            <div className="text-lg font-semibold uppercase">Filter</div>
          </div>
          <div
            className={cx('dropdown-menu dropdown-menu-filter user-menu', { invisible: !visible })}
            ref={popperRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="px-4 pt-4 text-black" style={{ width: '530px' }}>
              <button
                className="flex justify-end mb-2 ml-auto text-right btn btn-filter"
                type="button"
                disabled={isLoading}
                onClick={handleResetFilters}
              >
                Reset Filter
              </button>
              <div className="grid grid-cols-2 gap-4">
                <Organizations isLoading={isLoading} data={data} />
                <Groups isLoading={isLoading} register={register} handleFilters={handleFilters} items={items} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Model isLoading={isLoading} register={register} handleFilters={handleFilters} items={items} />
                <DeviceName isLoading={isLoading} register={register} handleFilters={handleFilters} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <SerialNumber isLoading={isLoading} register={register} handleFilters={handleFilters} />
                <ICCID isLoading={isLoading} register={register} handleFilters={handleFilters} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Status
                  isLoading={isLoading}
                  register={register}
                  handleStatus={handleStatus}
                  handleHiddenDevices={handleHiddenDevices}
                  visibility={deviceVisibility}
                  items={items}
                />
                <Expiration isLoading={isLoading} register={register} onSliderChange={onSliderChange} items={items} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <UnusedSFCQuota isLoading={isLoading} maxQuota={maxQuota} handleUsage={handleUsage} />
                <UnusedConnectivityUsage
                  isLoading={isLoading}
                  maxQuotaSim={maxQuotaSim}
                  handleSimUsage={handleSimUsage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject(({ prepaidStore }) => ({ prepaidStore }))(observer(Filter));
