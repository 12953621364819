import { debounce } from 'lodash';
import { QUOTA_RANGE_ERROR_MESSAGE } from 'definitions';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, maxQuotaSim, handleQuota, handleType }) => {
  const minRef = React.useRef(null);
  const maxRef = React.useRef(null);

  const onChangeMin = debounce(() => {
    const elem = minRef?.current;
    const value = elem?.value;

    elem && elem.setCustomValidity && elem.setCustomValidity('');

    if (value) {
      if (elem?.checkValidity()) {
        handleQuota('usage_mb_from', {
          type: maxQuotaSim.type,
          amount: value >= 1 ? Number(value) : 0,
        });
      } else {
        elem && elem.setCustomValidity && elem.setCustomValidity(QUOTA_RANGE_ERROR_MESSAGE);
        elem && elem.reportValidity && elem.reportValidity();
      }
    } else {
      handleQuota('usage_mb_from', { type: 'TB', amount: '' });
    }
  }, 500);

  const onChangeMax = debounce(() => {
    const elem = maxRef?.current;
    const value = elem?.value;

    if (value) {
      if (elem?.checkValidity()) {
        handleQuota('usage_mb_to', {
          type: maxQuotaSim.type,
          amount: value >= 1 ? Number(value) : 0,
        });
      } else {
        elem && elem.setCustomValidity && elem.setCustomValidity(QUOTA_RANGE_ERROR_MESSAGE);
        elem && elem.reportValidity && elem.reportValidity();
      }
    } else {
      handleQuota('usage_mb_to', { type: 'TB', amount: '' });
    }
  }, 500);

  return (
    <FilterGroup label="eSIM Used" isLoading={isLoading}>
      <div className="flex items-center">
        <input
          ref={minRef}
          className="mr-2 text-center text-black bg-secondary"
          style={{ width: `50px` }}
          type="number"
          max={maxQuotaSim.usage_mb_to}
          step=".01"
          id="minQuota"
          onChange={onChangeMin}
        />
        <span className="ml-2 mr-2">&mdash;</span>
        <input
          ref={maxRef}
          className="mr-2 text-center text-black bg-secondary"
          style={{ width: `50px` }}
          type="number"
          min={maxQuotaSim.usage_mb_from}
          step=".01"
          id="maxQuota"
          onChange={onChangeMax}
        />
        <select
          name="maxQuotaSim"
          className="bg-secondary"
          style={{ width: `50px` }}
          value={maxQuotaSim.type}
          onChange={(e) => handleType(e.target.value)}
        >
          <option value="TB">TB</option>
          <option value="GB">GB</option>
          <option value="MB">MB</option>
        </select>
      </div>
    </FilterGroup>
  );
};
