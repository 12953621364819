import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import React from 'react';

const ViewAs = ({ userStore, className }) => {
  const inputRef = React.useRef();

  const onSubmit = () => {
    const email = inputRef?.current?.value || '';
    userStore.setViewAs(email);
  };

  const onReset = () => {
    userStore.setViewAs('');
    userStore.me(true);
  };

  React.useEffect(() => {
    if (isEmpty(userStore?.viewAs) && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [userStore.viewAs]);

  return (
    <>
      {userStore.IS_ADMIN && (
        <div className={cx('flex flex-col md:flex-row md:items-center', className)}>
          <label htmlFor="viewAs" className="mr-2 font-bold">
            View as:
          </label>
          <input
            type="text"
            id="viewAs"
            className="p-2"
            ref={inputRef}
            disabled={!isEmpty(userStore.viewAs)}
            defaultValue={userStore.viewAs}
          />

          <div className="my-2 grid grid-cols-2 gap-4">
            <button className="md:ml-2 btn btn-primary" onClick={onSubmit} disabled={!isEmpty(userStore.viewAs)}>
              Submit
            </button>
            <button className="md:ml-2 btn btn-secondary" onClick={onReset} disabled={isEmpty(userStore.viewAs)}>
              Reset
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(ViewAs));
