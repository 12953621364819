import { ESIM_DATA_PLAN } from 'definitions';
import { EsimDataPlanIcon } from 'components/TopUp/TopUp/components/EsimDataPlanIcon';
import { findDataRegion } from 'components/TopUp/helper/findDataRegion';
import { quotaDimension } from 'utils';
import ConnectSelector from 'components/TopUp/TopUp/components/Connect/Selector';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ item, totalUsage, totalQuota }) => {
  const [isExpand, setIsExpand] = React.useState(false);
  let percentageItem = ((totalUsage * 100) / totalQuota).toFixed(0);

  const deviceDataRegion = findDataRegion(item.iccid_details);

  const moduleDataOnTop = item.modules?.filter(
    (module) =>
      Array.isArray(module.iccid_details) &&
      module.iccid_details.some(
        (iccid) => iccid.data_region === (deviceDataRegion || ESIM_DATA_PLAN.US) && iccid.data_region != null
      )
  );

  const moduleDataOnBottom = item.modules?.filter(
    (module) =>
      Array.isArray(module.iccid_details) &&
      module.iccid_details.some(
        (iccid) => iccid.data_region !== (deviceDataRegion || ESIM_DATA_PLAN.US) && iccid.data_region != null
      )
  );

  const hasModuleDataOnTop = Array.isArray(moduleDataOnTop) && moduleDataOnTop.length > 0;
  const hasModuleDataOnBottom = Array.isArray(moduleDataOnBottom) && moduleDataOnBottom.length > 0;

  return (
    <div className="flex items-center w-full">
      <ConnectSelector item={item} deviceDataRegion={deviceDataRegion} view={'list'} />
      <div className="flex items-center w-3/4 ml-4">
        {!isExpand && (
          <div className="flex items-center">
            {/* normal */}
            <i className="mr-2 icons icon-sim-data-normal" />

            <div className="flex flex-col">
              <EsimDataPlanIcon
                dataRegion={deviceDataRegion}
                hasModuleDataOnBottom={hasModuleDataOnBottom}
                isExpand={isExpand}
              />
              {percentageItem >= 10 && (
                <>
                  <div className="flex items-center">
                    <span className="text-primary remaining-sfc-usage-number">
                      {quotaDimension(totalUsage / 1024).quota}
                    </span>
                    <span className="text-primary remaining-sfc-usage-type">
                      {quotaDimension(totalUsage / 1024).type}
                    </span>
                    {(hasModuleDataOnTop || hasModuleDataOnBottom) && (
                      <i className="cursor-pointer text-primary material-icons md-16" onClick={() => setIsExpand(true)}>
                        launch
                      </i>
                    )}
                  </div>
                </>
              )}
              {/* less then 10% */}
              {percentageItem < 10 && (
                <Tooltip
                  placement="top"
                  trigger="hover"
                  tooltip={
                    <div className="tooltip-firmware">
                      <span>Your quota is nearly used up.</span>
                      <br />
                      <span> Top up Peplink eSIM Data Plan now!</span>
                    </div>
                  }
                >
                  <div className="flex items-center cursor-pointer">
                    <i className="mr-2 icons icon-sim-data-normal" />
                    <div className="flex items-center text-primary">
                      <span className="remaining-sfc-usage-number">{quotaDimension(totalUsage / 1024).quota}</span>
                      <span className="remaining-sfc-usage-type">{quotaDimension(totalUsage / 1024).type}</span>
                      <span className="py-2 mr-1 material-icons icon-firmware-error md-12 text-danger">
                        error_outline
                      </span>
                      {(hasModuleDataOnTop || hasModuleDataOnBottom) && (
                        <i className="cursor-pointer material-icons md-16" onClick={() => setIsExpand(true)}>
                          launch
                        </i>
                      )}
                    </div>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )}
        {isExpand && (
          <div className="flex items-end w-full pt-2">
            <div className="w-full mr-auto">
              {(deviceDataRegion || hasModuleDataOnTop) && (
                <EsimDataPlanIcon
                  dataRegion={deviceDataRegion || ESIM_DATA_PLAN.US}
                  isExpand={isExpand}
                  listViewExpand={true}
                />
              )}
              <div className="flex flex-wrap">
                {/* master device 5G/ LTE usage */}
                {deviceDataRegion && item?.totalPeriodFreeKb && (
                  <div className="flex items-center justify-center w-3/4 px-2 py-1 mb-2 mr-2 bg-gray-200">
                    <div className="icons icon-device-tag" />
                    <div className="font-semibold text-gray-500">
                      {quotaDimension(item.totalPeriodFreeKb / 1024).quota +
                        ' ' +
                        quotaDimension(item.totalPeriodFreeKb / 1024).type}
                    </div>
                  </div>
                )}
                {deviceDataRegion && !item.totalPeriodFreeKb && (
                  <div className="flex items-center justify-center w-3/4 px-2 py-1 mb-2 mr-2 bg-gray-200">
                    <div className="icons icon-device-tag" />
                    <div className="font-semibold text-gray-500">0 MB</div>
                  </div>
                )}
                {/* modules */}
                {hasModuleDataOnTop &&
                  moduleDataOnTop.map((i, index) => (
                    <div className="w-3/4 px-2 py-1 mb-2 mr-2 bg-gray-200 cursor-pointer" key={index}>
                      <Tooltip
                        placement="bottom"
                        trigger="hover"
                        tooltip={
                          <div>
                            <span className="tooltip-firmware">{module.sn}</span>
                          </div>
                        }
                      >
                        <div className="flex items-center justify-center">
                          <div className="icons icon-module-tag" />
                          <div className="font-semibold text-gray-500">
                            {quotaDimension(i.totalPeriodFreeKb / 1024).quota +
                              ' ' +
                              quotaDimension(i.totalPeriodFreeKb / 1024).type}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
              </div>

              {hasModuleDataOnBottom && (
                <EsimDataPlanIcon
                  dataRegion={deviceDataRegion || ESIM_DATA_PLAN.US}
                  isExpand={isExpand}
                  listViewExpand={true}
                  showOnTop={false}
                />
              )}
              <div className="flex flex-wrap">
                {hasModuleDataOnBottom &&
                  moduleDataOnBottom.map((module, index) => (
                    <div className="w-3/4 px-2 py-1 mb-2 mr-2 bg-gray-200 cursor-pointer" key={index}>
                      <Tooltip
                        placement="bottom"
                        trigger="hover"
                        tooltip={
                          <div>
                            <span className="tooltip-firmware">{module.sn}</span>
                          </div>
                        }
                      >
                        <div className="flex items-center justify-center">
                          <div className="icons icon-module-tag" />
                          <div className="font-semibold text-gray-500">
                            {quotaDimension(module.totalPeriodFreeKb / 1024).quota +
                              ' ' +
                              quotaDimension(module.totalPeriodFreeKb / 1024).type}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  ))}
              </div>
            </div>
            <i className="text-right material-icons md-16 md-close-launch" onClick={() => setIsExpand(false)}>
              launch
            </i>
          </div>
        )}
      </div>
    </div>
  );
};
