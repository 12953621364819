import { flatten, isEmpty, sortBy } from 'lodash';
import { handleRefresh } from './Refresh';
import { inject, observer } from 'mobx-react';
import { isArray } from 'lodash';
import { quotaKB } from 'utils';
import { Spinner } from '../../PoolPlan/Components/Icons';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Checkbox from 'components/common/form/Checkbox';
import cx from 'classnames';
import ErrorPage from './ErrorPage';
import React, { useMemo, useState } from 'react';
import Table from './Table';

const SelectedSNList = ({ data, onBackBtn, poolPlanStore, postpaidStore, userStore, onToggle, filterData = true }) => {
  const selectedInfoDefault = { show: false, confirmed: false, devices: 0, iccids: 0, eSIMQuotas: 0 };
  const [selectedData, setSelectedData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [poolPlanICCIDS, setPoolPlanICCIDS] = useState([]);
  const [selectedInfo, setSelectedInfo] = useState(selectedInfoDefault);
  const [errorDevices, setErrorDevices] = useState();

  const isPrepaid = (iccidDetails, iccid) => {
    if (iccidDetails?.length > 0) {
      const matchingDetail = iccidDetails.find((detail) => detail.ref_id === iccid);
      return matchingDetail?.billing_type === 'PREPAID';
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Serial Number',
        accessor: 'sn',
        className: 'col-add-device-sn',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div className=" whitespace-no-wrap">{data.value}</div>
            {data?.row?.original?.type === 'module' && (
              <div
                className="text-gray-500 whitespace-no-wrap cursor-default hover:text-primary transition-all duration-300"
                style={{
                  fontSize: 10,
                }}
              >
                Expansion module
              </div>
            )}
          </div>
        ),
      },
      {
        Header: 'Model',
        accessor: 'product',
        className: 'col-postpaid-model',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            {data?.value ? <div>{data?.value?.name}</div> : '-'}
          </div>
        ),
      },
      {
        Header: 'Device Name',
        accessor: 'name',
        className: 'col-postpaid-device-name',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div>{data.value || data?.row?.original?.masterName}</div>
          </div>
        ),
      },
      {
        Header: 'ICCID',
        id: 'iccid',
        className: 'col-postpaid-iccid',
        Cell: (data) => {
          const { iccids } = data?.row?.original;
          return isArray(iccids) && iccids.length > 0 ? (
            <div className="flex flex-col">
              {iccids.map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        Header: 'Remark',
        accessor: 'remark',
        className: 'col-postpaid-remark',
        Cell: (data) => {
          const { iccids, iccid_details } = data?.row?.original;
          return isArray(iccids) && iccids.length > 0 ? (
            <div className="flex flex-col">
              {iccids.map((iccid, i) => {
                const item = data.row.original;
                const usage = item.usages ? item.usages.find((u) => u.iccid === iccid) : undefined;
                const freeKb = usage ? usage.freeKb : '-';
                return (
                  <div
                    key={i}
                    className={cx({
                      'text-danger': !isPrepaid(iccid_details, iccid) || poolPlanICCIDS.includes(iccid) || freeKb > 0,
                    })}
                  >
                    {!isPrepaid(iccid_details, iccid)
                      ? 'ICCID is in Postpaid Plan'
                      : poolPlanICCIDS.includes(iccid)
                      ? 'Device has subscribed Peplink eSIM pooled plan'
                      : freeKb > 0
                      ? `Remaining pre-paid Peplink eSIM Data: ${quotaKB(freeKb)}`
                      : ''}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-danger"> Model does not have ICCID</div>
          );
        },
      },
    ],
    [poolPlanICCIDS]
  );

  const formatData = (data) => {
    return data.reduce((pre, cur) => {
      let iccids = [];
      if (cur?.original?.usages && cur?.original?.usages.length > 0) {
        iccids = cur.original.usages.map((usage) => ({
          iccid: usage.iccid,
          billing_type: cur.original.iccid_details.find((detail) => detail.ref_id === usage.iccid)?.billing_type || '',
          freeKb: usage.freeKb || 0,
        }));
      } else {
        iccids =
          cur?.original?.iccids.map((iccid, index) => ({
            iccid: iccid,
            billing_type: cur?.original?.iccid_details[index]?.billing_type || '',
            freeKb: 0,
          })) || [];
      }

      return pre.concat([
        {
          sn: cur?.original?.sn,
          type: cur?.original?.type,
          iccids: iccids,
        },
      ]);
    }, []);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = { devices: formatData(selectedData), accountRefId: userStore.ACCOUNT_REF_ID };

      let response = await postpaidStore.setPrepaidToPostpaid(payload);

      if (response.fail && response.fail.length > 0) {
        const iccidDevices = devices.filter((i) => i.iccids);

        if (response.fail.length > 0) {
          // got fail iccid, device details and pass to error page
          const filteredDevices = iccidDevices
            .map((device) => {
              const filteredIccids = device.iccids?.filter((iccid) => response.fail.includes(iccid));
              const filteredIccidDetails = device?.iccid_details?.filter((detail) =>
                filteredIccids.includes(detail.ref_id)
              );
              return {
                ...device,
                iccids: filteredIccids,
                iccid_details: filteredIccidDetails,
              };
            })
            ?.filter((device) => device.iccids.length > 0);

          setErrorDevices(filteredDevices);
        }
      } else {
        onToggle && onToggle(false);
        toast.success(`Added ${selectedInfo.iccids} ICCID${selectedInfo.iccids > 1 ? 's' : ''} to the post-paid plan.`);
      }
    } catch (error) {
      const message = error?.message || error[0]?.message;
      toast.error(`Error: ${message}`);
      onToggle && onToggle(false);
    } finally {
      setSelectedInfo(selectedInfoDefault);
      handleRefresh(postpaidStore, userStore);
      setIsLoading(false);
    }
  };

  const onSelect = (value) => {
    setSelectedData(value);
  };

  useEffect(() => {
    if (selectedData.length === 0) {
      setSelectedInfo(selectedInfoDefault);
    } else {
      const selected = selectedData.map((i) => i?.original);

      // Count number of Prepaid iccids
      let totalPrepaidIccids = 0;
      selected.forEach((device) => {
        const prepaidIccids = device.iccid_details?.filter((iccid) => iccid.billing_type === 'PREPAID');
        totalPrepaidIccids += prepaidIccids.length;
      });

      // filter out those device have eSIM data
      const filteredData = selected.filter(
        (item) =>
          item.usages && item.usages.length > 0 && item.usages.some((usage) => typeof usage.freeKb !== 'undefined')
      );
      const formattedData = filteredData.map((item) => {
        const iccids = item.iccids.map((iccid) => ({
          iccid,
          eSIMQuota: item.usages.find((usage) => usage.iccid === iccid)?.freeKb || 0,
          billing_type: item.iccid_details.find((type) => type.ref_id === iccid)?.billing_type || null,
        }));

        return {
          sn: item.sn,
          iccids,
        };
      });

      // group data for eSIM confirm checkbox
      if (formattedData.length > 0) {
        const totalDevices = formattedData.length;
        // count total number of PREPAID iccids

        const totaleSIMQuotas = formattedData.reduce((total, item) => {
          // filter out  billing_type === 'PREPAID' iccids and count eSIM quota
          const prepaidIccids = item.iccids.filter((iccid) => iccid.billing_type === 'PREPAID');
          const eSIMQuotaSum = prepaidIccids.reduce((quotaTotal, iccid) => quotaTotal + iccid.eSIMQuota, 0);

          return total + eSIMQuotaSum;
        }, 0);
        setSelectedInfo({
          ...selectedInfo,
          show: true,
          devices: totalDevices,
          iccids: totalPrepaidIccids,
          eSIMQuotas: quotaKB(totaleSIMQuotas),
        });
      } else {
        setSelectedInfo({
          ...selectedInfoDefault,
          iccids: totalPrepaidIccids,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  const genFooter = (raw) => {
    return `${raw.length} Serial Number${raw.length > 1 ? 's' : ''}, ${selectedInfo.iccids} ICCID${
      selectedInfo.iccids > 1 ? 's' : ''
    }`;
  };

  const processData = React.useCallback(
    (raw) => {
      const allModules = flatten(raw.map((i) => i.modules || []));

      // get client iccids in pooled plan
      if (poolPlanStore.sns && poolPlanStore.sns.length > 0) {
        const iccids = poolPlanStore.sns.flatMap((item) => item.iccids.map((iccidObj) => iccidObj.iccid));
        setPoolPlanICCIDS(iccids);
      }

      return raw
        .filter((i) => !allModules.some((item) => item.sn === i.sn))
        .sort((a, b) => a - b)
        .reduce((prev, cur) => {
          if (cur.modules) {
            return prev.concat(cur, cur.modules);
          } else {
            return prev.concat(cur);
          }
        }, []);
    },
    [poolPlanStore.sns]
  );

  useEffect(() => {
    setDevices(() => {
      if (filterData) {
        return processData(data);
      } else {
        return sortBy(data, 'name');
      }
    });

    return () => setIsLoading(false);
  }, [data, filterData, processData]);

  return (
    <div>
      {!errorDevices ? (
        <>
          <div className="table-container">
            <Table
              tableType="addDevice"
              data={devices}
              columns={columns}
              withSelectionColumn={true}
              onSelect={onSelect}
              selectDisabledFunc={(row) =>
                !row.original.iccids ||
                row.original.iccids.length === 0 ||
                row.original.iccids.every((iccid) => !isPrepaid(row.original.iccid_details, iccid)) ||
                row.original.iccids.some((iccid) => poolPlanICCIDS.includes(iccid))
              }
            />
            {selectedInfo.show && selectedInfo.eSIMQuotas > 0 && (
              <div className="flex items-center justify-center gap-2">
                <div className="mt-1">
                  <Checkbox
                    onChange={() => setSelectedInfo({ ...selectedInfo, confirmed: !selectedInfo.confirmed })}
                    checked={selectedInfo.confirmed}
                  />
                </div>
                <div>
                  {` By adding the ICCIDs to the post-paid plan, I understand that `}
                  <span className="text-danger">{`the remaining eSIM data: ${selectedInfo.eSIMQuotas} in ${
                    selectedInfo.devices
                  } device${selectedInfo.devices.length > 1 ? 's' : ''} will be removed.`}</span>
                </div>
              </div>
            )}
          </div>
          <div className="bottom-bar">
            <button className="btn btn-outline-primary" onClick={onBackBtn}>
              Back
            </button>
            <div className="bottom-bar-right">
              <div className="flex flex-col items-end justify-center text-base">
                <div className="font-semibold"> {genFooter(selectedData)} </div>
                <div className="flex items-center font-light">
                  <div className="icons icon-info"></div>
                  <div>Please allow some time for creating the post-paid profiles</div>
                </div>
              </div>
              <button
                className="ml-6 btn btn-outline-primary"
                onClick={onSubmit}
                disabled={
                  isLoading ||
                  selectedData.length === 0 ||
                  !isEmpty(userStore.viewAs) ||
                  (selectedInfo.show === true && selectedInfo.confirmed === false && selectedInfo.eSIMQuotas > 0)
                }
              >
                {isLoading && <Spinner />}
                {isEmpty(userStore.viewAs) ? 'Add' : 'View Only'}
              </button>
            </div>
          </div>
        </>
      ) : (
        <ErrorPage onToggle={onToggle} data={errorDevices} />
      )}
    </div>
  );
};

export default inject(({ poolPlanStore, prepaidStore, userStore, postpaidStore }) => ({
  poolPlanStore,
  prepaidStore,
  userStore,
  postpaidStore,
}))(observer(SelectedSNList));
