import { includes } from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';

const Visibility = ({ data, userStore }) => {
  const onSetDeviceVisibility = async () => {
    await userStore.updateHiddenDevices({ type: 'postpaid', value: data.iccid });
  };

  return (
    <div className="flex items-center justify-center">
      <span className="text-2xl text-gray-500 cursor-pointer material-icons-outlined" onClick={onSetDeviceVisibility}>
        {includes(userStore.hiddenDevices.postpaid, data.iccid) ? 'visibility' : 'visibility_off'}
      </span>
    </div>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(Visibility));
