import { Info, More } from '../../PoolPlan/Components/Icons';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'definitions';
import React from 'react';

const NotificationCard = ({ poolPlanStore, prepaidStore }) => {
  return (
    <div className="flex-col mb-5 card md:mb-0 md:flex-row">
      <div className="flex items-center">
        <Info />
        <span className="ml-2"> {poolPlanStore?.notification?.info.msg}</span>
        <span className="ml-1 font-semibold text-danger">
          {poolPlanStore?.notification?.info.highline} {poolPlanStore?.notification?.diff}
        </span>
        .
      </div>
      <div className="flex flex-row items-center mt-2 ml-2 mr-auto md:mr-0 md:mt-0">
        <Link className="top-up-btn" to={`${ROUTES.POOL_MANAGEMENT}/${prepaidStore?.selectedOrganization?.org_code}`}>
          <span className="px-3 font-semibold">View Detail</span>
          <More />
        </Link>
      </div>
    </div>
  );
};

export default inject(({ poolPlanStore, prepaidStore }) => ({ poolPlanStore, prepaidStore }))(
  observer(NotificationCard)
);
