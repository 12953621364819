import React from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ tooltip }) => {
  return (
    <Tooltip placement="top" trigger="hover" tooltip={<div className="tooltip-firmware">{tooltip}</div>}>
      <i className="text-gray-500 material-icons icon-bubble-help">help</i>
    </Tooltip>
  );
};
