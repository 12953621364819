import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader } from 'components/common/Modal';
import Loading from 'components/common/Loading';
import React from 'react';
import SelectedSNList from 'components/PoolPlan/Components/SelectedSNList';

const AddToPoolModal = ({ onToggle, poolPlanStore }) => {
  const isLoading = React.useMemo(() => poolPlanStore.devices.isLoading, [poolPlanStore.devices.isLoading]);
  const data = React.useMemo(
    () =>
      poolPlanStore.selectedDevices.map((i) => {
        const sn = poolPlanStore.devices.data.find((sn) => sn.sn === i.sn);
        const subscribed = poolPlanStore.sns.some((sn) => sn.sn === i.sn);
        return { ...sn, subscribed };
      }),
    [poolPlanStore.devices.data, poolPlanStore.selectedDevices, poolPlanStore.sns]
  );

  React.useEffect(() => {
    poolPlanStore.getDevices();
  }, [poolPlanStore]);

  return (
    <Modal size="addToPoolModal" containerClass="bg-black" onToggle={onToggle}>
      <ModalHeader onToggle={onToggle} classNames={{ modal: 'px-6 pt-5', closeBtn: 'px-6 pt-5' }}>
        <h3 className="add-modal-title">add device</h3>
      </ModalHeader>
      {isLoading ? (
        <div style={{ width: '100%', height: '400px' }}>
          <Loading />
        </div>
      ) : (
        <div className="add-modal">
          <SelectedSNList
            redirectToPool={true}
            data={data}
            onBackBtn={onToggle}
            onToggle={onToggle}
            filterData={false}
          />
        </div>
      )}
    </Modal>
  );
};

export default inject(({ poolPlanStore }) => ({ poolPlanStore }))(observer(AddToPoolModal));
