import { computed, configure, makeObservable, observable } from 'mobx';
import { includes, isEmpty } from 'lodash';
import { POSTPAID_COLUMNS, PREPAID_COLUMNS } from 'definitions';
import stores from 'stores';

configure({
  enforceActions: 'never',
});

export default class UserStore {
  constructor(props) {
    this.api = props.api;

    this.user = null;
    this.viewAs = null;
    this.hiddenDevices = {};
    this.tags = [];
    this.iccidTags = [];
    this.postPaidOption = [];
    this.prepaidOption = [];
    this.isLoading = false;
    this.accountRefId = 0;

    makeObservable(this, {
      user: observable,
      viewAs: observable,
      hiddenDevices: observable,
      tags: observable,
      iccidTags: observable,
      postPaidOption: observable,
      prepaidOption: observable,
      isLoading: observable,
      USER_DATA: computed,
      IS_ADMIN: computed,
      IS_IC2USER: computed,
      IS_POSTPAID_USER: computed,
      IS_NEW: computed,
      ACCOUNT_REF_ID: computed,
      LANDING: computed,
      POSTPAID_RANGE: computed,
    });
  }

  refreshUserData = (response) => {
    let data = {};

    if (includes([200, 202], response.statusCode)) {
      data = { ...this.USER_DATA, ...response.item };

      stores.authStore.setToken(JSON.stringify(data));

      this.tags = data.tags;
      this.hiddenDevices = data.hidden;
      this.iccidTags = data.iccidTags;
      this.postPaidOption = data.postPaidOption;
      this.prepaidOption = data.prepaidOption;
      this.user = data;
      this.isLoading = false;
    }
  };

  updateHiddenDevices = async (payload) => {
    const response = await this.api.updateHiddenDevices(payload);

    this.refreshUserData(response);

    return response;
  };

  update = async (payload) => {
    const response = await this.api.update(payload);

    this.refreshUserData(response);

    return response;
  };

  me = async (invalidate = false) => {
    if (this.isLoading) return;

    if (!isEmpty(this.user) && !invalidate) {
      return this.user;
    } else {
      let response;

      try {
        this.isLoading = true;
        response = await this.api.find();

        this.refreshUserData(response);

        return response;
      } catch (error) {
        return Promise.reject(error);
      }
    }
  };

  getOtherUserData = async () => {
    if (this.isLoading) return;

    this.isLoading = true;

    try {
      const response = await this.api.getOtherUserData({ email: this.viewAs });
      const { item } = response;

      this.tags = item.tags || [];
      this.hiddenDevices = item.hidden || { prepaid: [], postpaid: [] };
      this.iccidTags = item.iccidTags || [];
      this.prepaidOption = PREPAID_COLUMNS;
      this.postpaidOption = POSTPAID_COLUMNS;
      this.isLoading = false;

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  setTags = async (iccid, payload) => {
    const response = await this.api.setTags(iccid, payload);

    this.refreshUserData(response);

    return response;
  };

  setViewAs = (value) => {
    this.viewAs = value;
  };

  getDataOrFalse(which, isBoolean = false) {
    if (!isEmpty(this.USER_DATA)) return isBoolean ? JSON.parse(this.USER_DATA[which]) : this.USER_DATA[which];

    return false;
  }

  getNumber(which) {
    if (!isEmpty(this.USER_DATA)) return null ? JSON.parse(this.USER_DATA[which]) : Number(this.USER_DATA[which]);
    return null;
  }

  get USER_DATA() {
    const SF_CLOUD = localStorage.getItem(process.env.REACT_APP_APP_NAME);

    return !isEmpty(SF_CLOUD) ? JSON.parse(SF_CLOUD) : {};
  }

  get IS_ADMIN() {
    return this.getDataOrFalse('isAdmin', true);
  }

  get IS_IC2USER() {
    return this.getDataOrFalse('isIC2User', true);
  }

  get IS_POSTPAID_USER() {
    return this.getDataOrFalse('isPostpaidUser', true);
  }

  get ACCOUNT_REF_ID() {
    return this.getNumber('accountRefId');
  }

  get IS_NEW() {
    return this.getDataOrFalse('isNew', true);
  }

  get LANDING() {
    return this.getDataOrFalse('landing');
  }

  get SA_TOKEN() {
    return this.getDataOrFalse('saToken');
  }

  get POSTPAID_RANGE() {
    return this.getDataOrFalse('postpaidDateRange');
  }
}
