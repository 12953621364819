import { eachMonthOfInterval, format, isValid } from 'date-fns';
import { inject, observer } from 'mobx-react';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

const MonthPicker = ({ isLoading, onChange, value, userStore }) => {
  const options = React.useMemo(() => {
    return eachMonthOfInterval({
      start: isValid(new Date(userStore.POSTPAID_RANGE.min)) ? new Date(userStore.POSTPAID_RANGE.min) : new Date(),
      end: new Date(),
    })
      .sort((a, b) => b - a)
      .map((i) => ({
        value: i,
        label: format(i, 'MMMM yyyy'),
      }));
  }, [userStore.POSTPAID_RANGE.min]);

  return (
    <FilterGroup label="Total Usage During" isLoading={isLoading}>
      <div className="flex items-center" htmlFor="totalUsage">
        <i className="text-lg material-icons-outlined">calendar_today</i>
        <select name="totalUsage" id="totalUsage" onChange={(e) => onChange(e.target.value)} value={value}>
          {options.map((i) => (
            <option key={`model-${i.value}`} value={i.value}>
              {i.label}
            </option>
          ))}
        </select>
      </div>
    </FilterGroup>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(MonthPicker));
