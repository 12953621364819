import { dateFormat, filterCarrierPostpaid, filterStatusPostpaid, filterUsageSim, snLink } from 'utils';
import { DEFAULT_SN, POSTPAID_COLUMNS } from 'definitions';
import { find, get, includes, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { isArray } from 'lodash';
import Carrier from './Carrier';
import Firmware from './Firmware';
import HelpBubble from 'components/common/HelpBubble';
import React from 'react';
import ReactTable from 'components/common/ReactTable';
import SimOptions from '../../../component/SimOptions';
import Usage from './Usage';
import Visibility from './Visibility';

const SUSPEND = 'Suspended';

const View = ({
  tableRef,
  defaultSelected,
  defaultPageSize,
  setFilteredData,
  children,
  postpaidStore,
  userStore,
  items,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Visibility',
        id: 'visibility',
        className: 'col-visibility text-center',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Visibility data={original} />,
      },
      {
        Header: (
          <span>
            ICCID
            <HelpBubble tooltip="An ICCID is a unique number assigned to your SIM card used in the cellular device." />
          </span>
        ),
        accessor: 'iccid',
        className: 'col-usage-iccid text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <span className="value">{original.iccid}</span>,
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
        className: 'col-carrier text-left',
        filter: filterCarrierPostpaid,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Carrier item={original} />,
      },
      {
        Header: 'SIM/ eSIM Usage',
        accessor: 'usageMb',
        className: 'col-connect-postpaid-usage text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Usage item={original} />,
      },
      {
        Header: 'Device Name',
        accessor: 'name',
        className: 'col-device text-left',
      },
      {
        Header: 'Model',
        accessor: 'product.name',
        className: 'col-usage-model text-left',
      },
      {
        Header: 'Serial No.',
        accessor: 'sn',
        className: 'col-sn text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <div className="flex items-center">
              <span className="mr-2 text-lg font-medium">{original.sn}</span>
              {original.sn !== DEFAULT_SN && (
                <a href={snLink(original)} target="_blank" rel="noopener noreferrer" className="no-underline">
                  <i className="icons icon-sn-link"></i>
                </a>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Firmware',
        accessor: 'fw',
        className: 'col-fw text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Firmware item={original} />,
      },

      {
        Header: 'Last Online',
        accessor: 'last_seen',
        className: 'col-last-seen text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <>{original.last_seen && <span>{dateFormat(original.last_seen, 'd MMM yyyy HH:mm')}</span>}</>;
        },
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        className: 'col-iccid text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const userTags = userStore.iccidTags.find((i) => i.iccid === original.iccid);

          return (
            <>
              {!isEmpty(userTags) && (
                <div className="flex items-center py-2">
                  {userTags.tags.map((tag, index) => (
                    <span
                      className="inline-block px-2 py-1 mr-2 font-semibold text-gray-700 bg-gray-300 bg-opacity-25 rounded-md"
                      key={index}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </>
          );
        },
      },
      {
        accessor: 'action',
        sticky: 'right',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <div className="flex items-center justify-center">
              <SimOptions item={original} className="" />
            </div>
          );
        },
      },
      {
        accessor: 'usage_mb_from',
        className: 'hidden',
        filter: filterUsageSim,
      },
      {
        accessor: 'usage_mb_to',
        className: 'hidden',
        filter: filterUsageSim,
      },
      {
        accessor: 'status',
        className: 'hidden',
        filter: filterStatusPostpaid,
      },
      {
        accessor: 'isDeviceHidden',
        filter: (rows, _, filterValue) => {
          return rows.filter((row) => {
            if (filterValue === 'VISIBLE_ONLY') {
              return !includes(userStore.hiddenDevices.postpaid, row.original.iccid);
            } else if (filterValue === 'HIDDEN_ONLY') {
              return includes(userStore.hiddenDevices.postpaid, row.original.iccid);
            } else if (filterValue === 'SUSPENDED_ONLY') {
              return get(find(postpaidStore.status, { iccid: row.original.iccid }), 'status') === SUSPEND;
            }

            return true;
          });
        },
      },
      {
        accessor: 'tag',
        filter: (rows, _, filterValue) => {
          return rows.filter((row) => {
            const userTags = userStore.iccidTags.find((i) => i.iccid === row.original.iccid);

            if (!isEmpty(userTags) && includes(userTags.tags, filterValue)) {
              return true;
            }

            return false;
          });
        },
      },
    ],
    [postpaidStore.status, userStore.hiddenDevices.postpaid, userStore.iccidTags]
  );

  return (
    <>
      {isArray(userStore.postPaidOption) && (
        <ReactTable
          header={children}
          perPageText={'SIMs Per Page'}
          tableRef={tableRef}
          setTotalNumber={postpaidStore.setTotalNumber}
          setFilteredData={setFilteredData}
          columns={columns}
          data={items}
          isUsageList={true}
          className="mb-5"
          options={{
            defaultPageSize,
            isLoading: postpaidStore.items.isLoading,
            defaultSelected,
            isMulti: true,
            onSelectionChanged: () => {
              return [];
            },
            disabledProp: 'disabled',
            toHideCheckbox: true,
            tableClassName: 'table-view-postpaid',
            totalColumns: POSTPAID_COLUMNS.length,
            hiddenColumns: [
              ...userStore.postPaidOption.filter((i) => !i.show).map((i) => i.key),
              !isEmpty(userStore.viewAs) && 'visibility',
            ],
            isList: true,
          }}
        />
      )}
    </>
  );
};

export default inject(({ postpaidStore, userStore }) => ({ postpaidStore, userStore }))(observer(View));
