import { FEATURE, ROUTES } from 'definitions';
import { has } from 'lodash';
import { inject, observer } from 'mobx-react';
import Loading from './common/Loading';
import qs from 'qs';
import React from 'react';

const CONFIRMATION_MESSAGE =
  'No associated device with PeplinkID. \nWould you like to associate your devices with PeplinkID?';

const Callback = ({ history, authStore, userStore }) => {
  React.useEffect(() => {
    const res = qs.parse(history.location.search);
    const userData = !has(res, 'hidden') ? { ...res, hidden: { prepaid: [], postpaid: [] } } : res;

    if (Number(userData?.statusCode) === 200) {
      authStore.setToken(JSON.stringify(userData));

      if (userStore.IS_IC2USER) {
        if (userStore.IS_POSTPAID_USER && userStore.LANDING === FEATURE.POSTPAID) {
          history.push(ROUTES.SIM);
        } else if (userStore.LANDING === FEATURE.POOL) {
          history.push(ROUTES.POOL_MANAGEMENT);
        } else {
          history.push(ROUTES.TOPUP);
        }
      } else {
        const confirmAction = window.confirm(CONFIRMATION_MESSAGE);

        if (confirmAction) {
          document.location = process.env.REACT_APP_REDIRECT_IC2_PAGE;
        } else {
          document.location = process.env.REACT_APP_REDIRECT_ESTORE_PAGE;
        }
      }
    } else {
      alert('Login unsuccessful.');
      document.location = '/';
    }
  }, [authStore, history, userStore]);

  return <Loading />;
};

export default inject(({ authStore, userStore }) => ({ authStore, userStore }))(observer(Callback));
