import { usePopper } from 'react-popper';
import cx from 'classnames';
import React from 'react';
import useClickOutside from 'hooks/useClickOutside';

export default ({
  placement = 'bottom-start',
  children,
  items,
  onClick,
  className,
  menuClassname = 'w-full',
  strategy = 'fixed',
}) => {
  const [visible, setVisibility] = React.useState(false);

  const buttonRef = React.useRef(null);
  const popperRef = React.useRef(null);
  useClickOutside({ onClose: () => setVisibility(false), elemRef: buttonRef });

  const { styles, attributes, update } = usePopper(buttonRef.current, popperRef.current, {
    placement,
    strategy,
  });

  function handleDropdownClick() {
    update();
    setVisibility(!visible);
  }

  const handleClick = (item) => {
    onClick(item);
  };

  return (
    <div className={cx('dropdown relative', className)} ref={buttonRef} onClick={handleDropdownClick}>
      {children}
      <div
        className={cx('dropdown-menu', menuClassname, { invisible: !visible })}
        ref={popperRef}
        style={styles.popper}
        {...attributes.popper}
      >
        <ul style={styles.offset}>
          {items.map((i, key) => (
            <li key={`${i.value}-${key}`} onClick={() => handleClick(i)} id={i.id}>
              {i.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
