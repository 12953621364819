import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import React from 'react';

const Refresh = ({ poolPlanStore }) => {
  const handleRefresh = async () => {
    await poolPlanStore.invalidateItems();
    await poolPlanStore.getPool();
    await poolPlanStore.getHistory();
    await poolPlanStore.getBalance();
    await poolPlanStore.getOrder();
    await poolPlanStore.getDevices();
  };

  const isLoading = React.useMemo(
    () =>
      poolPlanStore.poolPlan.isLoading ||
      poolPlanStore.history.isLoading ||
      poolPlanStore.balance.isLoading ||
      poolPlanStore.order.isLoading,
    [
      poolPlanStore.poolPlan.isLoading,
      poolPlanStore.history.isLoading,
      poolPlanStore.balance.isLoading,
      poolPlanStore.order.isLoading,
    ]
  );

  const isNotActivePlan = React.useMemo(() => isEmpty(poolPlanStore.poolPlan.item), [poolPlanStore.poolPlan.item]);

  return (
    <button
      className={cx(
        'btn btn-refresh mb-5 md:mb-0 md:flex items-center bg-white text-primary mr-1 md:whitespace-no-wrap border-2 border-primary cursor-default'
      )}
      disabled={isLoading || isNotActivePlan}
    >
      <div className="flex items-center">
        <i className="mr-2 icons icon-sfc-reminder" />
        <span className="mr-4 font-normal tracking-normal text-left normal-case text-text-primary">
          It may take some time for changes
          <br className="hidden md:block lg:hidden" /> to come into effect.
        </span>
      </div>
      <div className="flex justify-end">
        <span onClick={handleRefresh} className={cx('cursor-pointer')}>
          <i className="mr-1 material-icons md-22">sync</i>
          Refresh
        </span>
      </div>
    </button>
  );
};

export default inject(({ userStore, poolPlanStore }) => ({ userStore, poolPlanStore }))(observer(Refresh));
