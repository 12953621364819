import { formatValueWithUnit, getFormatedStringFromDays } from 'utils';
import React from 'react';

const Connect5GLTEProductTable = ({ planData, register, methods, dataColName, inputName }) => {
  return (
    <table className="table w-full simple-responsive data-plans">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" className="col-right">
            SFC Usage
          </th>
          <th scope="col" className="col-right">
            Speed
          </th>
          <th scope="col" className="col-right">
            {dataColName}
          </th>
          <th scope="col" className="col-right">
            Validity
          </th>
          <th scope="col" className="col-right col-price">
            Listed Price ({planData.content[0].price.currency})
          </th>
          <th scope="col" className="col-right col-price">
            Unit Price ({planData.content[0].price.currency})
          </th>
        </tr>
      </thead>
      <tbody className="mb-6">
        {planData.content.map((item, index) => (
          <tr key={index}>
            <td>
              <label htmlFor={item?.id} className="flex items-center">
                <input
                  name={inputName}
                  ref={register({ required: true })}
                  checked={item?.checked}
                  className="mr-2"
                  type="radio"
                  methods={methods}
                  defaultValue={item?.id}
                />
                <span>{item?.productCode}</span>
              </label>
            </td>
            <td data-label="Usage" className="text-right">
              {item?.usage !== 'nullnull' ? formatValueWithUnit(item?.usage) : '-'}
            </td>
            <td data-label="Speed" className="text-right">
              {item?.speed !== 'nullnull' ? formatValueWithUnit(item?.speed) : '-'}
            </td>
            <td data-label="LTE_Usage" className="text-right">
              {item?.sim_data?.volumn ? item?.sim_data?.volumn : '-'} {item?.sim_data?.unit && item?.sim_data?.unit}
            </td>
            <td data-label="Lifetime" className="text-right">
              {item?.lifetime !== 'null' ? getFormatedStringFromDays(item?.lifetime) : '-'}
            </td>
            <td
              data-label={`Listed Price (${planData?.content[0]?.price?.currency})`}
              className="text-right line-through"
            >
              &nbsp;&nbsp;{item?.price?.amount ? item?.price?.amount : '-'}&nbsp;&nbsp;
            </td>
            <td data-label={`Unit Price (${planData?.content[0]?.price?.currency})`} className="text-right">
              {item?.discountedPrice ? item?.discountedPrice?.amount : item?.price?.amount}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Connect5GLTEProductTable;
