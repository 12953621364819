import BaseServices from './base';

export default class MeServices extends BaseServices {
  constructor(props) {
    super(props);
    this.url = '/me';
    this.http = props.http;
  }

  updateHiddenDevices = (payload) => this.http.put(`${this.url}/hidden-device`, payload);
  setTags = (iccid, payload) => this.http.put(`${this.url}/iccid-tags/${iccid}`, payload);
  getOtherUserData = (payload) => this.http.get(`${this.url}/impersonate`, payload);
}
