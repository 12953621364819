import { format } from 'date-fns';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import FileDownload from 'js-file-download';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const ExportCSV = ({ prepaidStore, userStore }) => {
  const [isDownloading, setDownloading] = React.useState(false);

  const handleDownloadReport = async () => {
    const timestamp = format(new Date(), 'yyyy-MM-dd-HHmm');
    const filename = `SpeedFusion Connect Prepaid Report - ${timestamp}`;

    setDownloading(true);

    await prepaidStore
      .download(userStore.viewAs)
      .then((res) => FileDownload(res, `${filename}.xlsx`))
      .finally(() => setDownloading(false));
  };

  return (
    <>
      {!isEmpty(prepaidStore.roles) && (
        <Tooltip
          placement="top"
          trigger="hover"
          tooltip={<div className="tooltip-refresh">Download and track your remaining usage records</div>}
        >
          <button
            className={cx('btn btn-download flex items-center bg-white text-primary ml-1 whitespace-no-wrap', {
              'cursor-not-allowed': isDownloading || prepaidStore.items.isLoading,
            })}
            disabled={isDownloading || prepaidStore.items.isLoading}
            onClick={handleDownloadReport}
          >
            <i className="mr-1 material-icons md-20">assignment</i>
            Download Report
          </button>
        </Tooltip>
      )}
    </>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(ExportCSV));
