import { sortBy } from 'lodash';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, register, handleFilters, items }) => {
  const [groupsOptions, setGroupsOptions] = React.useState([]);

  React.useEffect(() => {
    setGroupsOptions(
      sortBy(
        Object.values(
          items.reduce((acc, value) => {
            return acc[value.grp_name] ? { ...acc } : { ...acc, [value.grp_name]: value.grp_name };
          }, {})
        ).map((i) => ({ label: i, value: i })),
        (i) => i.label
      )
    );
  }, [items]);

  return (
    <>
      <FilterGroup label="Groups" isLoading={isLoading}>
        <select
          id="select-group"
          ref={register()}
          name="grp_name"
          onChange={(e) => handleFilters('grp_name', e.target.value)}
        >
          <option value="">All</option>
          {groupsOptions.map((i) => (
            <option key={i.value} value={i.value}>
              {i.label}
            </option>
          ))}
        </select>
      </FilterGroup>
    </>
  );
};
