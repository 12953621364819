import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React from 'react';

export default () => {
  return (
    <ToastContainer
      closeButton={
        <div className="flex items-center">
          <span className="material-icons">clear</span>
        </div>
      }
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      draggable={false}
      theme="dark"
      limit={1}
      style={{ width: 'auto' }}
    />
  );
};
