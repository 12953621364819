import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import React from 'react';

export default ({ children, onToggle, size = 'md', className, containerClass }) => {
  const ref = React.createRef();

  React.useEffect(() => {
    const { current } = ref;
    disableBodyScroll(current);
    return () => {
      enableBodyScroll(current);
    };
  }, [ref]);

  return (
    <>
      <div ref={ref} className={cx('modal', className)}>
        <div
          className={cx(
            'relative w-auto modal-content overflow-hidden',
            containerClass,
            { 'md:w-2/3 lg:w-1/3': size === 'sm' },
            { 'md:w-3/4 lg:w-2/4': size === 'md' },
            { 'md:w-4/6': size === 'addToPoolModal' },
            { 'md:w-4/5': size === 'lg' }
          )}
        >
          {children}
        </div>
        <div className="modal-overlay" onClick={() => onToggle && onToggle(false)} />
      </div>
    </>
  );
};
