import { noFirmwareRequirements } from 'utils';
import React from 'react';

export default ({ data }) => {
  // Enable those code when 8.3.2 is released, please don't del it.
  // function compareVersions(versionA, versionB) {
  //   const segmentsA = versionA.split('.').map(Number);
  //   const segmentsB = versionB.split('.').map(Number);
  //   for (let i = 0; i < segmentsA.length; i++) {
  //     if (segmentsA[i] > segmentsB[i]) {
  //       return 1;
  //     } else if (segmentsA[i] < segmentsB[i]) {
  //       return -1;
  //     }
  //   }
  //   return 0;
  // }

  return (
    <div>
      {(data.fw || data.fw_ver) &&
        (parseFloat(data.fw || data.fw_ver) < 8.1 &&
        !noFirmwareRequirements(data.product.sku) &&
        data.support_sfc === true ? (
          <div className="text-danger">
            <div className="flex flex-row items-center cursor-pointer">{(data.fw || data.fw_ver).substring(0, 5)}</div>
            <div>
              <span className="mr-1 material-icons icon-firmware-error md-12">error_outline</span>
              <span>8.1.0 Required</span>
            </div>
          </div>
        ) : (
          // Enable those code when 8.3.2 is released, please don't del it.
          // data.sfwan_license?.unlimited_data_volume === true &&
          //   compareVersions((data.fw || data.fw_ver).substring(0, 5), '8.3.2') < 0 ? (
          //   <div className="text-danger">
          //     <div className="flex flex-row items-center cursor-pointer">{(data.fw || data.fw_ver).substring(0, 5)}</div>
          //     <div>
          //       <span className="mr-1 material-icons icon-firmware-error md-12">error_outline</span>
          //       <span>8.3.2 Required</span>
          //     </div>
          //   </div>
          // ) :

          (data.fw || data.fw_ver).substring(0, 5)
        ))}
    </div>
  );
};
