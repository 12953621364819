import { encodedEmail } from 'utils';
import { isEmpty } from 'lodash';

export default class SimServices {
  constructor(props) {
    this.url = '/sim';
    this.http = props.http;
  }

  refresh = (payload, email) =>
    this.http.post(
      !isEmpty(email) ? `${this.url}/clearCache?email=${encodedEmail(email)}` : `${this.url}/clearCache`,
      payload
    );

  getPrepaid = (payload, email) =>
    this.http.get(
      !isEmpty(email) ? `${this.url}/prepaid?email=${encodedEmail(email)}` : `${this.url}/prepaid`,
      payload
    );

  downloadPrepaid = (email) =>
    this.http.getBlob(
      !isEmpty(email) ? `${this.url}/prepaidReport?email=${encodedEmail(email)}` : `${this.url}/prepaidReport`
    );

  roles = (email) =>
    this.http.get(!isEmpty(email) ? `${this.url}/roles?email=${encodedEmail(email)}` : `${this.url}/roles`);

  getPostpaid = (payload, email) =>
    this.http.get(
      !isEmpty(email) ? `${this.url}/postpaid?email=${encodedEmail(email)}` : `${this.url}/postpaid`,
      payload
    );

  downloadPostpaid = (payload, email) =>
    this.http.getBlob(
      !isEmpty(email) ? `${this.url}/postpaidReport?email=${encodedEmail(email)}` : `${this.url}/postpaidReport`,
      payload
    );

  postpaidAllStatus = () => this.http.get(`${this.url}/postpaidAllStatus`);

  postPaidResume = (iccid) => this.http.post(`${this.url}/postpaid/${iccid}/resume`);
  postPaidSuspend = (iccid) => this.http.post(`${this.url}/postpaid/${iccid}/suspend`);
  postPaidStatus = (iccid) => this.http.get(`${this.url}/postpaid/${iccid}/status`);
  getIMEIHistory = (iccid) => this.http.get(`${this.url}/postpaid/${iccid}/imeihistory`);
}
