import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Modal, ModalFooter, ModalHeader } from 'components/common/Modal';
import { PREPAID_COLUMNS } from 'definitions';
import { useForm } from 'react-hook-form';
import Checkbox from 'components/common/form/Checkbox';
import React from 'react';

const SettingModal = ({ onToggle, getData, userStore }) => {
  const [columnList, setColumnList] = React.useState([]);
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSave = async ({ ...rest }) => {
    const payload = {
      prepaidOption: Object.entries({ ...rest }).map((item) => ({
        key: item[0] === 'product' ? 'product.name' : item[0],
        show: item[0] === 'product' ? item[1].name : item[1],
      })),
    };

    await userStore.update(payload);

    getData();
    onToggle();
  };

  const handleChange = (data, item) => {
    setColumnList(
      Object.entries({ ...data.map((i) => (i.key === item.key ? { ...i, show: !i.show } : { ...i })) }).map((e) => e[1])
    );
  };

  React.useEffect(() => {
    setColumnList(() =>
      PREPAID_COLUMNS.map((i) => {
        const match = userStore.prepaidOption.find((option) => option.key === i.key);

        return { ...i, show: !isEmpty(match) ? match.show : false };
      })
    );
  }, [userStore.prepaidOption]);

  return (
    <Modal size="sm" containerClass="bg-black" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalHeader onToggle={onToggle}></ModalHeader>

        <div className="px-8 text-white">
          <h3 className="modal-title">Column Customization</h3>
          <table className="table w-full settings custom-column">
            <thead className="border-b border-primary_dark">
              <tr>
                <th scope="col" className="text-center th-show">
                  Show
                </th>
                <th scope="col" className="text-left th-column">
                  Column
                </th>
              </tr>
            </thead>
            <tbody>
              {columnList.map((i) => (
                <tr key={i.key}>
                  <td data-label={i.key} className="flex justify-center">
                    <Checkbox
                      checked={i.show}
                      onChange={() => handleChange(columnList, i)}
                      name={i.key}
                      methods={methods}
                    />
                  </td>
                  <td data-label={i.key} className="text-center">
                    {PREPAID_COLUMNS.find((j) => j.key === i.key).label}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <ModalFooter>
          <div className="p-8">
            <button onClick={() => onToggle()} className="ml-auto uppercase btn btn-modal">
              Cancel
            </button>
            <button className="ml-2 uppercase btn btn-modal">Submit</button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(SettingModal));
