import { configure, makeObservable, observable } from 'mobx';
import { isEmpty, isNull } from 'lodash';
import { removeEmpty } from 'utils';
import { STORE_DEFAULT_ITEMS } from 'definitions';

configure({
  enforceActions: 'never',
});

export default class PostpaidStore {
  constructor(props) {
    this.api = props.api;
    this.status = [];
    this.filters = {};
    this.items = STORE_DEFAULT_ITEMS;
    this.iccid_cache_created = null;
    this.totalNumber = 0;
    this.filteredData = [];
    this.totalUsage = 0;

    makeObservable(this, {
      status: observable,
      items: observable,
      iccid_cache_created: observable,
      totalNumber: observable,
      filteredData: observable,
      totalUsage: observable,
    });
  }

  setTotalNumber = (value) => {
    this.totalNumber = value;
  };

  setFilteredData = (value) => {
    this.filteredData = value;
    // this.totalUsage = value.map((i) => i.original.usageMb).reduce((a, c) => a + c, 0);
  };

  getStatus = async () => {
    const response = await this.api.getStatus();

    this.status = response.status;

    return response.status;
  };

  setFilters = (filters) => {
    this.filters = removeEmpty(filters);
  };

  invalidateItems = () => {
    this.iccid_cache_created = null;
    this.items = STORE_DEFAULT_ITEMS;
    // this.totalNumber = 0;
    // this.filteredData = [];
    // this.totalUsage = 0;
  };

  getData = async (viewAs) => {
    this.items.isLoading = true;

    if (isNull(this.iccid_cache_created) && isEmpty(this.items.data)) {
      const response = await this.api.getData(this.filters, viewAs);
      const { iccid_cache_created, devices } = response;

      this.iccid_cache_created = iccid_cache_created;
      this.items = { isLoading: false, data: devices };

      return response;
    } else {
      this.items.isLoading = false;
      return this.items;
    }
  };

  refresh = (payload, viewAs) => this.api.refresh(payload, viewAs);
  refreshPartnerICCID = (payload, viewAs) => this.api.refreshPartnerICCID(payload, viewAs);
  download = (email) => this.api.download(email, this.filters);

  resume = (iccid) => this.api.resume(iccid);
  suspend = (iccid) => this.api.suspend(iccid);
  status = (iccid) => this.api.status(iccid);
  getIMEIHistory = (iccid) => this.api.getIMEIHistory(iccid);

  setPrepaidToPostpaid = (value, email) => this.api.setPrepaidToPostpaid(value, email);
}
