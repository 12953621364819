import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import CreateTicket from 'components/common/CreateTicket';
import cx from 'classnames';
import Filter from './components/Filter';
import React from 'react';
import SettingModal from './components/SettingModal';
import Tooltip from 'components/common/Tooltip';
import TopupButtons from '../components/TopupButtons';
import useToggle from 'hooks/useToggle';
import View from './components/View';

const List = ({
  data,
  items,
  filterDefaults,
  isLoading,
  options,
  prepaidStore,
  elemRef,
  handleFilters,
  setFilters,
  userStore,
  poolPlanStore,
}) => {
  const { toggle, handleToggle } = useToggle({ mobileFilter: false, cloudTopup: false, connectTopup: false });

  const getData = React.useCallback(async () => {
    await prepaidStore.invalidateItems();
    await prepaidStore.getRoles(userStore.viewAs);
    await prepaidStore.getData(userStore.viewAs);
  }, [prepaidStore, userStore.viewAs]);

  const tableData = React.useMemo(() => {
    return items.map((item) => {
      const isPooled = poolPlanStore.SN_LIST.includes(item.sn);
      const matchingSNs = poolPlanStore.sns.filter((sn) => sn.sn === item.sn || sn.masterSN === item.sn) || [];

      const totalUsage =
        isPooled && item.totalPeriodFreeKb
          ? matchingSNs.reduce((accumulator, sn) => {
              return (
                sn.iccids.reduce((iccidsAccumulator, iccid) => {
                  return iccidsAccumulator + iccid.freeKb;
                }, 0) + accumulator
              );
            }, 0) + item.totalPeriodFreeKb
          : isPooled && !item.totalPeriodFreeKb
          ? matchingSNs.reduce((accumulator, sn) => {
              return (
                sn.iccids.reduce((iccidsAccumulator, iccid) => {
                  return iccidsAccumulator + iccid.freeKb;
                }, 0) + accumulator
              );
            }, 0)
          : item.totalPeriodFreeKb;

      const totalQuota =
        isPooled && item.totalPeriodQuotaKb
          ? matchingSNs.reduce((accumulator, sn) => {
              return (
                sn.iccids.reduce((iccidsAccumulator, iccid) => {
                  return iccidsAccumulator + iccid.quotaKb;
                }, 0) + accumulator
              );
            }, 0) + item.totalPeriodQuotaKb
          : isPooled && !item.totalPeriodQuotaKb
          ? matchingSNs.reduce((accumulator, sn) => {
              return (
                sn.iccids.reduce((iccidsAccumulator, iccid) => {
                  return iccidsAccumulator + iccid.quotaKb;
                }, 0) + accumulator
              );
            }, 0)
          : item.totalPeriodQuotaKb;

      return { ...item, isPool: isPooled, totalUsage, totalQuota };
    });
  }, [items, poolPlanStore.SN_LIST, poolPlanStore.sns]);

  return (
    <>
      <View
        defaultSelected={options.defaultSelected}
        items={tableData}
        onSelect={options.handleSelect}
        tableRef={elemRef}
        isLoading={isLoading}
        defaultPageSize={options.defaultPageSize}
        columnsShow={userStore.prepaidOption}
      >
        <div className="items-center py-2 mb-3 md:flex">
          <div id="dropdown-filter" className="mr-6">
            <Filter
              data={data}
              items={tableData}
              handleFilters={handleFilters}
              setFilters={setFilters}
              filterDefaults={filterDefaults}
              isLoading={isLoading}
            />
          </div>
          {isEmpty(userStore.viewAs) && <TopupButtons />}
          <div className="flex items-center ml-auto">
            <Tooltip
              placement="top"
              trigger="hover"
              tooltip={<div className="tooltip-config">Column Customization</div>}
            >
              <button
                className={cx('ml-2 btn btn-config bg-white text-primary', {
                  'cursor-not-allowed': isLoading || userStore.viewAs,
                })}
                onClick={() => handleToggle({ settingsModal: !toggle.settingsModal })}
                disabled={isLoading || userStore.viewAs}
              >
                <i className="material-icons md-22">settings</i>
              </button>
            </Tooltip>
          </div>
        </div>
      </View>
      <div className="flex items-center">
        <CreateTicket className="text-sm text-left" />
        {!isLoading && prepaidStore.DEVICE_CACHE_FORMATTED && (
          <div className="last-update">Updated: {prepaidStore.DEVICE_CACHE_FORMATTED}</div>
        )}
      </div>
      {toggle.settingsModal && (
        <SettingModal
          columns={userStore.prepaidOption}
          getData={getData}
          onToggle={() => handleToggle({ settingsModal: !toggle.settingsModal })}
        />
      )}
    </>
  );
};

export default inject(({ userStore, prepaidStore, poolPlanStore }) => ({ userStore, prepaidStore, poolPlanStore }))(
  observer(List)
);
