import { dateFormat } from 'utils';
import { inject, observer } from 'mobx-react';
import { maybePluralize, quotaDimension } from 'utils';
import AddICCID from '../AddICCID';
import CreateTicket from 'components/common/CreateTicket';
import cx from 'classnames';
import ExportCSV from 'components/Sim/UsageRecord/ExportCSV';
import Filter from './components/Filter';
import React from 'react';
import Refresh from 'components/Sim/UsageRecord/Refresh';
import SettingModal from './components/SettingModal';
import Tooltip from 'components/common/Tooltip';
import useToggle from 'hooks/useToggle';
import View from './components/View';

const List = ({
  setAdvancedFilters,
  options,
  filters,
  setFilters,
  filterDefaults,
  postpaidStore,
  userStore,
  elemRef,
  handleFilters,
  items,
}) => {
  const { toggle, handleToggle } = useToggle({ settingsModal: false, addICCID: false });
  const [totalUsage, setTotalUsage] = React.useState(0);
  const [filteredData, setFilteredData] = React.useState([]);

  const onAddICCID = () => {
    handleToggle({ addICCID: !toggle.addICCID });
  };

  React.useEffect(() => {
    setTotalUsage(
      filteredData
        .map((i) => i.original.usageMb)
        .filter((i) => i !== undefined)
        .reduce((a, c) => a + c, 0)
    );
  }, [filteredData]);

  return (
    <>
      <View
        tableRef={elemRef}
        setFilteredData={setFilteredData}
        defaultPageSize={options.defaultPageSize}
        items={items}
      >
        <div className="flex items-center sticky-postpaid-list-filter">
          <div id="dropdown-filter">
            <Filter
              handleFilters={handleFilters}
              setFilters={setFilters}
              filterDefaults={filterDefaults}
              setAdvancedFilters={setAdvancedFilters}
              filters={filters}
              isLoading={postpaidStore.items.isLoading}
            />
          </div>

          <button className="flex items-center justify-center md:ml-2 btn btn-secondary" onClick={() => onAddICCID()}>
            <div className="icons icon-plus"></div>
            Add ICCID
          </button>
          <div className="flex items-center ml-auto">
            <Refresh />
            <ExportCSV />
            <Tooltip
              placement="top"
              trigger="hover"
              tooltip={<div className="tooltip-config">Column Customization</div>}
            >
              <button
                className={cx('ml-2 btn btn-config bg-white text-primary', {
                  'cursor-not-allowed': postpaidStore.items.isLoading || userStore.viewAs,
                })}
                onClick={() => handleToggle({ settingsModal: !toggle.settingsModal })}
                disabled={postpaidStore.items.isLoading || userStore.viewAs}
              >
                <i className="material-icons md-22">settings</i>
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="flex items-center mb-2 font-semibold">
          Total: {postpaidStore.totalNumber} {maybePluralize(postpaidStore.totalNumber, 'SIM')}
          <span className="total-divider">|</span>
          {quotaDimension(totalUsage).quota + ' ' + quotaDimension(totalUsage).type} SIM/ eSIM Usage
        </div>
      </View>

      <div className="flex items-center mb-12">
        <CreateTicket className="text-sm text-left" />
        {!postpaidStore.items.isLoading && postpaidStore.iccid_cache_created && (
          <div className="last-update">
            Updated: {dateFormat(postpaidStore.iccid_cache_created, 'dd-MM-yyyy HH:mm')}
          </div>
        )}
      </div>

      {toggle.settingsModal && (
        <SettingModal
          columns={userStore.postPaidOption}
          getData={userStore.me}
          onToggle={() => handleToggle({ settingsModal: !toggle.settingsModal })}
        />
      )}

      {toggle.addICCID && <AddICCID onToggle={() => handleToggle({ addICCID: !toggle.addICCID })} />}
    </>
  );
};

export default inject(({ userStore, postpaidStore }) => ({ userStore, postpaidStore }))(observer(List));
