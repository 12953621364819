import React from 'react';
import styled from 'styled-components';

export default ({ name, className, onFileSet, placeholder = '' }) => {
  const [filename, setFilename] = React.useState();

  const handleUpload = (e) => {
    onFileSet(e.target, (filename) => {
      setFilename(filename);
    });
  };
  return (
    <FileUploadStyled htmlFor={name} className={className}>
      <div className="flex bg-white">
        <div className="text-base font-normal pl-3 py-3">
          {(placeholder && <span className="text-placeholder">{placeholder}</span>) || filename}
        </div>
        <div className="ml-auto btn btn-primary flex btn-browse items-center">
          <span>Browse</span>
        </div>
      </div>
      <input type="file" name={name} id={name} onChange={handleUpload} />
    </FileUploadStyled>
  );
};

const FileUploadStyled = styled.label`
  input[type='file'] {
    position: absolute;
    left: -9999px;
  }
`;
