import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { quotaGBSizeFromKB } from 'utils';
import EditPooledSetting from '../Components/EditPooledSetting';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import useToggle from 'hooks/useToggle';

const TopupSettings = ({ poolPlanStore, userStore }) => {
  const data = React.useMemo(() => poolPlanStore.poolPlan.item, [poolPlanStore.poolPlan]);
  const onToggleUpdateSetting = () => handleToggle({ updateSetting: !toggle.updateSetting });
  const { toggle, handleToggle } = useToggle({ updateSetting: false });

  return (
    <>
      <div className="section">
        <div className="flex justify-between">
          <h1 className="title">Top Up Settings</h1>
          {data?.id && isEmpty(userStore.viewAs) && (
            <div className="flex items-center mb-4 cursor-pointer" onClick={onToggleUpdateSetting}>
              <div className="mr-2 icons icon-pooled-edit" />
              <span className="font-semibold text-primary">Edit</span>
            </div>
          )}
        </div>
        {poolPlanStore.poolPlan.isLoading && <Skeleton height={200} />}
        {!poolPlanStore.poolPlan.isLoading && !isEmpty(data) && (
          <div className="pt-8 pb-4 box">
            <div className="flex item">
              <span className="subtitle">
                Auto Top Up Amount:{' '}
                <span className="text-green">{`+ ${quotaGBSizeFromKB(data?.autoTopupAmountKb).resultNum} ${
                  quotaGBSizeFromKB(data?.autoTopupAmountKb).resultDim
                }`}</span>
              </span>
            </div>
            <div className="border-t border-gray-300">
              <div className="sub-text">Trigger Auto Top Up when ICCID</div>
              <div className="flex item">
                <span className="subtitle">
                  Remaining Quota:{' '}
                  <span>{`Below ${quotaGBSizeFromKB(data?.thresholdTopupKb).resultNum} ${
                    quotaGBSizeFromKB(data?.thresholdTopupKb).resultDim
                  }`}</span>
                </span>
              </div>
            </div>
          </div>
        )}

        {!poolPlanStore.poolPlan.isLoading && isEmpty(data) && (
          <div className="pt-8 pb-4 box">
            <div className="flex item">
              <span className="subtitle">
                Auto Top Up Amount: <span className="text-green">{`+ 500 MB`}</span>
              </span>
            </div>
            <div className="border-t border-gray-300">
              <div className="sub-text">Trigger Auto Top Up when ICCID</div>
              <div className="flex item">
                <span className="subtitle">
                  Remaining Quota: <span>{`Below 100 MB`}</span>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {toggle.updateSetting && isEmpty(userStore.viewAs) && (
        <EditPooledSetting onToggle={() => handleToggle({ updateSetting: !toggle.updateSetting })} />
      )}
    </>
  );
};

export default inject(({ poolPlanStore, userStore }) => ({ poolPlanStore, userStore }))(observer(TopupSettings));
