import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, maxQuotaSim, handleSimUsage }) => {
  return (
    <FilterGroup isLoading={isLoading} label="Unused eSIM Quota">
      <div className="flex items-center">
        <span className="mr-2">Up To</span>
        <input
          className="w-1/2 mr-2 text-center text-black bg-secondary"
          value={maxQuotaSim.amount || ''}
          style={{ width: `50px` }}
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              handleSimUsage({ type: maxQuotaSim.type, amount: value >= 1 ? Number(value) : 0 });
            } else {
              handleSimUsage({ type: 'TB', amount: '' });
            }
          }}
        />
        <select
          name="maxQuotaSim"
          className="bg-secondary"
          style={{ width: `50px` }}
          value={maxQuotaSim.type || ''}
          onChange={(e) => handleSimUsage({ type: e.target.value, amount: maxQuotaSim.amount })}
        >
          <option value="TB">TB</option>
          <option value="GB">GB</option>
          <option value="MB">MB</option>
        </select>
      </div>
    </FilterGroup>
  );
};
