import { find, get } from 'lodash';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import React from 'react';

const SUSPENDED = 'Suspend';

const Title = ({ className, iccid, postpaidStore }) => (
  <div className={cx('sfc-card-title flex items-center mb-2', className)}>
    SIM/ eSIM Usage
    {get(find(postpaidStore.status, { iccid }), 'status') === SUSPENDED && (
      <div className="px-2 py-1 ml-auto border border-solid rounded border-danger bg-danger bg-opacity-25 text-danger">
        Suspended
      </div>
    )}
  </div>
);

export default inject(({ postpaidStore }) => ({ postpaidStore }))(observer(Title));
