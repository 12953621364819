import Card from './Card';
import cx from 'classnames';
import NoResultsFound from 'components/common/NoResultsFound';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default ({ children, defaultOptions, prepareRow, selectedFlatRows, rows }) => {
  const { onSelectionChanged } = defaultOptions;

  React.useEffect(() => {
    onSelectionChanged(selectedFlatRows.filter((i) => !i.original.disabled).map(({ original }) => original));
  }, [onSelectionChanged, selectedFlatRows]);

  const n = 24;

  return (
    <>
      {defaultOptions.isLoading ? (
        <div className="my-8 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-12">
          {[...Array(n)].map((i, index) => (
            <div className="px-6 py-5 text-sm bg-white" key={index}>
              <>
                <Skeleton height={25} />
                <Skeleton height={25} />
                <Skeleton height={25} />
                <Skeleton height={75} />
                <Skeleton height={25} />
              </>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div
            id="grid-view"
            className={cx('grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 my-8')}
            style={{ gridAutoRows: '1fr' }}
          >
            {rows.map((row, key) => {
              prepareRow(row);
              return (
                <Card item={row.original} key={`tr-body-${key}`} isloading={defaultOptions.isLoading}>
                  {row.cells.map((cell, key) => {
                    return (
                      <React.Fragment key={key}>
                        {cell.column.code === 'check_box' && cell.render('Cell', { editable: true })}
                      </React.Fragment>
                    );
                  })}
                </Card>
              );
            })}
            {children}
          </div>

          <div>{!rows.length && !defaultOptions.isLoading && <NoResultsFound />}</div>
        </>
      )}
    </>
  );
};
