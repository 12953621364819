import AuthService from './auth';
import Http from './http';
import MeService from './me';
import PoolPlanService from './poolplan';
import PostpaidService from './postpaid';
import PrepaidService from './prepaid';
import SaServices from './sa';
import SimServices from './sim';
import UserServices from './user';

export const http = new Http();

export default {
  authService: new AuthService({ http }),
  saService: new SaServices({ http }),
  simService: new SimServices({ http }),
  userService: new UserServices({ http }),
  postpaidService: new PostpaidService({ http }),
  meService: new MeService({ http }),
  prepaidService: new PrepaidService({ http }),
  poolPlanService: new PoolPlanService({ http }),
};
