import { flatMap, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import ConnectProtectProductTable from 'components/TopUp/components/ConnectProtectProductTable';
import Loading from 'components/common/Loading';
import React from 'react';

const ConnectProtectTopup = ({ onToggle, onToggleConfirmPurchaseModal, prepaidStore, saStore }) => {
  const methods = useForm();
  const { handleSubmit, watch, register } = methods;
  const productId = watch();

  const [isAllowed, setIsAllowed] = React.useState(true);
  const planData = React.useMemo(() => saStore.connectProtect, [saStore.connectProtect]);
  const isLoading = React.useMemo(() => saStore.isLoading, [saStore.isLoading]);

  const onSubmitProceed = (proceed = false) => {
    saStore
      .post(proceed)
      .then((res) => {
        if (isEmpty(res.data.sfcPlanDetails)) {
          onToggle();
          window.location.href = process.env.REACT_APP_ESTORE_CART;
        } else {
          saStore.setSfcPlanDetails(res.data.sfcPlanDetails);
          onToggle();
          onToggleConfirmPurchaseModal();
        }
      })
      .catch((err) => {
        const error = flatMap(
          err.errors.map((i) => {
            if (!isEmpty(i?.message?.errorMessages)) {
              return i.message.errorMessages.map((errorMsg) => errorMsg.message);
            }

            return i.message;
          })
        );
        prepaidStore.setServerErrors(error);
        onToggle();
      });
  };

  const onInitialSubmit = async ({ productId }) => {
    saStore.setProductId(productId);
    saStore.setSNS(prepaidStore.selectedCloudDevices.map((i) => i.sn));
    onSubmitProceed(false);
  };

  React.useEffect(() => {
    saStore.get();
  }, [saStore]);

  React.useEffect(() => {
    setIsAllowed(!prepaidStore.selectedCloudDevices.map((i) => i.top_up_plan_eligible).some((i) => i === true));
  }, [prepaidStore.selectedCloudDevices]);

  return (
    <>
      <Modal containerClass="bg-black with-scroll" onToggle={onToggle}>
        <form onSubmit={handleSubmit(onInitialSubmit)}>
          <ModalHeader onToggle={onToggle}></ModalHeader>
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              {!isEmpty(planData.content) ? (
                <div className="px-8 text-white">
                  <h3 className="modal-title">Select SpeedFusion Connect Usage Plan</h3>
                  <p className="mb-6">You will be redirected to Peplink eStore to check out.</p>
                  <ConnectProtectProductTable planData={planData} methods={methods} register={register} />
                </div>
              ) : (
                <div className="px-8 text-white">
                  <h3>Top up is not available in your region. Please try again later.</h3>
                </div>
              )}

              {!isEmpty(planData?.content) ? (
                <div className="flex items-center justify-end mr-6">
                  <div className="p-6">
                    <button onClick={() => onToggle()} className="ml-auto btn btn-modal" type="button">
                      Cancel
                    </button>
                    <button disabled={!productId.productId && !isAllowed} className="ml-2 btn btn-modal" type="submit">
                      Add to cart
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-end p-6">
                  <button onClick={() => onToggle()} className="ml-auto uppercase btn btn-modal">
                    OK
                  </button>
                </div>
              )}
            </>
          )}
          <div className="mb-6">
            {isAllowed && (
              <div className="p-6 text-danger">
                <i className="text-lg material-icons">error_outline</i> Serial number is invalid or does not match the
                product, please verify (
                {prepaidStore.selectedCloudDevices
                  .filter((i) => !i.top_up_plan_eligible)
                  .map((i) => i.sn)
                  .toString()}
                )
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default inject(({ prepaidStore, saStore }) => ({ prepaidStore, saStore }))(observer(ConnectProtectTopup));
