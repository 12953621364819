import { formatDateTime, quotaGBSizeFromKB, quotaTBSizeFromKB } from 'utils';
import { inject, observer } from 'mobx-react';
import HistoryTable from '../Components/HistoryTable';
import React from 'react';

const History = ({ tableRef }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'createdAt',
        Cell: (props) => formatDateTime(props?.cell?.value),
        className: 'col-time',
      },
      {
        Header: 'Top Up',
        accessor: 'deviceSn',
        className: 'col-history-top-up',
        Cell: (props) =>
          props?.cell?.value
            ? `Allocate data to device:  ${props?.cell?.value}`
            : props?.row?.original?.comment === 'sfc5glte_quota_return'
            ? `Remaining eSIM data from new added device`
            : props?.row?.original?.comment === 'pooledplan_unused_quota_return'
            ? `Remaining eSIM data from removed device`
            : props?.row?.original?.orderRef
            ? `Top Up eSIM Data Pooled Plan: ${props?.row?.original?.orderRef}`
            : '-',
      },
      {
        Header: 'Effect',
        accessor: 'topupAmountKb',
        Cell: (props) => (
          <span
            style={{
              color: props?.cell?.value > 0 ? '#20A62E' : '#FF0000',
            }}
          >
            {props?.cell?.value / 1000000 > 1}
            {props?.cell?.value > 0 ? '+ ' : '- '}
            {quotaTBSizeFromKB(props?.cell?.value).resultNum > 0
              ? quotaTBSizeFromKB(props?.cell?.value).resultNum + ' ' + quotaTBSizeFromKB(props?.cell?.value).resultDim
              : quotaTBSizeFromKB(props?.cell?.value * -1).resultNum +
                ' ' +
                quotaTBSizeFromKB(props?.cell?.value * -1).resultDim}
          </span>
        ),
        className: 'col-history-normal',
      },
      {
        Header: 'Balance',
        accessor: 'balanceLeftKb',
        className: 'col-history-normal',
        Cell: (props) => (
          <span>
            {quotaGBSizeFromKB(props?.cell?.value, props?.cell?.value).resultNum +
              ' ' +
              quotaGBSizeFromKB(props?.cell?.value, props?.cell?.value).resultDim}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <HistoryTable
      tableRef={tableRef}
      filterBy="deviceSn"
      filterByLabel="Serial Number"
      topupOnly={false}
      columns={columns}
    />
  );
};

export default inject(({ poolPlanStore, userStore }) => ({ poolPlanStore, userStore }))(observer(History));
