import React from 'react';

export default () => {
  return (
    <div className="bg-white text-black px-10 py-8 mb-8">
      <div className="grid gap-12 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        {items.map((i) => (
          <Card i={i} key={i.name} />
        ))}
      </div>
    </div>
  );
};

const Card = ({ i }) => (
  <div id="sfc-odc-a" className="bg-white text-black">
    <div className="sim-plan-name mb-4">{i.name}</div>
    <div className="mt-6">
      <div className="grid gap-4 grid-cols-3 text-center">
        <div className="flex flex-col justify-center items-center">
          <div className="sim_capacity">
            <div className="sim-usage-text sim-plan-detail">{i.connectivity.type}</div>
            <div className="sim-plan-figure black">{i.connectivity.size}</div>
            <div className="sim-usage-text sim-plan-detail">Connectivity</div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <i className="icons icon-sfcloud_speed mx-auto mb-2"></i>
          <div className="sim-plan-figure">{i.speed}</div>
          <small className="sim-plan-detail">Speed</small>
        </div>
        <div className="flex flex-col justify-center items-center">
          <i className="icons icon-sfcloud_lifetime mx-auto mb-2"></i>
          <div className="sim-plan-figure">{i.price}</div>
          <small className="sim-plan-detail">PER GB</small>
        </div>
      </div>
    </div>
  </div>
);

const items = [
  { name: 'SFC-ODC-A', connectivity: { type: 'up to', size: '50 GB' }, speed: '200 Mbps', price: '$10.00' },
  { name: 'SFC-ODC-B', connectivity: { type: 'up to', size: '100 GB' }, speed: '200 Mbps', price: '$9.60' },
  { name: 'SFC-ODC-C', connectivity: { type: 'up to', size: '500 GB' }, speed: '200 Mbps', price: '$9.50' },
  { name: 'SFC-ODC-D', connectivity: { type: 'up to', size: '1000 GB' }, speed: '200 Mbps', price: '$8.50' },
  { name: 'SFC-ODC-E', connectivity: { type: 'over', size: '1000 GB' }, speed: '200 Mbps', price: '$8.00' },
];
