import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import React from 'react';
import SimOptions from 'components/Sim/UsageRecord/component/SimOptions';

const Header = ({ item, userStore }) => {
  const userTags = userStore.iccidTags.find((i) => i.iccid === item.iccid);

  return (
    <>
      <div className="flex items-center">
        <div className="flex items-center overflow-hidden">
          <span className="mr-2 material-icons sim-icon">sim_card</span>
          <span className="overflow-hidden text-lg font-semibold text-ellipsis">{item.iccid}</span>
        </div>
        <SimOptions item={item} />
      </div>
      {!isEmpty(userTags) && (
        <div className="flex flex-wrap items-center py-2">
          {userTags.tags.map((tag, index) => (
            <span
              className="inline-block px-2 py-1 mb-2 mr-2 font-semibold text-gray-700 bg-gray-300 bg-opacity-25 rounded-md"
              key={index}
            >
              {tag}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default inject(({ userStore, postpaidStore }) => ({ userStore, postpaidStore }))(observer(Header));
