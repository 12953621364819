import React from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ item }) => (
  <>
    {item.fw && (
      <div className="firmware">
        {parseFloat(item.fw) < 8.1 && item.support_sfc === true ? (
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={
              <div className="tooltip-firmware">To enjoy SpeedFusion Cloud, a firmware 8.1 or above is required</div>
            }
          >
            <div className="flex items-center cursor-pointer text-danger">
              <span className="mr-1 material-icons icon-firmware-error md-18">error_outline</span>
              {item.fw.substring(0, 5)}
            </div>
          </Tooltip>
        ) : (
          item.fw.substring(0, 5)
        )}
      </div>
    )}
  </>
);
