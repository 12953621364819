import React from 'react';

export default ({ onToggle, children, classNames }) => {
  return (
    <div
      className={`relative top-0 z-10 flex flex-row items-center w-full border-t-8 border-primary ${classNames?.modal}`}
    >
      <div className="w-full px-6 pt-4 pb-3 mb-4 font-bold uppercase text-primary bg-brown">{children}</div>
      {onToggle && (
        <button
          onClick={() => onToggle(false)}
          className={`absolute top-0 right-0 mt-4 mr-4 text-2xl text-white material-icons ${classNames?.closeBtn}`}
        >
          close
        </button>
      )}
    </div>
  );
};
