import { ESIM_DATA_PLAN } from '../../../definitions';

export function findDataRegion(iccid_details = []) {
  if (Array.isArray(iccid_details) && iccid_details.length > 0) {
    const iccid_detail = iccid_details.find(
      (iccid) => iccid.data_region === ESIM_DATA_PLAN.US || iccid.data_region === ESIM_DATA_PLAN.GLOBAL
    );
    if (iccid_detail) return iccid_detail.data_region;
    else return '';
  }
}
