import React from 'react';

export default ({ isLoading, handleResetFilters }) => {
  return (
    <div className="flex sidebar-title">
      <i className="mr-1 material-icons material-icons-outlined md-20">filter_list</i> Filter
      <button className="ml-auto btn btn-filter" type="button" disabled={isLoading} onClick={handleResetFilters}>
        Reset Filter
      </button>
    </div>
  );
};
