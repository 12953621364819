import { includes } from 'lodash';
import { inject, observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import CreatableSelect from 'components/common/form/CreatableSelect';
import cx from 'classnames';
import React from 'react';

const LandingPageOption = ({ methods, name, label, value, children, className }) => {
  const { register, watch } = methods;
  const values = watch();

  return (
    <label
      htmlFor={`${name}-${value}`}
      className={cx(
        'default-landing-page-option',
        {
          'border-primary': values[name] === value,
          'border-gray-800': values[name] !== value,
        },
        className
      )}
    >
      <input
        type="radio"
        name={name}
        id={`${name}-${value}`}
        {...(register && { ref: register({}) })}
        className="absolute z-0 w-0 h-0 opacity-0"
        value={value}
      />
      {children}
      <span className="px-5 text-center md:px-0"> {label}</span>
    </label>
  );
};

const UserSettings = ({ history, userStore }) => {
  const SF_CLOUD = JSON.parse(localStorage.getItem(process.env.REACT_APP_APP_NAME));
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const onSave = async (data) => {
    const { tags, ...rest } = data;
    const payload = { ...rest, ...(tags && { tags: tags.map((tag) => tag.value) }) };
    const response = await userStore.update(payload);

    if (response.statusCode === 202) {
      window.alert('User Settings saved.');
    }
  };

  React.useEffect(() => {
    userStore.me(true).then((res) => {
      reset({
        landing: res.item.landing,
        tags: res.item.tags.map((i) => ({
          label: i,
          value: i,
          isFixed: res.item.iccidTags.some((device) => includes(device.tags, i)),
        })),
      });
    });
  }, [reset, userStore]);

  return (
    <>
      <div className="sticky-postpaid">
        <div className="flex items-center justify-between">
          <h1 className="flex items-center py-4 font-medium">
            <i className="mr-2 icons text-primary material-icons md-22">settings</i>
            User Settings
          </h1>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSave)} className="w-full md:w-3/5 lg:w-2/5">
        <h2 className="mb-4 font-semibold">Default Landing Page</h2>
        <div className="flex gap-5">
          <LandingPageOption methods={methods} name="landing" value="prepaid" label="SFC Management">
            <i className="mr-2 icons icon-topup-setting md-16 md:md-14" />
          </LandingPageOption>
          {SF_CLOUD && JSON.parse(SF_CLOUD?.isPostpaidUser) && (
            <LandingPageOption methods={methods} name="landing" value="postpaid" label="Postpaid Service Management">
              <i className="mr-2 icons icon_connect2" />
            </LandingPageOption>
          )}
          <LandingPageOption methods={methods} name="landing" value="pool" label="Pool Management">
            <i className="mt-1 mr-2 icons icon_pool_setting" />
          </LandingPageOption>
        </div>
        <div className="py-8">
          <CreatableSelect
            name="tags"
            label="Tags"
            methods={methods}
            isMulti
            isClearable={userStore.tags.some((i) => !i.isFixed)}
          />
        </div>
        <div className="flex py-8 justify-items-end">
          <button type="button" onClick={() => history.goBack()} className="ml-auto uppercase btn btn-modal">
            Cancel
          </button>
          <button className="ml-2 uppercase btn btn-modal">Submit</button>
        </div>
      </form>
    </>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(UserSettings));
