import { isNull } from 'lodash';
import HasQuota from './HasQuota';
import NoQuota from './NoQuota';
import NoSFWanLicense from './NoSFWanLicense';
import React from 'react';
import Unlimited from './Unlimited';

export default ({ data }) => {
  return (
    <div>
      {data.sfwan_license && (
        <>
          {data.sfwan_license?.unlimited_data_volume === true ? (
            <Unlimited />
          ) : (
            <>
              {data.sfwan_license && isNull(data.sfwan_license.quota_mb) && <NoQuota item={data} />}
              {data.sfwan_license && !isNull(data.sfwan_license.quota_mb) && <HasQuota item={data} />}
            </>
          )}
        </>
      )}
      {!data.sfwan_license && <NoSFWanLicense item={data} />}
    </div>
  );
};
