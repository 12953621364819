import {
  filterActivated,
  filterBySelect,
  filterBySelectTopUp,
  filterCarrierPostpaid,
  filterFirmware,
  filterLessThan,
  filterLessThanQuota,
  filterStatusPostpaid,
  filterSubscribed,
  filterUsageSim,
} from 'utils';
import { find, get, includes, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import Component from './Component';
import React from 'react';
import ReactTable from 'components/common/ReactTable';

const SUSPEND = 'Suspended';

const Table = ({
  tableRef,
  defaultSelected,
  defaultPageSize,
  children,
  userStore,
  postpaidStore,
  setFilteredData,
  items,
}) => {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'grp_name',
        className: 'hidden',
      },
      {
        className: 'hidden',
        accessor: 'product.name',
      },
      {
        accessor: 'name',
        className: 'hidden',
      },
      {
        accessor: 'sfwan_license',
        className: 'hidden',
        filter: filterSubscribed,
      },
      {
        accessor: 'sfwan_license.quota_mbFrom',
        className: 'hidden',
        filter: filterLessThanQuota,
      },
      {
        accessor: 'sfwan_license.quota_mbTo',
        className: 'hidden',
        filter: filterLessThanQuota,
      },
      {
        accessor: 'sn',
        className: 'hidden',
      },
      {
        Header: 'Status',
        accessor: 'trial_plan.activated',
        className: 'hidden',
        filter: filterActivated,
      },
      {
        Header: 'Status',
        accessor: 'activation_plan.activated',
        className: 'hidden',
        filter: filterActivated,
      },
      {
        accessor: 'trial_plan.exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterLessThan,
      },
      {
        accessor: 'activation_plan.exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterLessThan,
      },
      {
        accessor: 'service_exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterBySelect,
      },
      {
        accessor: 'sfwan_license.exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterBySelectTopUp,
      },
      {
        accessor: 'item.fw_ver',
        className: 'hidden',
        filter: filterFirmware,
      },
      {
        accessor: 'iccid',
        className: 'hidden',
      },
      {
        accessor: 'from',
        className: 'hidden',
      },
      {
        accessor: 'to',
        className: 'hidden',
      },
      {
        accessor: 'usage_mb_from',
        className: 'hidden',
        filter: filterUsageSim,
      },
      {
        accessor: 'usage_mb_to',
        className: 'hidden',
        filter: filterUsageSim,
      },
      {
        accessor: 'status',
        className: 'hidden',
        filter: filterStatusPostpaid,
      },
      {
        accessor: 'carrier',
        className: 'hidden',
        filter: filterCarrierPostpaid,
      },
      {
        accessor: 'isDeviceHidden',
        filter: (rows, _, filterValue) => {
          return rows.filter((row) => {
            if (filterValue === 'VISIBLE_ONLY') {
              return !includes(userStore.hiddenDevices.postpaid, row.original.iccid);
            } else if (filterValue === 'HIDDEN_ONLY') {
              return includes(userStore.hiddenDevices.postpaid, row.original.iccid);
            } else if (filterValue === 'SUSPENDED_ONLY') {
              return get(find(postpaidStore.status, { iccid: row.original.iccid }), 'status') === SUSPEND;
            }

            return true;
          });
        },
      },
      {
        accessor: 'tag',
        filter: (rows, _, filterValue) => {
          return rows.filter((row) => {
            const userTags = userStore?.iccidTags?.find((i) => i.iccid === row.original.iccid);

            if (!isEmpty(userTags) && includes(userTags.tags, filterValue)) {
              return true;
            }

            return false;
          });
        },
      },
    ],
    [postpaidStore.status, userStore.hiddenDevices.postpaid, userStore.iccidTags]
  );

  return (
    <ReactTable
      isSimUsage={true}
      header={children}
      perPageText={'SIMs Per Page'}
      tableRef={tableRef}
      setTotalNumber={postpaidStore.setTotalNumber}
      setFilteredData={setFilteredData}
      columns={columns}
      data={items}
      className="mb-5"
      tableClassName="border-primary border-top"
      template={Component}
      options={{
        defaultPageSize,
        isLoading: postpaidStore.items.isLoading,
        defaultSelected,
        isMulti: true,
        onSelectionChanged: () => {
          return [];
        },
        disabledProp: 'disabled',
      }}
    />
  );
};

export default inject(({ postpaidStore, userStore }) => ({ postpaidStore, userStore }))(observer(Table));
