import { format } from 'date-fns';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import FileDownload from 'js-file-download';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const ExportCSV = ({ postpaidStore, userStore }) => {
  const [isDownloading, setDownloading] = React.useState(false);

  const handleDownloadReport = async () => {
    setDownloading(true);
    const timestamp = format(new Date(), 'yyyy-MM-dd-HHmm');
    const filename = `SpeedFusion Connect Postpaid Report - ${timestamp}`;

    postpaidStore
      .download(userStore.viewAs)
      .then((res) => FileDownload(res, `${filename}.xlsx`))
      .finally(() => setDownloading(false));
  };

  return (
    <Tooltip
      placement="top"
      trigger="hover"
      tooltip={<div className="tooltip-refresh">Download and track your usage records</div>}
    >
      <button
        className={cx('btn btn-download flex items-center bg-white text-primary ml-2', {
          'cursor-not-allowed': isDownloading || postpaidStore.items.isLoading,
        })}
        disabled={isDownloading || postpaidStore.items.isLoading}
        onClick={handleDownloadReport}
      >
        <i className="mr-1 material-icons md-20">assignment</i>
        <span className="lg:block xs:hidden">Download Report</span>
      </button>
    </Tooltip>
  );
};

export default inject(({ postpaidStore, userStore }) => ({ postpaidStore, userStore }))(observer(ExportCSV));
