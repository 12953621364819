import React from 'react';

export default () => {
  const [consent, setConsent] = React.useState(false);
  React.useEffect(() => {
    const res = localStorage.getItem(`consent`);
    if (JSON.parse(res) === true) setConsent(true);
    else setConsent(false);
  }, [setConsent]);

  const handleConsent = () => {
    localStorage.setItem(`consent`, true);
    setConsent(true);
  };

  return (
    <>
      {!consent && (
        <div className="flex justify-center">
          <div className="cookie-policy-notice">
            <div className="text-primary">
              We use cookies to improve your online experience.<br></br>By continuing to visit this website you agree to
              our use of cookies. Details of our{' '}
              <a href="//www.peplink.com/support/policies/#support12" target="_blank" rel="noopener noreferrer">
                Cookie Policy
              </a>
              .
            </div>
            <button className="ml-4 uppercase btn btn-primary text-black" onClick={handleConsent}>
              Accept
            </button>
          </div>
        </div>
      )}
    </>
  );
};
