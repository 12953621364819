import AuthStore from './auth';
import PoolPlanStore from './poolplan';
import PostpaidStore from './postpaid';
import PrepaidStore from './prepaid';
import SaStore from './sa';
import services from 'services';
import UserStore from './user';

export default {
  authStore: new AuthStore({ api: services.authService }),
  userStore: new UserStore({ api: services.userService }),
  postpaidStore: new PostpaidStore({ api: services.postpaidService }),
  prepaidStore: new PrepaidStore({ api: services.prepaidService }),
  saStore: new SaStore({ api: services.saService }),
  poolPlanStore: new PoolPlanStore({ api: services.poolPlanService }),
};
