import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, register, handleFilters }) => {
  return (
    <FilterGroup isLoading={isLoading} label="Serial Number">
      <input
        id="input-sn"
        name="sn"
        ref={register()}
        maxLength="14"
        className="w-full p-2 border-transparent"
        placeholder="Please Enter ..."
        onChange={(e) => handleFilters('sn', e.target.value)}
      />
    </FilterGroup>
  );
};
