import { includes } from 'lodash';
import { inject, observer } from 'mobx-react';
import { VISIBILITY } from 'definitions';
import FilterGroup from 'components/common/FilterGroup';
import Radio from 'components/common/form/Checkbox';
import React from 'react';

const Status = ({ items, handleHiddenDevices, isLoading, visibility, userStore, poolPlanStore }) => {
  const count = {
    ALL: items.length,
    VISIBLE_ONLY: items.filter((i) => !includes(userStore.hiddenDevices.prepaid, i.sn)).length,
    HIDDEN_ONLY: items.filter((i) => includes(userStore.hiddenDevices.prepaid, i.sn)).length,
    POOL: items.filter((item) => poolPlanStore.SN_LIST.includes(item.sn)).length,
  };

  return (
    <FilterGroup isLoading={isLoading} label="Status">
      {Object.keys(VISIBILITY).map((key) => (
        <Radio value={key} key={key} onChange={(e) => handleHiddenDevices(e.target.value)} checked={visibility === key}>
          {VISIBILITY[key]} ({count[key]})
        </Radio>
      ))}
    </FilterGroup>
  );
};

export default inject(({ userStore, poolPlanStore }) => ({ userStore, poolPlanStore }))(observer(Status));
