import { checkExpired, formatDate, quotaGBSizeFromKB } from 'utils';
import { ESIM_DATA_PLAN } from '../../../definitions';
import { flatten, isEmpty, sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import { isArray } from 'lodash';
import { ROUTES } from 'definitions';
import { Spinner } from './Icons';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';
import Table from './Table';

const SelectedSNList = ({
  data,
  onBackBtn,
  poolPlanStore,
  prepaidStore,
  userStore,
  onToggle,
  filterData = true,
  redirectToPool = false,
}) => {
  const [selectedData, setSelectedData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalQuota, setTotalQuota] = useState(0);
  const history = useHistory();

  const isPostpaid = (iccidDetails, iccid) => {
    if (iccidDetails?.length > 0) {
      const matchingDetail = iccidDetails.find((detail) => detail.ref_id === iccid);
      return matchingDetail?.billing_type === 'POSTPAID';
    }
  };

  // Accept iccid data region from GLOBAL or null. Reject US
  const checkESimRegion = (iccidDetails) => {
    return iccidDetails.every(
      (iccidDetail) => iccidDetail?.data_region == null || iccidDetail?.data_region === ESIM_DATA_PLAN.GLOBAL
    );
  };

  // detailData?.expiresAt
  const columns = useMemo(
    () => [
      {
        Header: 'Serial Number',
        accessor: 'sn',
        className: 'col-add-device-sn',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div className=" whitespace-no-wrap">{data.value}</div>
            {data?.row?.original?.type === 'module' && (
              <div
                className="text-gray-500 whitespace-no-wrap cursor-default hover:text-primary transition-all duration-300"
                style={{
                  fontSize: 10,
                }}
              >
                Expansion module
              </div>
            )}
          </div>
        ),
      },
      {
        Header: 'Model',
        accessor: 'model',
        className: 'col-add-device-model',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            {data?.value ? <div>{data?.value}</div> : '-'}
          </div>
        ),
      },
      {
        Header: 'Device Name',
        accessor: 'name',
        className: 'col-add-device-name',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div>{data.value || data?.row?.original?.masterName}</div>
          </div>
        ),
      },
      {
        Header: 'ICCID',
        id: 'iccid',
        className: 'col-add-device-iccid',
        Cell: (data) => {
          const { iccids, iccid_details } = data?.row?.original;
          return isArray(iccids) && iccids.length > 0 ? (
            <div className="flex flex-col">
              {iccids.map((item, i) => (
                <div key={i} className={cx({ 'text-danger': isPostpaid(iccid_details, item) })}>
                  {item} {isPostpaid(iccid_details, item) && <span>(ICCID is in Postpaid Plan)</span>}
                </div>
              ))}
            </div>
          ) : (
            <div>-</div>
          );
        },
      },
      {
        Header: 'eSIM Remaining Quota',
        accessor: 'totalPeriodFreeKb',
        className: 'col-add-device-remaining-quota',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            {data?.value ? (
              <div>{quotaGBSizeFromKB(data?.value).resultNum + ` ` + quotaGBSizeFromKB(data?.value).resultDim}</div>
            ) : (
              '0 MB'
            )}
          </div>
        ),
      },
      {
        Header: 'eSIM Expiry Date',
        accessor: 'expiresAt',
        className: 'col-add-device-expiry-date',
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            {data?.value ? <div>{formatDate(data?.value)}</div> : '-'}
          </div>
        ),
      },
      {
        Header: 'Remark',
        id: 'remark',
        className: 'col-add-device-iccid',
        Cell: (data) => {
          const { iccids, iccid_details, support_sfc_sim, subscribed } = data?.row?.original;
          return !support_sfc_sim ? (
            <div className="text-danger">This model does not support eSIM.</div>
          ) : subscribed ? (
            <div style={{ color: '#ABABAB' }}>Serial Number has been added to the Pool Plan.</div>
          ) : !checkESimRegion(iccid_details) ? (
            <div className="text-danger">There are remaining NA & EU eSIM data in the device.</div>
          ) : isArray(iccids) && iccids.length === 0 ? (
            <div className="text-danger">No ICCID</div>
          ) : (
            <div>-</div>
          );
        },
      },
    ],
    []
  );

  const formatData = (raw) => {
    return raw.reduce(
      (pre, cur) => pre.concat([{ sn: cur?.original?.sn, iccids: cur?.original?.iccids, type: cur?.original?.type }]),
      []
    );
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = { devices: formatData(selectedData) };

      await poolPlanStore.addDevices(payload);
      await poolPlanStore.getPool(true);
      await poolPlanStore.getDevices(true);

      await prepaidStore.resetSelectedDevices();
      await poolPlanStore.resetSelectedDevices();

      toast.success(
        `Added ${selectedData.length} device${selectedData.length > 1 ? 's' : ''} to the eSIM Data Pooled Plan.`
      );

      onToggle && onToggle(false);
      if (redirectToPool) {
        history.push(`${ROUTES.POOL_MANAGEMENT}/${poolPlanStore.selectedOrganization}`);
      }
    } catch (error) {
      const message = error?.message || error[0]?.message || error.errors[0]?.message;
      toast.error(`Error: ${message}`);
    }
  };

  const onSelect = (value) => {
    setSelectedData(value);
    setTotalQuota(value.reduce((pre, cur) => pre + cur?.original?.totalPeriodFreeKb, 0));
  };

  const genFooter = (raw) => {
    const iccids = raw.reduce((pre, cur) => {
      return pre + (cur?.original?.iccids?.length || 0);
    }, 0);
    return `${raw.length} Serial Number${raw.length > 1 ? 's' : ''}, ${iccids} ICCID${iccids > 1 ? 's' : ''}`;
  };

  const processData = React.useCallback(
    (raw) => {
      const allModules = flatten(raw.map((i) => i.modules || []));
      const devicesInPool = poolPlanStore.sns || [];

      // filter the modules which in the device
      // and show all the modules in the device with order
      return raw
        .filter((i) => !allModules.some((item) => item.sn === i.sn))
        .sort((a, b) => a - b)
        .reduce((prev, cur) => {
          if (cur.modules) {
            // filter out not eligible to 5g/lte/poolPlan
            const modules = cur.modules.filter((m) => !devicesInPool.some((i) => i.sn === m.sn));
            return prev.concat(cur, modules);
          } else {
            return prev.concat(cur);
          }
        }, []);
    },
    [poolPlanStore]
  );

  useEffect(() => {
    setDevices(() => {
      if (filterData) {
        return processData(data);
      } else {
        return sortBy(data, 'name');
      }
    });

    return () => setIsLoading(false);
  }, [data, filterData, processData]);

  const expired = React.useMemo(() => poolPlanStore.poolPlan.item.expiresAt, [poolPlanStore.poolPlan.item.expiresAt]);

  return (
    <div>
      <div className="mb-4 text-sm text-white">Router compatible with eSIM will be displayed.</div>
      <div className="table-container">
        <Table
          tableType="addDevice"
          data={devices}
          columns={columns}
          withSelectionColumn={true}
          onSelect={onSelect}
          selectDisabledFunc={(row) =>
            row.original.support_sfc_sim === false ||
            row?.original?.subscribed === true ||
            !row.original.iccids ||
            row.original.iccids.length === 0 ||
            !checkESimRegion(row.original.iccid_details) ||
            row.original.iccids.some((iccid) => isPostpaid(row.original.iccid_details, iccid))
          }
        />
      </div>
      <div className="add-modal-desc">
        <div>
          By adding devices into your pooled plan,
          <span className="text-xl font-bold text-primary">
            {` `}
            {quotaGBSizeFromKB(totalQuota).resultNum} {quotaGBSizeFromKB(totalQuota).resultDim} {` `}
          </span>
          of eSIM Data will be transferred to your pool.
        </div>
        <div className="mt-1">
          The <span className="text-primary">Expiration Date</span> of the data will be updated to align with the pool
          <span className="text-primary">
            {` `}
            {checkExpired(expired) || !expired
              ? formatDate(new Date().setDate(new Date().getDate() + 90))
              : formatDate(expired)}
          </span>
          .
        </div>
      </div>
      <div className="bottom-bar">
        <button className="btn btn-outline-primary" onClick={onBackBtn}>
          Back
        </button>
        <div className="bottom-bar-right">
          <div className="text-base">{genFooter(selectedData)}</div>
          <button
            className="ml-6 btn btn-outline-primary"
            onClick={onSubmit}
            disabled={isLoading || selectedData.length === 0 || !isEmpty(userStore.viewAs)}
          >
            {isLoading && <Spinner />}
            {isEmpty(userStore.viewAs) ? 'Confirm' : 'View Only'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default inject(({ poolPlanStore, prepaidStore, userStore }) => ({ poolPlanStore, prepaidStore, userStore }))(
  observer(SelectedSNList)
);
