import { Link } from 'react-router-dom';
import { ROUTES } from 'definitions';
import React from 'react';

export default ({ className }) => {
  return (
    <div id="create-ticket" className={className}>
      <span className="mr-2">Need Help?</span>
      <Link to={{ pathname: ROUTES.SUPPORT_TICKET }} target="_blank" className="mr-2 text-primary">
        Create Support Ticket
      </Link>
    </div>
  );
};
