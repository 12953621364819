import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Modal, ModalFooter, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import CreatableSelect from 'components/common/form/CreatableSelect';
import React from 'react';

const TagModal = ({ onToggle, userStore, item }) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const onSave = async ({ tags }) => {
    await userStore.setTags(item.iccid, { tags: tags?.map((i) => i.value) || [] });
    onToggle();
  };

  React.useEffect(() => {
    const match = userStore.iccidTags.find((i) => i.iccid === item.iccid);

    if (!isEmpty(match)) {
      reset({ tags: match.tags.map((i) => ({ label: i, value: i })) });
    }
  }, [item.iccid, reset, userStore.iccidTags]);

  return (
    <Modal size="sm" containerClass="bg-black" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalHeader onToggle={onToggle} className="relative"></ModalHeader>

        <div className="px-8 text-white">
          <h3 className="modal-title">Tag(s)</h3>
          <h4 className="text-primary">{item.iccid}</h4>
        </div>

        <div className="p-8">
          <CreatableSelect
            name="tags"
            methods={methods}
            isMulti
            options={userStore.tags.map((i) => ({ label: i, value: i }))}
          />
        </div>

        <ModalFooter>
          <button onClick={() => onToggle()} className="ml-auto uppercase btn btn-modal">
            Cancel
          </button>
          <button className="ml-2 uppercase btn btn-modal">Submit</button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(TagModal));
