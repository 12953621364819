import { inject, observer } from 'mobx-react';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

const Tag = ({ isLoading, handleFilters, register, userStore }) => {
  return (
    <FilterGroup label="Tag" isLoading={isLoading}>
      <select id="select-tag" name="tag" ref={register()} onChange={(e) => handleFilters('tag', e.target.value)}>
        <option value="">All</option>
        {userStore.tags.map((tag, index) => (
          <option key={index} value={tag}>
            {tag}
          </option>
        ))}
      </select>
    </FilterGroup>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(Tag));
