import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, maxQuota, handleUsage }) => {
  return (
    <FilterGroup isLoading={isLoading} label="Unused SFC Usage Quota">
      <div className="flex items-center">
        <span className="mr-2">Up To</span>
        <input
          className="w-1/2 mr-2 text-center text-black bg-secondary"
          value={maxQuota.amount || ''}
          style={{ width: `50px` }}
          type="number"
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              handleUsage({ type: maxQuota.type, amount: value >= 1 ? Number(value) : 0 });
            } else {
              handleUsage({ type: 'TB', amount: '' });
            }
          }}
        />
        <select
          name="maxQuota"
          className="bg-secondary"
          style={{ width: `50px` }}
          value={maxQuota.type}
          onChange={(e) => handleUsage({ type: e.target.value, amount: maxQuota.amount })}
        >
          <option value="TB">TB</option>
          <option value="GB">GB</option>
          <option value="MB">MB</option>
        </select>
      </div>
    </FilterGroup>
  );
};
