import React from 'react';

const Unlimited = () => {
  return (
    <div className="flex items-center ml-10">
      <div className="flex items-center">
        <i className="mr-2 icons icon-5g-plan" />
        <div className="sfc-list-view-content">
          <span className="remaining-sfc-usage-type text-primary">Unlimited</span>
        </div>
      </div>
    </div>
  );
};

export default Unlimited;
