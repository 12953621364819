import IsPooledTitle from './IsPooledTitle';
import React, { useEffect, useState } from 'react';
import RemainingTitle from './RemainingTitle';
import ZeroRemainingTitle from './ZeroRemainingTitle';

export default ({ item, className, isPool, poolUsage }) => {
  const [totalUsage, setTotalUsage] = useState(0);
  const [totalQuota, setTotalQuota] = useState(0);

  useEffect(() => {
    let total = 0;
    if (item?.modules?.length > 0) {
      item.modules.forEach((module) => {
        if (typeof module.totalPeriodFreeKb === 'number') total += module.totalPeriodFreeKb;
      });
    }
    if (typeof item.totalPeriodFreeKb === 'number') total += item.totalPeriodFreeKb;

    setTotalUsage(total);
  }, [item.modules, item.totalPeriodFreeKb]);

  useEffect(() => {
    let total = 0;
    if (item?.modules?.length > 0) {
      item.modules.forEach((module) => {
        if (typeof module.totalPeriodQuotaKb === 'number') total += module.totalPeriodQuotaKb;
      });
    }
    if (typeof item?.totalPeriodQuotaKb === 'number') total += item.totalPeriodQuotaKb;

    setTotalQuota(total);
  }, [item.modules, item.totalPeriodQuotaKb]);

  return (
    <div className={className}>
      {isPool && <IsPooledTitle poolUsage={poolUsage} />}
      {!isPool && totalUsage !== 0 && <RemainingTitle item={item} totalUsage={totalUsage} totalQuota={totalQuota} />}
      {!isPool && totalUsage === 0 && <ZeroRemainingTitle item={item} />}
    </div>
  );
};
