import { Modal, ModalFooter, ModalHeader } from 'components/common/Modal';
import React from 'react';

export default ({ onToggle }) => {
  return (
    <Modal containerClass="bg-black" onToggle={onToggle}>
      <ModalHeader onToggle={onToggle} />
      <h3 className="px-8 text-white">Top up is not available. Please try again later.</h3>
      <ModalFooter>
        <div className="p-6">
          <button onClick={() => onToggle()} className="p-6 ml-auto uppercase btn btn-modal">
            OK
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
