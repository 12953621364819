import { encodedEmail } from 'utils';
import { isEmpty } from 'lodash';

export default class PrepaidServices {
  constructor(props) {
    this.url = '/sim';
    this.http = props.http;
  }

  refreshIC2 = (payload, email) =>
    this.http.post(!isEmpty(email) ? `/ic2/clearCache?email=${encodedEmail(email)}` : `/ic2/clearCache`, payload);

  refresh = (payload, email) =>
    this.http.post(
      !isEmpty(email) ? `${this.url}/clearCache?email=${encodedEmail(email)}` : `${this.url}/clearCache`,
      payload
    );

  getData = (payload, email) =>
    this.http.get(
      !isEmpty(email) ? `${this.url}/prepaid?email=${encodedEmail(email)}` : `${this.url}/prepaid`,
      payload
    );

  download = (email) =>
    this.http.getBlob(
      !isEmpty(email) ? `${this.url}/prepaidReport?email=${encodedEmail(email)}` : `${this.url}/prepaidReport`
    );

  getRoles = (email) =>
    this.http.get(!isEmpty(email) ? `${this.url}/roles?email=${encodedEmail(email)}` : `${this.url}/roles`);
}
