import { dateFormat, dayDifference, quotaSize } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Checkbox from 'components/common/form/Checkbox';
import cx from 'classnames';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const HasQuota = ({ item, prepaidStore, userStore }) => {
  const latestTopUpMB = React.useMemo(() => item.sfwan_license.latest_top_up_mb, [item.sfwan_license.latest_top_up_mb]);
  const quotaMB = React.useMemo(() => item.sfwan_license.quota_mb, [item.sfwan_license.quota_mb]);
  const exp = React.useMemo(() => item.sfwan_license.exp, [item.sfwan_license.exp]);
  const quota = React.useMemo(() => quotaSize(quotaMB, latestTopUpMB), [latestTopUpMB, quotaMB]);

  const checkUnlimited = (item) => {
    if (
      item?.care_summary_general_type !== null &&
      new Date() < new Date(item?.care_summary_general_expiration_date) &&
      parseFloat(item?.fw || item?.fw_ver) >= 8.3
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {/* sfwan_license.quota_mb !== null */}
      <div className={cx('sfc-card-title', quota.availabilityColor)}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            SFC Usage Quota
            {checkUnlimited(item) && (
              <div className="ml-2 text-sm text-primary">{`(up to ${
                exp && dayDifference(exp) >= 0 && checkUnlimited(item) ? (quotaMB !== 0 ? 200 : 10) : 10
              }Mbps)`}</div>
            )}
          </div>
          {isEmpty(userStore.viewAs) && (
            <Checkbox
              onChange={() => prepaidStore.onSelectCloudDevice(item)}
              checked={prepaidStore.isCloudDeviceSelected(item)}
            />
          )}
        </div>
      </div>
      <div className="sfc-card-content">
        {/* 10% lower */}
        {quota.resultPercent > 0 && quota.resultPercent <= 10 ? (
          <Tooltip
            placement="bottom"
            trigger="hover"
            tooltip={
              <div>
                <span className="tooltip-firmware">Your quota is nearly used up.</span>
                <br />
                <span className="tooltip-firmware">Top up SpeedFusion Connect Usage Plan now!</span>
              </div>
            }
          >
            <div className="flex items-center cursor-pointer">
              {checkUnlimited(item) ? (
                <i className="mr-2 icons icon-5g-plan"></i>
              ) : (
                <i className={cx('icons mr-2', quota.iconColor)}></i>
              )}
              <div className="items-end mt-2">
                <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                <span className="mb-2 mr-1 material-icons icon-firmware-error md-16 text-danger">error_outline</span>
              </div>
            </div>
          </Tooltip>
        ) : (
          // cloud logo
          <div className="flex items-center">
            {exp && dayDifference(exp) >= 0 && quotaMB !== 0 ? (
              <i className={cx('icons mr-2', quota.iconColor)}></i>
            ) : quotaMB === 0 && checkUnlimited(item) && exp && dayDifference(exp) >= 0 ? (
              <i className="mr-2 icons icon-5g-plan"></i>
            ) : checkUnlimited(item) ? (
              <i className="mr-2 icons icon-5g-plan"></i>
            ) : quotaMB === 0 || checkUnlimited(item) ? (
              <i className="mr-2 icons icon-5g-plan-dimmed"></i>
            ) : (
              <i className={cx('icons mr-2', quota.iconColor)}></i>
            )}
            {exp && dayDifference(exp) >= 0 && quotaMB === 0 && checkUnlimited(item) ? (
              <div className="items-end text-primary">
                <span className="text-lg font-semibold">Fair Usage Policy</span>
              </div>
            ) : (
              <div className="items-end">
                {quotaMB === 0 && !checkUnlimited(item) ? (
                  <Tooltip
                    placement="bottom"
                    trigger="hover"
                    tooltip={
                      <div>
                        <span className="tooltip-firmware">Your quota is used up.</span>
                        <br />
                        <span className="tooltip-firmware">Top up SpeedFusion Connect Usage Plan now!</span>
                      </div>
                    }
                  >
                    <div className="mt-2 cursor-pointer">
                      <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                      <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                      <span className="mb-2 mr-1 material-icons icon-firmware-error md-16 text-danger">
                        error_outline
                      </span>
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    {exp && dayDifference(exp) < 0 && checkUnlimited(item) ? (
                      <>
                        <div className="items-end text-primary">
                          <span className="text-lg font-semibold">Fair Usage Policy</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <span className={cx('remaining-sfc-usage-number', quota.textColor)}>{quota.resultNum}</span>
                        <span className={cx('remaining-sfc-usage-type', quota.textColor)}>{quota.resultDim}</span>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
        <div
          className={cx(
            'flex items-center',
            exp && dayDifference(exp) >= 0 ? dateFormat(exp) : checkUnlimited(item) ? '' : 'text-danger'
          )}
        >
          <i
            className={cx(
              'icons mr-2',
              (exp && dayDifference(exp) >= 0) || checkUnlimited(item)
                ? 'icon-sfc-expiry-usage-normal'
                : 'icon-sfc-expiry-usage-red'
            )}
          />
          {exp && dayDifference(exp) >= 0 ? dateFormat(exp) : checkUnlimited(item) ? '-' : 'Expired'}
        </div>
      </div>
    </div>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(HasQuota));
