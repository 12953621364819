import { dateFormat, dayDifference, quotaDimension } from 'utils';
import { ESIM_DATA_PLAN } from 'definitions';
import { EsimDataPlanIcon } from 'components/TopUp/TopUp/components/EsimDataPlanIcon';
import { findDataRegion } from 'components/TopUp/helper/findDataRegion';
import ConnectSelector from 'components/TopUp/TopUp/components/Connect/Selector';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ item, totalUsage, totalQuota }) => {
  const [isExpand, setIsExpand] = useState(false);
  const [expiresAt, setExpiresAt] = useState(null);
  let percentageItem = ((totalUsage * 100) / totalQuota).toFixed(0);

  const deviceDataRegion = findDataRegion(item.iccid_details);

  const moduleDataOnTop = item.modules?.filter(
    (module) =>
      Array.isArray(module.iccid_details) &&
      module.iccid_details.some(
        (iccid) => iccid.data_region === (deviceDataRegion || ESIM_DATA_PLAN.US) && iccid.data_region != null
      )
  );

  const moduleDataOnBottom = item.modules?.filter(
    (module) =>
      Array.isArray(module.iccid_details) &&
      module.iccid_details.some(
        (iccid) => iccid.data_region !== (deviceDataRegion || ESIM_DATA_PLAN.US) && iccid.data_region != null
      )
  );

  const hasModuleDataOnTop = Array.isArray(moduleDataOnTop) && moduleDataOnTop.length > 0;
  const hasModuleDataOnBottom = Array.isArray(moduleDataOnBottom) && moduleDataOnBottom.length > 0;

  useEffect(() => {
    let latestDate = null;
    if (item?.expiresAt) {
      latestDate = item.expiresAt;
    }
    if (item?.modules?.length > 0) {
      item.modules.forEach((module) => {
        if (module.expiresAt && (!latestDate || module.expiresAt < latestDate)) {
          latestDate = module.expiresAt;
        }
      });
    }
    setExpiresAt(latestDate);
  }, [item]);

  return (
    <>
      {/* totalPeriodFreeKb !== 0 */}
      <div className="flex items-center">
        <div className="w-full sfc-card-title">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center justify-between w-auto">
              <div className="flex items-center mr-2">eSIM Quota</div>
              {!isExpand && (
                <EsimDataPlanIcon
                  dataRegion={deviceDataRegion}
                  hasModuleDataOnBottom={hasModuleDataOnBottom}
                  isExpand={isExpand}
                />
              )}
            </div>
            <ConnectSelector item={item} deviceDataRegion={deviceDataRegion} />
          </div>
        </div>
      </div>
      <div>
        {!isExpand && (
          <div className="sfc-card-content">
            {/* more then 10% */}
            {percentageItem > 10 && (
              <div className="flex">
                <i className="ml-1 mr-2 icons icon-sim-data-normal" />
                <div className="items-end text-primary">
                  <span className="remaining-sfc-usage-number">{quotaDimension(totalUsage / 1024).quota}</span>
                  <span className="remaining-sfc-usage-type">{quotaDimension(totalUsage / 1024).type}</span>
                  {(hasModuleDataOnTop || hasModuleDataOnBottom) && (
                    <i className="pb-1 cursor-pointer material-icons md-16" onClick={() => setIsExpand(true)}>
                      launch
                    </i>
                  )}
                </div>
              </div>
            )}
            {/* less then 10% */}
            {percentageItem <= 10 && (
              <div className="flex">
                <i className="mr-2 icons icon-sim-data-normal" />
                <Tooltip
                  placement="bottom"
                  trigger="hover"
                  tooltip={
                    <div>
                      <span className="tooltip-firmware">Your quota is nearly used up.</span>
                      <br />
                      <span className="tooltip-firmware">Top up Peplink eSIM Data Plan now!</span>
                    </div>
                  }
                >
                  <div className="items-end cursor-pointer text-primary">
                    <span className="remaining-sfc-usage-number">{quotaDimension(totalUsage / 1024).quota}</span>
                    <span className="remaining-sfc-usage-type">{quotaDimension(totalUsage / 1024).type}</span>
                    <span className="mb-2 mr-1 material-icons icon-firmware-error md-16 text-danger">
                      error_outline
                    </span>
                    {(hasModuleDataOnTop || hasModuleDataOnBottom) && (
                      <i className="pb-2 cursor-pointer material-icons md-16" onClick={() => setIsExpand(true)}>
                        launch
                      </i>
                    )}
                  </div>
                </Tooltip>
              </div>
            )}
            {/* data exp */}
            <div className="flex">
              <span className={cx('flex items-center', dayDifference(expiresAt) < 0 && 'text-danger')}>
                <i
                  className={cx(
                    'icons mr-2',
                    dayDifference(expiresAt) >= 0 ? ' icon-sfc-expiry-usage-normal' : 'icon-sfc-expiry-usage-red'
                  )}
                />
                {dayDifference(expiresAt) >= 0 ? dateFormat(expiresAt) : 'Expired'}
              </span>
            </div>
          </div>
        )}
        {isExpand && (
          <>
            <div className="flex items-end pt-2">
              <div className="w-full mr-auto">
                {(deviceDataRegion || hasModuleDataOnTop) && (
                  <EsimDataPlanIcon dataRegion={deviceDataRegion || ESIM_DATA_PLAN.US} isExpand={isExpand} />
                )}
                <div className="flex flex-wrap">
                  {/* master device 5G/ LTE usage */}
                  {deviceDataRegion && item?.totalPeriodFreeKb && item?.usages && (
                    <div className="flex items-center justify-center w-1/3 px-2 py-1 mb-2 mr-2 bg-gray-200">
                      <div className="icons icon-device-tag" />
                      <div className="font-semibold text-gray-500">
                        {quotaDimension(item?.totalPeriodFreeKb / 1024).quota +
                          ' ' +
                          quotaDimension(item?.totalPeriodFreeKb / 1024).type}
                      </div>
                    </div>
                  )}
                  {deviceDataRegion && !item.totalPeriodFreeKb && (
                    <div className="flex items-center justify-center w-1/3 px-2 py-1 mb-2 mr-2 bg-gray-200">
                      <div className="icons icon-device-tag" />
                      <div className="font-semibold text-gray-500">0 MB</div>
                    </div>
                  )}
                  {/* modules */}
                  {hasModuleDataOnTop &&
                    moduleDataOnTop.map((module, index) => (
                      <div className="w-1/3 px-2 py-1 mb-2 mr-2 bg-gray-200 cursor-pointer" key={index}>
                        <Tooltip
                          placement="bottom"
                          trigger="hover"
                          tooltip={
                            <div>
                              <span className="tooltip-firmware">{module.sn}</span>
                            </div>
                          }
                        >
                          <div className="flex items-center justify-center">
                            <div className="icons icon-module-tag" />
                            <div className="font-semibold text-gray-500">
                              {quotaDimension(module.totalPeriodFreeKb / 1024).quota +
                                ' ' +
                                quotaDimension(module.totalPeriodFreeKb / 1024).type}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    ))}
                </div>

                {hasModuleDataOnBottom && (
                  <EsimDataPlanIcon
                    dataRegion={deviceDataRegion || ESIM_DATA_PLAN.US}
                    isExpand={isExpand}
                    showOnTop={false}
                  />
                )}
                <div className="flex flex-wrap">
                  {hasModuleDataOnBottom &&
                    moduleDataOnBottom.map((module, index) => (
                      <div className="w-1/3 px-2 py-1 mb-2 mr-2 bg-gray-200 cursor-pointer" key={index}>
                        <Tooltip
                          placement="bottom"
                          trigger="hover"
                          tooltip={
                            <div>
                              <span className="tooltip-firmware">{module.sn}</span>
                            </div>
                          }
                        >
                          <div className="flex items-center justify-center">
                            <div className="icons icon-module-tag" />
                            <div className="font-semibold text-gray-500">
                              {quotaDimension(module.totalPeriodFreeKb / 1024).quota +
                                ' ' +
                                quotaDimension(module.totalPeriodFreeKb / 1024).type}
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    ))}
                </div>
              </div>

              <i className="material-icons md-16 md-close-launch" onClick={() => setIsExpand(false)}>
                launch
              </i>
            </div>
          </>
        )}
      </div>
    </>
  );
};
