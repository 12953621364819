import { get } from 'lodash';
import ControlledTable from './ControlledTable';
import React from 'react';
import Table from './Table';

export default ({
  tableRef,
  template,
  columns,
  children,
  data,
  updateMyData,
  skipReset,
  options,
  className,
  setTotalNumber,
  perPageText,
  setFilteredData,
  setSelectedData,
  key = '',
  header,
  isModal = false,
  isSimUsage = false,
  isUsageList = false,
}) => {
  const defaultOptions = {
    perPageText: perPageText,
    sortable: true,
    defaultSelected: {},
    pager: true,
    rowPerPage: true,
    isModal: isModal,
    isSimUsage: isSimUsage,
    isUsageList: isUsageList,
    ...options,
  };

  const Component = defaultOptions.fetchData ? ControlledTable : Table;
  const defaultPageSize = get(defaultOptions, 'filters.pageSize') || defaultOptions.defaultPageSize || 25;

  const defaultTableProps = {
    columns,
    data,
    disableSortBy: !defaultOptions.sortable,
    autoResetPage: !skipReset,
    autoResetSelectedRows: !skipReset,
    initialState: {
      ...(defaultOptions.defaultSelected && { selectedRowIds: defaultOptions.defaultSelected }),
      ...(defaultOptions.hiddenColumns && { hiddenColumns: defaultOptions.hiddenColumns }),
      pageSize: defaultPageSize,
    },
    defaultColumn: React.useMemo(
      () => ({
        sortable: true,
        // width: 0,
        Cell: ({ cell: { value } }) => (typeof value !== 'undefined' && value !== null ? String(value) : null),
      }),
      []
    ),
    updateMyData,
  };

  return (
    <Component
      key={key}
      tableRef={tableRef}
      setTotalNumber={setTotalNumber}
      setFilteredData={setFilteredData}
      setSelectedData={setSelectedData}
      defaultTableProps={defaultTableProps}
      defaultOptions={defaultOptions}
      skipReset={skipReset}
      options={options}
      template={template}
      header={header}
      className={className}
    >
      {children}
    </Component>
  );
};
