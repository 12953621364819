import { isEmpty } from 'lodash';
import cx from 'classnames';
import Footer from './Footer';
import Header from './Header';
import React from 'react';
import Usage from '../Usage';

export default ({ item }) => (
  <div
    className={cx('postpaid-grid-card', {
      dimmed: isEmpty(item.last_seen) || item.usageMb === 0,
    })}
  >
    <div className="card-section">
      <Header item={item} />
      <div className="bottom-of-card">
        <Usage item={item} status={item.status} />
        <Footer item={item} />
      </div>
    </div>
  </div>
);
