import React from 'react';
import { isEmpty } from 'lodash';
import { get } from 'react-hook-form';

const Container = ({ plain, children }) =>
  plain ? (
    <>{children}</>
  ) : (
    <div className="text-danger invalid-feedback block items-center mt-2">
      <i className="material-icons md-16 mr-1">error_outline</i>
      {children}
    </div>
  );

export default ({ plain = false, methods = {}, name = '' }) => {
  if (isEmpty(methods.errors)) return false;
  const error = get(methods.errors, name);

  if (!error) return false;
  let errorMessage;
  switch (error.type) {
    case 'required':
      errorMessage = `${'This field is'} ${error.type}`;
      break;
    default:
      errorMessage = error.message || `${'This field is'} ${'invalid'}`;
  }

  return <Container plain={plain}>{errorMessage}</Container>;
};
