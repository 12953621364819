import React from 'react';

export default ({ serverError }) => {
  const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <div id="error-message" className="text-danger">
        Click{' '}
        <button id="download-log" className="underline" onClick={() => download('log.txt', serverError)}>
          here
        </button>{' '}
        to download the error log.
      </div>
    </>
  );
};
