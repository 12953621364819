import { quotaDimension } from 'utils';
import { SIM_STATUS } from 'definitions';
import React from 'react';

export default ({ item }) => {
  return (
    <>
      {(item.status === SIM_STATUS.ONLINE || item.status === SIM_STATUS.OFFLINE) &&
        quotaDimension(item.usageMb).quota !== 0 && (
          <div className="flex items-center">
            <div className="mr-2 icons icon_carrier" />
            <div className="text-status text-primary">{item.carrier || 'In Use'}</div>
          </div>
        )}

      {item.status === SIM_STATUS.UNKNOWN && quotaDimension(item.usageMb).quota === 0 && (
        <div className="flex items-center no-device-data sn-expire-text-gray">
          <span className="mr-2 material-icons-outlined no-device-data-icon">cloud_upload</span>
          No Device Data
        </div>
      )}
    </>
  );
};
