import { ANOTHER_CARRIER } from 'definitions';
import { sortBy } from 'lodash';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, handleFilters, register, items }) => {
  const [carrierOptions, setCarrierOptions] = React.useState([]);

  React.useEffect(() => {
    setCarrierOptions(
      sortBy(
        Object.values(
          items.reduce((acc, value) => {
            return acc[value.carrier] ? { ...acc } : { ...acc, [value.carrier]: value.carrier };
          }, {})
        ).map((i) => ({ label: i, value: i })),
        (i) => i.label
      ).filter((i) => i.value !== undefined)
    );

    setCarrierOptions((prevState) => [...prevState, ANOTHER_CARRIER]);
  }, [handleFilters, items]);

  return (
    <FilterGroup label="Carrier" isLoading={isLoading}>
      <select
        id="select-carrier"
        name="carrier"
        ref={register()}
        onChange={(e) => handleFilters('carrier', e.target.value)}
      >
        <option value="">All</option>
        {carrierOptions.map((i) => (
          <option key={`carrier-${i.value}`} value={i.value}>
            {i.label}
          </option>
        ))}
      </select>
    </FilterGroup>
  );
};
