import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { maybePluralize, quotaGBSizeFromKB } from 'utils';
import { Spinner } from './Icons';
import { toast } from 'react-toastify';
import React from 'react';
import ReactModal from 'react-modal';

const RemoveDevices = React.forwardRef(({ poolPlanStore }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const snCount = React.useMemo(() => poolPlanStore.devicesToRemove.length, [poolPlanStore.devicesToRemove.length]);
  const iccidCount = React.useMemo(
    () =>
      poolPlanStore.devicesToRemove.reduce((pre, cur) => {
        return pre + (cur?.iccids?.length || 0);
      }, 0),
    [poolPlanStore.devicesToRemove]
  );

  const quota = poolPlanStore.devicesToRemove.reduce((pre, cur) => {
    if (cur.iccids) {
      const freeKbSum = cur.iccids.reduce((sum, iccid) => {
        return sum + (iccid?.freeKb || 0);
      }, 0);
      return pre + freeKbSum;
    } else {
      return pre;
    }
  }, 0);

  React.useImperativeHandle(
    ref,
    () => ({
      setModalOpen: (bool) => {
        setIsOpen(bool);
      },
    }),
    []
  );

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      await poolPlanStore.removeDevices();
      await poolPlanStore.getPool(true);
      await poolPlanStore.getDevices(true);

      toast.success(`Removed ${snCount} ${maybePluralize(snCount, 'device')} from the eSIM Data Pooled Plan.`);
      setIsOpen(false);
      setIsLoading(false);
    } catch (error) {
      const message = error?.message || error[0]?.message;
      toast.error(`Error: ${message}`);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="remove-modal-head"></div>
      <div className="remove-modal">
        <div className="title">
          <div>REMOVE DEVICE</div>
          <span className="text-white cursor-pointer material-icons" onClick={() => setIsOpen(false)}>
            clear
          </span>
        </div>
        <div className="calc-desc">
          By removing the device from the pooled plan, the remaining
          <br />
          <span className="text-primary">
            {quotaGBSizeFromKB(quota).resultNum}
            {` `}
            {quotaGBSizeFromKB(quota).resultDim} of eSIM Data
          </span>{' '}
          will be added to the pool.
        </div>
        <div className="content">
          Do you confirm to remove {snCount} {maybePluralize(snCount, 'device')}, {iccidCount}{' '}
          {maybePluralize(iccidCount, 'ICCID')} from the eSIM Data Pooled Plan?
        </div>
        <div className="btn-bar">
          <div className="mr-6 btn" style={{ borderWidth: 1, width: 140 }} onClick={() => setIsOpen(false)}>
            CANCEL
          </div>
          <button
            className="btn"
            style={{ borderWidth: 1, width: 140 }}
            onClick={onSubmit}
            disabled={isEmpty(poolPlanStore.devicesToRemove) || isLoading}
            type="button"
          >
            {isLoading && <Spinner />}
            CONFIRM
          </button>
        </div>
      </div>
    </ReactModal>
  );
});

export default inject(({ poolPlanStore }) => ({ poolPlanStore }))(observer(RemoveDevices));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#000000',
    height: '250px',
    width: '620px',
    padding: 0,
    borderRadius: 0,
    borderWidth: 0,
  },
  overlay: {
    zIndex: 200,
    backgroundColor: '#00000066',
  },
};
