import { add, formatDistanceStrict, isPast, isWithinInterval } from 'date-fns';
import { computed, makeObservable, observable } from 'mobx';
import { concat, first, isEmpty } from 'lodash';
import {
  ERROR_CODES,
  NOTIFICATION_MSG,
  POOL_LOW_DATA_THRESHOLD,
  STORE_DEFAULT_ITEM,
  STORE_DEFAULT_ITEMS,
} from 'definitions';
import { sortBy } from 'lodash';

export default class PoolPlanStore {
  constructor(props) {
    this.api = props.api;
    this.poolPlan = STORE_DEFAULT_ITEM;
    this.devices = STORE_DEFAULT_ITEMS;
    this.history = STORE_DEFAULT_ITEMS;
    this.balance = STORE_DEFAULT_ITEMS;
    this.order = STORE_DEFAULT_ITEMS;
    this.organizations = { ...STORE_DEFAULT_ITEMS, viewAs: null };
    this.selectedDevices = [];
    this.notification = { orgCode: undefined, info: {}, showing: false, topUpOpen: false };
    this.selectedOrganization = '';
    this.sns = [];
    this.devicesToAdd = [];
    this.devicesToRemove = [];

    makeObservable(this, {
      selectedDevices: observable,
      organizations: observable,
      devices: observable,
      history: observable,
      balance: observable,
      poolPlan: observable,
      // loading: observable,
      notification: observable,
      devicesToRemove: observable,
      selectedOrganization: observable,
      sns: observable,
      order: observable,
      SN_LIST: computed,
    });
  }

  setSelectedDevices = (selected) => {
    this.selectedDevices = selected;
  };

  onSelectDevice = (item) => {
    const isSelected = this.selectedDevices.some((i) => i.sn === item.sn);
    const selected = isSelected
      ? this.selectedDevices.filter((i) => i.sn !== item.sn)
      : concat(this.selectedDevices, item);

    this.setSelectedDevices(selected);
  };

  resetSelectedDevices = () => {
    this.selectedDevices = [];
  };

  onDeselectConnectDevice = (item) => {
    let tempDevices = this.selectedDevices.filter((i) => i.sn !== item.sn);
    if (item?.modules?.length > 0 && tempDevices.length > 0) {
      item.modules.forEach((m) => {
        tempDevices = tempDevices.filter((i) => i.sn !== m.sn);
      });
    }
    this.selectedDevices = tempDevices;
  };

  onNestedSelectDevice = (item, selectedList) => {
    selectedList.forEach((i) => {
      if (item.sn === i) {
        this.onSelectDevice(item);
      } else {
        const selectedItem = item.modules.find((m) => m.sn === i);
        this.onSelectDevice(selectedItem);
      }
    });
  };

  getOrganizations = async (email = null, invalidate = false) => {
    try {
      this.organizations.isLoading = true;

      if (
        (!isEmpty(email) && this.organizations.viewAs !== email) ||
        (!isEmpty(this.organizations.viewAs) && isEmpty(email))
      ) {
        this.organizations.data = [];
      }

      if (!isEmpty(this.organizations.data) && !invalidate) {
        this.organizations.isLoading = false;

        return this.organizations.data;
      } else {
        const response = await this.api.getOrganizations(email);

        this.organizations = {
          isLoading: false,
          data: response.organizations,
          ...(!isEmpty(email) && { viewAs: email }),
        };

        this.selectedOrganization = first(response.organizations)?.code || '';

        return this.organizations.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDevices = async (invalidate = false, email) => {
    this.devices.isLoading = true;

    if ((!isEmpty(this.devices.data) && !invalidate) || isEmpty(this.poolPlan.item)) {
      this.devices.isLoading = false;

      return this.devices.data;
    } else {
      try {
        const res = await this.api.getDevices(this.selectedOrganization, email);

        let data = [];
        if (res.devices && res.devices.length > 0) {
          res.devices.forEach((i) => {
            if (!this.subscribedSN(i)) {
              data.push(i);
            }
          });
        }

        this.devices = { data, isLoading: false };

        return this.devices.data;
      } catch (error) {
        if (error.status === ERROR_CODES.NOT_FOUND) {
          this.devices = { data: [], isLoading: false };
        }
      }
    }
  };

  subscribedSN = (sn) => {
    if (isEmpty(this.sns)) return false;
    return this.sns.some((i) => i.sn === sn.sn);
  };

  getPool = async (invalidate = false, email = '') => {
    this.poolPlan.isLoading = true;

    if (!isEmpty(this.poolPlan.item) && this.poolPlan.item.id === this.selectedOrganization && !invalidate) {
      this.poolPlan.isLoading = false;
      return this.poolPlan.item;
    } else {
      try {
        let sortedSNS = [];
        const { item } = await this.api.getPool(this.selectedOrganization, email);
        const { sns, ...rest } = item;

        if (!isEmpty(sns)) {
          sortedSNS = sortBy(sns, ['name', 'type']);
        }

        this.poolPlan = { item: rest, isLoading: false };
        this.sns = sortedSNS;

        this.setNotification();

        return this.poolPlan.item;
      } catch (error) {
        // throw new Error(error);
        if (error.status === ERROR_CODES.NOT_FOUND) {
          this.poolPlan = { item: {}, isLoading: false };
        }
      }
    }
  };

  getHistory = async (invalidate = false) => {
    this.history.isLoading = true;

    if ((!isEmpty(this.history.data) && !invalidate) || isEmpty(this.poolPlan.item)) {
      this.history.isLoading = false;
      return this.history.data;
    } else {
      try {
        const res = await this.api.getHistory(this.selectedOrganization, false);

        this.history = { data: res.history, isLoading: false };

        return res.history;
      } catch (error) {
        if (error.status === ERROR_CODES.NOT_FOUND) {
          this.history = { data: [], isLoading: false };
        }
      }
    }
  };

  getBalance = async (invalidate = false) => {
    this.balance.isLoading = true;

    if ((!isEmpty(this.balance.data) && !invalidate) || isEmpty(this.poolPlan.item)) {
      this.balance.isLoading = false;
      return this.balance.data;
    } else {
      try {
        const res = await this.api.getBalance(this.selectedOrganization);

        this.balance = { data: res.balance, isLoading: false };

        return res.balance;
      } catch (error) {
        if (error.status === ERROR_CODES.NOT_FOUND) {
          this.balance = { data: [], isLoading: false };
        }
      }
    }
  };

  getOrder = async (invalidate = false) => {
    this.order.isLoading = true;

    if ((!isEmpty(this.order.data) && !invalidate) || isEmpty(this.poolPlan.item)) {
      this.order.isLoading = false;
      return this.order.data;
    } else {
      try {
        const res = await this.api.getHistory(this.selectedOrganization, true);

        this.order = { data: res.history, isLoading: false };

        return res.history;
      } catch (error) {
        if (error.status === ERROR_CODES.NOT_FOUND) {
          this.order = { data: [], isLoading: false };
        }
      }
    }
  };

  topup = async (payload) => {
    await this.api.topup(this.selectedOrganization, payload);
  };

  addDevices = async (payload) => await this.api.addDevices(this.selectedOrganization, payload);

  removeDevices = async () => {
    const sn = this.devicesToRemove.reduce((prev, cur) => {
      return prev.concat([{ sn: cur.sn }]);
    }, []);
    const response = await this.api.removeDevices(this.selectedOrganization, { devices: sn });

    return response;
  };

  setDevicesToRemove = (data) => {
    this.devicesToRemove = data;
  };

  setDevicesToAdd = (data) => {
    this.devicesToRemove = data;
  };

  setNotification = async () => {
    if (isPast(new Date(this.poolPlan.item.expiresAt))) {
      this.notification = { orgCode: this.selectedOrganization, info: NOTIFICATION_MSG.expiry, showing: true };
    } else if (
      isWithinInterval(new Date(this.poolPlan.item.expiresAt), {
        start: new Date(),
        end: add(new Date(), { days: 30 }),
      })
    ) {
      this.notification = {
        orgCode: this.selectedOrganization,
        info: NOTIFICATION_MSG.expirySoon,
        showing: true,
        diff: formatDistanceStrict(new Date(), new Date(this.poolPlan.item.expiresAt)),
      };
    } else if (this.poolPlan.item.quotaLeftKb === 0) {
      this.notification = { orgCode: this.selectedOrganization, info: NOTIFICATION_MSG.noData, showing: true };
    } else if (this.poolPlan.item.quotaLeftKb < POOL_LOW_DATA_THRESHOLD) {
      this.notification = { orgCode: this.selectedOrganization, info: NOTIFICATION_MSG.lowData, showing: true };
    } else {
      this.notification = { orgCode: undefined, info: {}, showing: false };
    }
  };

  updateNotification = async (orgCode) => {
    if (!orgCode || this.notification.orgCode === orgCode) {
      return;
    }
    this.getPool(true);
  };

  refresh = (orgCode) => this.api.refresh(orgCode);

  setTopUpModal = (bool) => {
    this.notification = { ...this.notification, showing: false, topUpOpen: bool };
  };

  onSubscribe = async () => {
    this.poolPlan.isLoading = true;

    await this.api.onSubscribe({
      organizationID: this.selectedOrganization,
      thresholdTopupKb: 102400,
      autoTopupAmountKb: 512000,
      thresholdTopupBeforeDay: 7,
    });
  };

  updatePooledSetting = async (payload) => {
    this.poolPlan.isLoading = true;

    const response = await this.api.updatePooledSetting(this.selectedOrganization, payload);
    await this.getPool(true);

    return response;
  };

  setAutoTopUp = async (payload) => {
    this.poolPlan.isLoading = true;

    const response = await this.api.setAutoTopUp(this.selectedOrganization, payload);
    await this.getPool(true);

    return response;
  };

  setSelectedOrganization = (org) => {
    if (this.organizations.data.find((i) => i.code === org)) {
      this.selectedOrganization = org;
    } else {
      this.selectedOrganization = first(this.organizations.data)?.code || '';
    }
  };

  invalidateItems = () => {
    this.poolPlan = STORE_DEFAULT_ITEM;
    this.history = STORE_DEFAULT_ITEMS;
    this.balance = STORE_DEFAULT_ITEMS;
    this.order = STORE_DEFAULT_ITEMS;
    this.devices = STORE_DEFAULT_ITEMS;
  };

  get SN_LIST() {
    return this.sns.map((i) => i.sn);
  }
}
