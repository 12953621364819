export const PLAN_TYPE = {
  ACTIVATION: 'activation_plan',
  TRIAL: 'trial_plan',
};

export const PLAN = {
  INACTIVATED: 'INACTIVE',
  ACTIVATED: 'ACTIVATED',
};

export const POOL_PLAN = {
  SUBSCRIBED: 'SUBSCRIBE',
  NONSUBSCRIBED: 'NONSUBSCRIBE',
};

export const SIM_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  UNKNOWN: 'UNKNOWN',
};

export const FILTER_STATUS = {
  ALL: 'ALL',
  IN_USE: 'IN_USE',
  NOT_IN_USE: 'NOT_IN_USE',
  UNKNOWN: 'UNKNOWN',
};

export const SIM_STATUS_OPTIONS = [
  { value: 'ALL', label: 'All' },
  { value: 'IN_USE', label: 'In Use' },
  { value: 'NOT_IN_USE', label: 'Not In Use' },
  { value: 'UNKNOWN', label: 'No Device Data' },
];

export const statusOptionPrepaid = [
  { value: 'SUBSCRIBE', label: 'SFC Enabled' },
  { value: 'NONSUBSCRIBE', label: 'SFC Disabled' },
  { value: 'LEGACY', label: 'Legacy Firmware' },
];

export const ANOTHER_CARRIER = { value: 'another', label: 'Using in 3rd Party Device' };

export const ROUTES = {
  CALLBACK: '/callback',
  TOPUP: '/top-up',
  TOPUP_BULK: '/form',
  SIM: '/connectivity',
  SIM_PLANS: '/plans',
  SETTINGS: '/settings',
  PARTNER_PAVILION: '//access.peplink.com/',
  SUPPORT_TICKET: '//ticket.peplink.com',
  FIRMWARE_UPDATES: '//peplink.com/support/downloads/',
  IC2DD: '//incontrol2.peplink.com/sn',
  POOL_MANAGEMENT: '/5glte-pool',
};

export const PREPAID_COLUMNS = [
  { key: 'sn', label: 'Serial No.', show: true },
  { key: 'status', label: 'Status', show: true },
  { key: 'product.name', label: 'Model', show: true },
  { key: 'name', label: 'Device Name', show: true },
  { key: 'fw_ver', label: 'Firmware', show: true },
  { key: 'quota_mb', label: 'Remaining SFC Usage Quota', show: true },
  { key: 'lifetime', label: 'SFC Usage Expiry Date', show: true },
  { key: 'usages', label: 'Remaining eSIM Quota', show: true },
  { key: 'expiresAt', label: 'eSIM Expiry Date', show: true },
];

export const POSTPAID_COLUMNS = [
  { key: 'iccid', label: 'ICCID', show: true },
  { key: 'carrier', label: 'Carrier', show: true },
  { key: 'usageMb', label: 'eSIM Usage', show: true },
  { key: 'name', label: 'Device Name', show: true },
  { key: 'product.name', label: 'Model', show: true },
  { key: 'sn', label: 'Serial No.', show: true },
  { key: 'fw', label: 'Firmware', show: true },
  { key: 'last_seen', label: 'Last Online', show: true },
];

export const QUOTA_RANGE_ERROR_MESSAGE = 'From value must be smaller than To value';

export const SF_CLOUD_LABELS = {
  TOOLTIP:
    'With SpeedFusion Cloud, this device will be able to form SpeedFusion connections with our global network of endpoints.',
};

export const VISIBILITY = {
  ALL: 'All devices',
  VISIBLE_ONLY: 'Visible devices',
  HIDDEN_ONLY: 'Hidden devices',
  POOL: 'Devices in eSIM Data Pooled Plan',
};

export const DEFAULT_VISIBILITY = 'VISIBLE_ONLY';

export const DEFAULT_SN = '****-****-****';

export const FEATURE = {
  POSTPAID: 'postpaid',
  PREPAID: 'prepaid',
  POOL: 'pool',
};

export const DEFAULT_PAGESIZE = 24;

export const STORE_DEFAULT_ITEMS = {
  isLoading: true,
  data: [],
};

export const STORE_DEFAULT_ITEM = {
  isLoading: true,
  item: {},
};

export const NOTIFICATION_MSG = {
  expirySoon: { msg: 'Your eSIM Data Pooled Plan is', highline: 'expiring in' },
  expiry: { msg: 'Your eSIM Data Pooled Plan is', highline: 'expired' },
  lowData: { msg: 'The quota in your eSIM Data Pooled Plan is running low:', highline: '10 GB' },
  noData: { msg: 'The quota in your eSIM Data Pooled Plan is', highline: 'used up' },
};

export const MINIMUM_FIRMWARE_VERSION = '8.1.1';

export const ERROR_CODES = {
  NOT_FOUND: 404,
};

export const POOL_LOW_DATA_THRESHOLD = 10_240_000;

export const ESIM_DATA_COL_NAME = {
  us: 'NA & EU eSIM Data',
  global: 'Global eSIM Data',
};

export const PRODUCT_INPUT_NAME = {
  eSimPlanUs: 'eSimPlanUs',
  eSimPlanGlobal: 'eSimPlanGlobal',
};

export const ESIM_DATA_PLAN = {
  US: 'US',
  GLOBAL: 'GLOBAL',
};
