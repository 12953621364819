import { Link } from 'react-router-dom';
import cx from 'classnames';
import React from 'react';

export default ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    const res = localStorage.getItem(process.env.REACT_APP_APP_NAME);
    if (res) {
      const data = JSON.parse(res);
      setUser(data.name);
    }
  }, []);

  return (
    <>
      <div className="z-50 bg-primary">
        <div className={cx('container flex p-2 mx-auto', { 'referral-link-container-sidebar': isSidebarOpen })}>
          <Link to="/" id="return-home" className="ml-auto font-semibold text-black no-underline">
            Return to Homepage
          </Link>
        </div>
      </div>
      <header className="sticky top-0 z-50">
        <div className="header-bar">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="ml-2 text-white no-underline material-icons md-28"
          >
            menu
          </button>
          <div
            className={cx(
              'flex items-center py-3',
              { 'header-logo-sidebar': isSidebarOpen },
              { 'header-logo-close-sidebar': !isSidebarOpen }
            )}
          >
            <Link className="logo" to="/" />
          </div>
          <div className="container flex px-3 mx-auto">
            <div className="flex items-center ml-auto text-white">
              <div className="flex items-center">
                Hi, {user}
                <img src={`https://ui-avatars.com/api/?name=${user}`} alt="" className="w-12 ml-2 rounded-full" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
