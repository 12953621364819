import { Modal, ModalFooter } from 'components/common/Modal';
import React from 'react';

export default ({ onToggle, onSave, children }) => {
  return (
    <Modal size="md" containerClass="bg-white" onToggle={onToggle}>
      <div className="p-2">
        {children}
        <ModalFooter>
          <button onClick={() => onToggle()} className="btn btn-outline" data-cy="cancel_btn">
            Cancel
          </button>
          <button onClick={() => onSave()} className="ml-3 btn btn-outline" data-cy="confirm_btn">
            Confirm
          </button>
        </ModalFooter>
      </div>
    </Modal>
  );
};
