import { DEFAULT_VISIBILITY } from 'definitions';
import { inject, observer } from 'mobx-react';
import { toMb } from 'utils';
import { useForm } from 'react-hook-form';
import DeviceName from 'components/TopUp/TopUp/components/Filter/DeviceName';
import Expiration from 'components/TopUp/TopUp/components/Filter/Expiration';
import Groups from 'components/TopUp/TopUp/components/Filter/Groups';
import ICCID from 'components/TopUp/TopUp/components/Filter/ICCID';
import Model from 'components/TopUp/TopUp/components/Filter/Model';
import Organizations from 'components/TopUp/TopUp/components/Filter/Organizations';
import React from 'react';
import Reset from './Reset';
import SerialNumber from 'components/TopUp/TopUp/components/Filter/SerialNumber';
import Status from 'components/TopUp/TopUp/components/Filter/Status';
import UnusedConnectivityUsage from 'components/TopUp/TopUp/components/Filter/UnusedConnectivityUsage';
import UnusedSFCQuota from 'components/TopUp/TopUp/components/Filter/UnusedSFCQuota';

const Filter = ({ data, items, handleFilters, setFilters, filterDefaults, isLoading, prepaidStore }) => {
  const [maxQuota, setMaxQuota] = React.useState({ type: 'TB' });
  const [maxQuotaSim, setMaxQuotaSim] = React.useState({ type: 'TB' });
  const [visibility, setVisibility] = React.useState(DEFAULT_VISIBILITY);

  const methods = useForm();
  const { register, reset } = methods;

  const defaultValues = () => ({
    activation_plan: { exp: null },
    sn: null,
    'device-name': null,
    'subscribed-status': 'all',
    fw_ver: null,
    lifetime: '',
    grp_name: '',
    model: '',
    iccid: '',
    isDeviceHidden: DEFAULT_VISIBILITY,
  });

  const onSliderChange = (value, type) => {
    type === 'lifetime'
      ? handleFilters('sfwan_license.exp', value)
      : handleFilters('sfwan_license.quota_mb', value ? value : '0');
  };

  const handleUsage = (quota) => {
    handleFilters('sfwan_license.quota_mbTo', quota ? toMb(quota) : '0');
    setMaxQuota(quota);
  };

  const handleSimUsage = (quota) => {
    handleFilters('quotaSim', quota ? toMb(quota) : '0');
    setMaxQuotaSim(quota);
  };

  const handleResetFilters = React.useCallback(() => {
    reset({ 'subscribed-status': 'all' });
    reset(defaultValues());
    setFilters(filterDefaults());
    setMaxQuota({ type: 'TB', amount: '' });
    setMaxQuotaSim({ type: 'TB', amount: '' });
    setVisibility(DEFAULT_VISIBILITY);
  }, [filterDefaults, reset, setFilters]);

  const handleHiddenDevices = (value) => {
    setVisibility(value);
    handleFilters('isDeviceHidden', value);
  };

  React.useEffect(() => {
    if (prepaidStore.selectedOrganization) {
      handleResetFilters();
    }
  }, [handleResetFilters, prepaidStore.selectedOrganization]);

  return (
    <div className="p-6 bg-white">
      <Reset isLoading={isLoading} handleResetFilters={handleResetFilters} />
      <Organizations isLoading={isLoading} data={data} />
      <Groups isLoading={isLoading} handleFilters={handleFilters} items={items} register={register} />
      <Model isLoading={isLoading} register={register} handleFilters={handleFilters} items={items} />
      <DeviceName isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <SerialNumber isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <ICCID isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <Status isLoading={isLoading} handleHiddenDevices={handleHiddenDevices} visibility={visibility} items={items} />
      <Expiration isLoading={isLoading} register={register} onSliderChange={onSliderChange} items={items} />
      <UnusedSFCQuota isLoading={isLoading} maxQuota={maxQuota} handleUsage={handleUsage} />
      <UnusedConnectivityUsage isLoading={isLoading} maxQuotaSim={maxQuotaSim} handleSimUsage={handleSimUsage} />
    </div>
  );
};

export default inject(({ prepaidStore }) => ({ prepaidStore }))(observer(Filter));
