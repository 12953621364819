import { inject, observer } from 'mobx-react';
import { Modal, ModalFooter, ModalHeader } from 'components/common/Modal';
import React from 'react';

const AccountIssue = ({ onToggle, userStore }) => {
  const goBackAction = async () => {
    await userStore.setViewAs('');
    await userStore.me(true);
    onToggle();
  };

  return (
    <>
      <Modal size="sm" containerClass="bg-black" onToggle={goBackAction}>
        <ModalHeader onToggle={goBackAction}>
          <div className=" text-white">
            <h3 className="mb-2 modal-title">Error</h3>
            <p className="mb-0">There are no associated organizations with the provided email address.</p>
          </div>
        </ModalHeader>
        <ModalFooter>
          <button onClick={goBackAction} className="ml-auto btn btn-modal">
            Go Back
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(AccountIssue));
