import {
  filterActivated,
  filterBySelectTopUp,
  filterFirmwarePrepaid,
  filterIccidPrepaid,
  filterLessThan,
  filterLessThanQuota,
  filterQuotaSimPrepaid,
  filterSubscribed,
  maybePluralize,
} from 'utils';
import { includes, isArray, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { PREPAID_COLUMNS } from 'definitions';
import ConnectExpiryDate from './ConnectExpiryDate';
import Firmware from './Firmware';
import QuotaLifetime from './QuotaLifetime';
import React from 'react';
import ReactTable from 'components/common/ReactTable';
import SFCExpiryDate from './SFCExpiryDate';
import SIMQuotaLifetime from './SIMQuotaLifetime';
import Sn from './Sn';
import Visibility from './Visibility';

const View = ({
  isLoading,
  tableRef,
  defaultSelected,
  items,
  onSelect,
  defaultPageSize,
  columnsShow,
  children,
  userStore,
  poolPlanStore,
  prepaidStore,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Visibility',
        id: 'visibility',
        className: 'col-status text-center',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Visibility data={original} />,
      },
      {
        Header: 'Serial No.',
        accessor: 'sn',
        className: 'col-sn text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <Sn data={original} />;
        },
      },
      {
        accessor: 'grp_name',
        className: 'hidden',
      },
      {
        Header: 'Model',
        accessor: 'product.name',
        className: 'col-model text-left',
      },
      {
        Header: 'Device Name',
        accessor: 'name',
        className: 'col-device text-left',
      },
      {
        Header: 'Firmware',
        accessor: 'fw_ver',
        className: 'col-fw text-left',
        filter: filterFirmwarePrepaid,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Firmware data={original} />,
      },
      {
        Header: 'SFC Usage Quota',
        id: 'quota_mb',
        className: 'col-sfc-usage text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (original.top_up_plan_eligible ? <QuotaLifetime data={original} /> : null),
      },
      {
        Header: 'SFC Usage Expiry Date',
        id: 'lifetime',
        className: 'col-expiry text-left',
        filter: filterLessThan,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <SFCExpiryDate data={original} />,
      },
      {
        Header: 'eSIM Quota',
        id: 'usages',
        className: 'col-connect-prepaid-usage text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return <SIMQuotaLifetime data={original} />;
        },
      },
      {
        Header: 'eSIM Expiry Date',
        accessor: 'expiresAt',
        className: 'col-expiry text-left',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <ConnectExpiryDate data={original} />,
      },
      {
        Header: 'eSIM Expiry Date',
        id: 'iccid',
        className: 'hidden',
        filter: filterIccidPrepaid,
      },
      {
        Header: 'Status',
        accessor: 'trial_plan.activated',
        filter: filterActivated,
        className: 'hidden',
      },
      {
        Header: 'Status',
        accessor: 'activation_plan.activated',
        filter: filterActivated,
        className: 'hidden',
      },
      {
        accessor: 'sfwan_license',
        className: 'hidden',
        filter: filterSubscribed,
      },
      {
        accessor: 'sfwan_license.quota_mbFrom',
        className: 'hidden',
        filter: filterLessThanQuota,
      },
      {
        accessor: 'sfwan_license.quota_mbTo',
        className: 'hidden',
        filter: filterLessThanQuota,
      },

      {
        Header: 'Remaining Lifetime',
        accessor: 'trial_plan.exp',
        filter: filterLessThan,
        className: 'hidden',
      },
      {
        Header: 'Remaining Lifetime',
        accessor: 'activation_plan.exp',
        className: 'hidden',
        filter: filterLessThan,
      },

      {
        accessor: 'service_exp',
        className: 'hidden',
        filter: filterLessThan,
      },
      {
        accessor: 'sfwan_license.exp',
        className: 'hidden',
        filter: filterBySelectTopUp,
      },
      {
        accessor: 'quotaSim',
        className: 'hidden',
        filter: filterQuotaSimPrepaid,
      },
      {
        accessor: 'isDeviceHidden',
        filter: (rows, _, filterValue) => {
          return rows.filter((row) => {
            return filterValue === 'VISIBLE_ONLY'
              ? !includes(userStore.hiddenDevices.prepaid, row.original.sn)
              : filterValue === 'HIDDEN_ONLY'
              ? includes(userStore.hiddenDevices.prepaid, row.original.sn)
              : filterValue === 'POOL'
              ? poolPlanStore.SN_LIST.includes(row.original.sn)
              : true;
          });
        },
      },
    ],
    [poolPlanStore.SN_LIST, userStore.hiddenDevices.prepaid]
  );

  return (
    <>
      {isArray(columnsShow) && (
        <ReactTable
          tableRef={tableRef}
          columns={columns}
          data={items}
          className="mb-5"
          header={children}
          options={{
            defaultPageSize,
            isLoading,
            defaultSelected,
            onSelectionChanged: onSelect,
            tableClassName: 'table-view-postpaid',
            disabledProp: 'disabled',
            totalColumns: PREPAID_COLUMNS.length + 1,
            hiddenColumns: [
              ...columnsShow.filter((i) => !i.show).map((i) => i.key),
              !isEmpty(userStore.viewAs) && 'visibility',
            ],
            isList: true,
            totalText: `eligible ${maybePluralize(items?.length, 'device')}`,
            serverErrors: prepaidStore.serverErrors,
          }}
        />
      )}
    </>
  );
};

export default inject(({ userStore, poolPlanStore, prepaidStore }) => ({ userStore, poolPlanStore, prepaidStore }))(
  observer(View)
);
