import { BtnPoolPlanLogo } from '../Components/Icons';
import { calculatePagesCount, quotaGBSizeFromKB } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import React from 'react';
import Switch from 'react-switch';
import Table from '../Components/Table';

const DEFAULT_PAGESIZE = 20;

const Management = ({ tableRef, poolPlanStore, userStore }) => {
  const [sns, setSns] = React.useState([]);
  const [search, setSearch] = React.useState('');

  const data = React.useMemo(() => {
    return !isEmpty(search)
      ? poolPlanStore.sns.filter((item) => {
          const snMatch = item.sn.toLowerCase().includes(search.toLowerCase());
          const iccidMatch = item.iccids.some((iccid) => iccid.iccid.toLowerCase().includes(search.toLowerCase()));
          const modelMatch = item.model.toLowerCase().includes(search.toLowerCase());
          const nameMatch = item.name.toLowerCase().includes(search.toLowerCase());
          return snMatch || iccidMatch || modelMatch || nameMatch;
        })
      : poolPlanStore.sns;
  }, [poolPlanStore.sns, search]);

  const totalRow = React.useMemo(() => data.length, [data.length]);
  const totalPage = React.useMemo(() => calculatePagesCount(DEFAULT_PAGESIZE, data.length), [data.length]);

  // toggle auto topup btn
  const handleToggle = React.useCallback(
    async (checked, sn) => {
      try {
        const response = await poolPlanStore.setAutoTopUp({ sn, autoTopupEnabled: checked });
        !response.error
          ? toast.success(`Your auto top up settings are updated.`)
          : toast.error(`We failed to update your top up settings. Please try again.`);
      } catch (error) {
        toast.error(`We failed to update your top up settings. Please try again.`);
      }
    },
    [poolPlanStore]
  );

  // react table columns
  const columns = React.useMemo(
    () => [
      {
        Header: 'Serial Number',
        className: 'col-pool-sn',
        accessor: 'sn',
        Cell: (props) =>
          props?.row?.original?.type === 'master' ? (
            props?.row?.original?.sn
          ) : (
            <div>
              <div>{props?.row?.original?.sn}</div>
              <div className="text-sm text-gray-500">Expansion module</div>
            </div>
          ),
      },
      {
        Header: 'Model',
        id: 'model',
        className: 'col-model',
        accessor: (props) => (props?.model ? props?.model : '-'),
      },
      {
        Header: 'Device Name',
        className: 'col-device',
        accessor: (props) => (props?.name ? props?.name : '-'),
      },
      {
        Header: 'ICCID',
        accessor: 'iccids',
        className: 'col-iccid',
        id: 'iccids',
        Cell: (props) => (
          <div>
            {props?.cell?.value?.map((item, index) => (
              <div key={index} className="iccid-cell">
                {item?.iccid}
              </div>
            ))}
          </div>
        ),
      },
      {
        Header: 'Remaining Quota',
        accessor: 'iccids',
        className: 'col-quota',
        id: 'remain',
        Cell: (props) => (
          <div>
            {props?.cell?.value?.map((item, index) => (
              <div key={index}>
                {quotaGBSizeFromKB(item.freeKb).resultNum + ' ' + quotaGBSizeFromKB(item.freeKb).resultDim}
              </div>
            ))}
          </div>
        ),
      },
      {
        Header: 'Auto Top Up',
        accessor: 'autoTopupEnabled',
        className: 'col-auto-topup-btn',
        id: 'btn',
        Cell: (props) => (
          <div>
            <Switch
              id="autoTopupToggle"
              onChange={(checked) => handleToggle(checked, props?.cell?.row?.values?.sn)}
              checked={props.cell.value}
              onColor="#ffb81c"
              offColor="#ccc"
              uncheckedIcon={false}
              checkedIcon={false}
              height={18}
              width={40}
              disabled={!isEmpty(userStore.viewAs)}
            />
          </div>
        ),
      },
    ],
    [handleToggle, userStore.viewAs]
  );

  const setData = React.useCallback(
    ({ page, pageSize }) => {
      setSns(() => data.slice((page - 1) * pageSize, page * pageSize));
    },
    [data]
  );

  const onSubscribe = async () => {
    await poolPlanStore.onSubscribe();
    await poolPlanStore.getPool();
  };

  const onSearch = (e) => {
    e.persist();
    setSearch(e.target.value);
  };

  const selectDevicesToRemove = React.useCallback(
    (devices) => {
      poolPlanStore.setDevicesToRemove(devices.map((i) => i.original));
    },
    [poolPlanStore]
  );

  return (
    <div>
      {poolPlanStore.poolPlan.isLoading ? (
        <div className="relative">
          <div className="loading" style={{ position: 'relative', background: 'none' }} />
        </div>
      ) : (
        <>
          <div className="pool-plan-history-bar">
            <div className="search-bar focus-within:text-gray-400">
              <span className="icon">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </span>
              <input
                className="focus:outline-none focus:bg-white focus:text-gray-900 pool-placeholder"
                placeholder="Serial Number/ ICCID"
                onChange={onSearch}
                value={search}
              />
              {search && search.length > 0 && (
                <span className="del" onClick={() => setSearch('')}>
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.5 0C4.73962 0 0.083374 4.47 0.083374 10C0.083374 15.53 4.73962 20 10.5 20C16.2605 20 20.9167 15.53 20.9167 10C20.9167 4.47 16.2605 0 10.5 0ZM15.7084 13.59L14.2396 15L10.5 11.41L6.76046 15L5.29171 13.59L9.03129 10L5.29171 6.41L6.76046 5L10.5 8.59L14.2396 5L15.7084 6.41L11.9688 10L15.7084 13.59Z"
                      fill="black"
                      fill-opacity="0.26"
                    />
                  </svg>
                </span>
              )}
            </div>
          </div>
          {!isEmpty(poolPlanStore.poolPlan.item) ? (
            <Table
              manualPagination
              ref={tableRef}
              data={sns}
              columns={columns}
              fetchData={setData}
              pageCount={totalPage}
              totalRow={totalRow}
              withSelectionColumn
              onSelect={selectDevicesToRemove}
            />
          ) : (
            <div className="pool-plan-register-table">
              <div>Please subscribe to eSIM Data Pooled Plan to add device</div>
              {isEmpty(userStore.viewAs) && (
                <div className="pl-2 pr-4 mt-3 btn-black" onClick={onSubscribe}>
                  <BtnPoolPlanLogo />
                  Subscribe eSIM Data Pooled Plan
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default inject(({ prepaidStore, poolPlanStore, userStore }) => ({ prepaidStore, poolPlanStore, userStore }))(
  observer(Management)
);
