import { SIM_STATUS_OPTIONS } from 'definitions';
import Checkbox from 'components/common/form/Checkbox';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, onChange, value }) => (
  <FilterGroup label="Usage Status" isLoading={isLoading}>
    {SIM_STATUS_OPTIONS.map((i) => (
      <Checkbox value={i.value} key={i.value} onChange={(e) => onChange(e.target.value)} checked={value === i.value}>
        {i.label}
      </Checkbox>
    ))}
  </FilterGroup>
);
