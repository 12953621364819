import { Route, Switch } from 'react-router-dom';
import Main from './Main';
import React from 'react';

export default () => (
  <Switch>
    <Route path="/:root/:id" component={Main} />
    <Route path="/:root" component={Main} />
  </Switch>
);
