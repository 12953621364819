import { Helmet } from 'react-helmet';
import Plans from './Plans';
import React from 'react';

export default () => {
  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <h1 className="flex items-center py-4 font-medium">
        <i className="mr-2 icons icon-sim-iccid" />
        Connectivity Service Plans
      </h1>
      <p className="mb-12">The current postpaid SIM service plans are available for your account.</p>
      <Plans />
    </>
  );
};
