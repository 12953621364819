import cx from 'classnames';
import React from 'react';
import shortid from 'shortid';

export default ({
  methods = {},
  id = shortid.generate(),
  rules = {},
  type = 'checkbox',
  name,
  className,
  children,
  ...rest
}) => {
  const { register } = methods;

  return (
    <div className={cx('check-box', className)}>
      <input type={type} id={id} name={name} {...(register && { ref: register(rules) })} {...rest}></input>
      <label htmlFor={id}>{children}</label>
    </div>
  );
};
