export default class AuthServices {
  constructor(props) {
    this.url = '/auth';
    this.http = props.http;
  }

  login = (id) => {
    return this.http.post(`${this.url}/test`);
  };

  refreshToken = (payload) => {
    return this.http.post(`${this.url}/refresh`, payload);
  };

  setToken = (token) => this.http.setToken(token);
  getToken = () => this.http.getToken();
  removeToken = () => this.http.removeToken();
}
