import {
  filterActivated,
  filterBySelect,
  filterBySelectTopUp,
  filterFirmware,
  filterIccidPrepaid,
  filterLessThan,
  filterLessThanQuota,
  filterQuotaSimPrepaid,
  filterSubscribed,
} from 'utils';
import { includes } from 'lodash';
import { inject, observer } from 'mobx-react';
import { maybePluralize } from 'utils';
import Component from './Component';
import React from 'react';
import ReactTable from 'components/common/ReactTable';

const View = ({
  isLoading,
  tableRef,
  defaultSelected,
  items,
  onSelect,
  setTotalNumber,
  defaultPageSize,
  children,
  userStore,
  poolPlanStore,
  prepaidStore,
}) => {
  const columns = React.useMemo(
    () => [
      {
        accessor: 'grp_name',
        className: 'hidden',
      },
      {
        className: 'hidden',
        accessor: 'product.name',
      },
      {
        accessor: 'name',
        className: 'hidden',
      },
      {
        accessor: 'sfwan_license',
        className: 'hidden',
        filter: filterSubscribed,
      },
      {
        accessor: 'sfwan_license.quota_mbFrom',
        className: 'hidden',
        filter: filterLessThanQuota,
      },
      {
        accessor: 'sfwan_license.quota_mbTo',
        className: 'hidden',
        filter: filterLessThanQuota,
      },
      {
        accessor: 'sn',
        className: 'hidden',
      },
      {
        Header: 'Status',
        accessor: 'trial_plan.activated',
        className: 'hidden',
        filter: filterActivated,
      },
      {
        Header: 'Status',
        accessor: 'activation_plan.activated',
        className: 'hidden',
        filter: filterActivated,
      },
      {
        accessor: 'trial_plan.exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterLessThan,
      },
      {
        accessor: 'activation_plan.exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterLessThan,
      },
      {
        accessor: 'service_exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterBySelect,
      },
      {
        accessor: 'sfwan_license.exp',
        className: 'hidden',
        style: { width: '10%' },
        filter: filterBySelectTopUp,
      },
      {
        accessor: 'item.fw_ver',
        className: 'hidden',
        filter: filterFirmware,
      },
      {
        accessor: 'iccid',
        className: 'hidden',
        filter: filterIccidPrepaid,
      },
      {
        accessor: 'quotaSim',
        className: 'hidden',
        filter: filterQuotaSimPrepaid,
      },
      {
        accessor: 'isDeviceHidden',
        filter: (rows, _, filterValue) => {
          return rows.filter((row) => {
            return filterValue === 'VISIBLE_ONLY'
              ? !includes(userStore.hiddenDevices.prepaid, row.original.sn)
              : filterValue === 'HIDDEN_ONLY'
              ? includes(userStore.hiddenDevices.prepaid, row.original.sn)
              : filterValue === 'POOL'
              ? poolPlanStore.SN_LIST.includes(row.original.sn)
              : true;
          });
        },
      },
    ],
    [poolPlanStore.SN_LIST, userStore.hiddenDevices.prepaid]
  );

  return (
    <>
      <ReactTable
        tableRef={tableRef}
        setTotalNumber={setTotalNumber}
        columns={columns}
        data={items}
        className="mb-5 topup-table"
        tableClassName="border-primary border-top"
        template={Component}
        header={children}
        options={{
          defaultPageSize,
          isLoading,
          defaultSelected,
          isMulti: true,
          onSelectionChanged: onSelect,
          disabledProp: 'disabled',
          isGridView: true,
          totalText: `eligible ${maybePluralize(items?.length, 'device')}`,
          serverErrors: prepaidStore.serverErrors,
        }}
      />
    </>
  );
};

export default inject(({ userStore, poolPlanStore, prepaidStore }) => ({ userStore, poolPlanStore, prepaidStore }))(
  observer(View)
);
