import { quotaDimension } from 'utils';
import { SIM_STATUS } from 'definitions';
import cx from 'classnames';
import React from 'react';
import Title from './Title';

export default ({ item, status }) => (
  <div className="py-3 mt-2 sn-row">
    <Title iccid={item.iccid} className={cx({ 'sn-expire-text-gray': quotaDimension(item.usageMb).quota === 0 })} />
    <div className="flex items-center">
      <i
        className={cx('ml-1 mr-2 icons ', {
          'icon-upload-download': quotaDimension(item.usageMb).quota > 0,
          'icon-upload-download-dimmed': quotaDimension(item.usageMb).quota === 0 || item.status === SIM_STATUS.UNKNOWN,
        })}
      ></i>
      <div className="items-end">
        <span
          className={cx('remaining-sfc-usage-number', {
            'text-primary': quotaDimension(item.usageMb).quota > 0,
            'sn-expire-text-gray': quotaDimension(item.usageMb).quota === 0 || item.status === SIM_STATUS.UNKNOWN,
          })}
        >
          {status !== SIM_STATUS.UNKNOWN ? quotaDimension(item.usageMb).quota : '-'}
        </span>
        <span
          className={cx('remaining-sfc-usage-type', {
            'text-primary': quotaDimension(item.usageMb).quota > 0,
            'sn-expire-text-gray': quotaDimension(item.usageMb).quota === 0 || item.status === SIM_STATUS.UNKNOWN,
          })}
        >
          {quotaDimension(item.usageMb).type}
        </span>
      </div>
      {(status === SIM_STATUS.ONLINE || status === SIM_STATUS.OFFLINE) && quotaDimension(item.usageMb).quota !== 0 && (
        <div className="flex items-center ml-auto">
          <div className="mr-2 icons icon_carrier" />
          <div className="text-status text-primary">{item.carrier || 'In Use'}</div>
        </div>
      )}
      {status === SIM_STATUS.UNKNOWN && quotaDimension(item.usageMb).quota === 0 && (
        <div className="ml-auto no-device-data">
          <span className="mr-2 material-icons-outlined no-device-data-icon">cloud_upload</span>
          No Device Data
        </div>
      )}
    </div>
  </div>
);
