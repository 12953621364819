import * as Icons from '../Components/Icons';
import { checkExpired, DQPoolPlan, formatDate, quotaGBSizeFromKB } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { ROUTES } from 'definitions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AccountIssue from '../Components/AccountIssue';
import AddDevice from '../Components/AddDevice';
import cx from 'classnames';
import Device from '../Device';
import History from '../History';
import Management from '../Management';
import Order from '../Order';
import PoolPlanTopup from 'components/TopUp/TopUp/components/PoolPlanTopup';
import React from 'react';
import Refresh from '../Components/Refresh';
import RemoveDevices from '../Components/RemoveDevice';
import Select from 'components/common/Select';
import Skeleton from 'react-loading-skeleton';
import TopupSettings from './TopupSettings';
import useToggle from 'hooks/useToggle';
import ViewAs from 'components/common/ViewAs';

// tab array
const TABS = [
  {
    name: 'Subscribed Devices',
    icon: function (active) {
      return <Icons.Devices active={active} />;
    },
    tab: 'management',
  },
  {
    name: 'Device History',
    icon: function (active) {
      return <Icons.Balance active={active} />;
    },
    tab: 'device',
  },
  {
    name: 'Data History',
    icon: function (active) {
      return <Icons.DataHistory active={active} />;
    },
    tab: 'history',
  },
  {
    name: 'Order History',
    icon: function (active) {
      return <Icons.History active={active} />;
    },
    tab: 'order',
  },
];

const Main = ({ poolPlanStore, userStore }) => {
  const params = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const paramTab = searchParams.get('tab');

  const removeDeviceModal = React.useRef(null);
  const tableRef = React.useRef(null);
  const [tab, setTab] = React.useState(paramTab || TABS[0].tab);
  const { toggle, handleToggle } = useToggle({
    poolPlanTopUp: false,
    addDevice: false,
    updateSetting: false,
    errorModal: false,
  });
  const detailData = React.useMemo(() => poolPlanStore.poolPlan.item, [poolPlanStore.poolPlan.item]);

  const onTogglePoolPlanTopUp = () => handleToggle({ poolPlanTopUp: !toggle.poolPlanTopUp });
  const onToggleAddDevice = () => handleToggle({ addDevice: !toggle.addDevice });

  const isActivePlan = React.useMemo(
    () => !!(poolPlanStore.poolPlan?.item?.quotaLeftKb || poolPlanStore.poolPlan?.item?.expiresAt),
    [poolPlanStore.poolPlan.item.expiresAt, poolPlanStore.poolPlan.item.quotaLeftKb]
  );

  const poolPlanIsLoading = React.useMemo(() => poolPlanStore.poolPlan.isLoading, [poolPlanStore.poolPlan.isLoading]);
  const devicesIsLoading = React.useMemo(() => poolPlanStore.devices.isLoading, [poolPlanStore.devices.isLoading]);

  const buttons = [
    {
      name: 'Add Device',
      icon: <Icons.Add />,
      disabled: !isActivePlan || poolPlanIsLoading || devicesIsLoading || isEmpty(poolPlanStore.devices.data),
      onClick: onToggleAddDevice,
    },
    {
      name: 'Remove Device',
      icon: <Icons.Bin />,
      disabled: !isActivePlan || poolPlanIsLoading || devicesIsLoading || isEmpty(poolPlanStore.devicesToRemove),
      onClick: () => removeDeviceModal.current.setModalOpen(true),
    },
  ];

  const getData = React.useCallback(async () => {
    await poolPlanStore.invalidateItems();
    await poolPlanStore.getOrganizations(userStore.viewAs);
    await poolPlanStore.setSelectedOrganization(params.id);

    if (!isEmpty(poolPlanStore.organizations.data) && !isEmpty(poolPlanStore.selectedOrganization)) {
      await poolPlanStore.getPool(false, userStore.viewAs);
      await poolPlanStore.getHistory();
      await poolPlanStore.getBalance();
      await poolPlanStore.getOrder();
      await poolPlanStore.getDevices(false, userStore.viewAs);
    } else {
      handleToggle({ errorModal: true });
    }
  }, [handleToggle, params.id, poolPlanStore, userStore.viewAs]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const onChangeTab = (tab) => {
    setTab(tab);
    searchParams.set('tab', tab);

    history.push({
      search: `?${searchParams.toString()}`,
    });
  };

  React.useEffect(() => {
    if (isEmpty(params.id)) {
      history.push(`${ROUTES.POOL_MANAGEMENT}/${poolPlanStore.selectedOrganization}`);
    }
  }, [history, params.id, poolPlanStore.selectedOrganization]);

  return (
    <div>
      <div className="sticky-postpaid">
        <div className="flex items-center justify-between mb-0 md:mb-5">
          <div className="flex items-center py-4 font-medium">
            <Icons.PoolPlanLogo />
            <h2 className="ml-2">eSIM Pool Management</h2>
          </div>
          <ViewAs className="hidden lg:flex" />
          {/* desktop refresh btn */}
          <div className="hidden md:block">
            <Refresh tab={tab} />
          </div>
        </div>
        {/* mobile refresh btn */}
        <div className="md:hidden">
          <Refresh tab={tab} />
        </div>
        <ViewAs className="lg:hidden" />
        <div className="pool-plan md:grid">
          {/* left side  */}
          <div className="left">
            <Select
              label="Organization"
              options={poolPlanStore.organizations.data}
              onChange={(item) => {
                poolPlanStore.setSelectedOrganization(item.code);
                setTab(TABS[0].tab);
                history.push(`${ROUTES.POOL_MANAGEMENT}/${item.code}`);
              }}
              defaultValue={poolPlanStore.organizations.data?.find(
                (i) => i.code === poolPlanStore.selectedOrganization
              )}
              value={poolPlanStore.organizations.data?.find((i) => i.code === poolPlanStore.selectedOrganization)}
              getOptionLabel={(item) => item.name}
              getOptionValue={(item) => item.code}
              labelClassName="mb-1 text-lg font-medium"
              isLoading={poolPlanStore.organizations.isLoading}
            />
            {/* Subscription Detail */}
            <div className="section">
              <h1 className="title">Subscription Detail</h1>
              {poolPlanStore.poolPlan.isLoading ? (
                <Skeleton height={100} />
              ) : (
                <div
                  className={cx('box py-4', {
                    'border-danger border':
                      DQPoolPlan(detailData?.quotaLeftKb, detailData?.expiresAt) || detailData?.quotaLeftKb <= 511999,
                  })}
                >
                  {checkExpired(detailData?.expiresAt)}
                  <div className="flex items-center subtitle">
                    <span className="mr-3">eSIM Data Pooled Plan</span>
                    {detailData?.expiresAt !== null && DQPoolPlan(detailData?.quotaLeftKb, detailData?.expiresAt) && (
                      <Icons.Warning />
                    )}
                    {detailData?.quotaLeftKb <= 511999 &&
                      !DQPoolPlan(detailData?.quotaLeftKb, detailData?.expiresAt) && <Icons.Warning />}
                  </div>
                  <div
                    className={cx('mb-2 text-2xl font-medium', {
                      'text-warning':
                        (!detailData?.quotaLeftKb > 0 && detailData?.quotaLeftKb !== null) ||
                        detailData?.quotaLeftKb <= 511999,
                    })}
                  >
                    {detailData?.quotaLeftKb > 0
                      ? `${quotaGBSizeFromKB(detailData.quotaLeftKb).resultNum} ${
                          quotaGBSizeFromKB(detailData.quotaLeftKb).resultDim
                        }`
                      : '0.0 MB'}
                  </div>
                  <div className="flex items-center">
                    {!checkExpired(detailData?.expiresAt) || detailData?.expiresAt === null ? (
                      <Icons.Expiry />
                    ) : (
                      <Icons.Expired />
                    )}
                    <span
                      className={cx('expiry-text', {
                        'text-warning': checkExpired(detailData?.expiresAt) && detailData?.expiresAt !== null,
                      })}
                    >
                      {(detailData?.expiresAt && detailData?.expired === false) ||
                      detailData?.expiresAt !== '0001-01-01T00:00:00Z' ||
                      !detailData?.expiresAt
                        ? formatDate(detailData?.expiresAt)
                        : '-'}
                    </span>
                  </div>
                  {!isEmpty(poolPlanStore.poolPlan.item) && isEmpty(userStore.viewAs) && (
                    <div className="btn-add" onClick={onTogglePoolPlanTopUp}>
                      <Icons.AddBtn />
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* Top Up Settings */}
            <TopupSettings />
          </div>
          {/* right side  */}
          <div className="right">
            {/* btn bar */}
            <div className="flex flex-col-reverse justify-between mb-5 lg:flex-row">
              <div className="flex">
                {TABS.map((item, index) => (
                  <div
                    key={index + item.name}
                    className={cx(
                      'flex items-center btn-tab',
                      { 'border-primary text-black': tab === item.tab },
                      { 'text-disabled_text': tab !== item.tab }
                    )}
                    onClick={() => isActivePlan && onChangeTab(item.tab)}
                  >
                    <div className="mr-2">
                      <div className="pool-plan-tab-icon">{item.icon(tab === item.tab ? true : false)}</div>
                    </div>
                    <div>{item.name}</div>
                  </div>
                ))}
              </div>
              <div className="h-12 mb-8 ml-auto pool-plan-device-btn md:mb-6 lg:mb-0 lg:h-auto">
                {tab === 'management' &&
                  buttons.map((item, index) =>
                    !isEmpty(userStore.viewAs) && item.name === 'Remove Device' ? null : (
                      <button
                        disabled={item.disabled}
                        key={index + item.name}
                        className="device-btn"
                        onClick={item.onClick}
                      >
                        <div className="mr-2">{item.icon}</div>
                        <div>{item.name}</div>
                      </button>
                    )
                  )}
              </div>
            </div>
            {tab === 'management' && <Management tableRef={tableRef} />}
            {tab === 'history' && !isEmpty(poolPlanStore.selectedOrganization) && <History tableRef={tableRef} />}
            {tab === 'order' && !isEmpty(poolPlanStore.selectedOrganization) && <Order tableRef={tableRef} />}
            {tab === 'device' && !isEmpty(poolPlanStore.selectedOrganization) && <Device tableRef={tableRef} />}
          </div>
        </div>
      </div>
      {/* pool plan top up (payment) modal */}
      {toggle.poolPlanTopUp && (
        <PoolPlanTopup
          onToggle={() => {
            poolPlanStore.setTopUpModal(false);
            handleToggle({ poolPlanTopUp: !toggle.poolPlanTopUp });
          }}
        />
      )}
      {/* add device modal  */}
      {toggle.addDevice && <AddDevice onToggle={() => handleToggle({ addDevice: !toggle.addDevice })} />}
      {toggle.errorModal && <AccountIssue onToggle={() => handleToggle({ errorModal: !toggle.errorModal })} />}
      {/* remove device modal  */}
      <RemoveDevices ref={removeDeviceModal} />
    </div>
  );
};

export default inject(({ poolPlanStore, userStore }) => ({ poolPlanStore, userStore }))(observer(Main));
