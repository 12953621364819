import { isNull } from 'lodash';
import HasQuota from './HasQuota';
import NoQuota from './NoQuota';
import NoSFWanLicense from './NoSFWanLicense';
import React from 'react';
import Unlimited from './Unlimited';

export default ({ item, className = '' }) => {
  return (
    <div className={className}>
      {item.sfwan_license && (
        <>
          {item.sfwan_license?.unlimited_data_volume === true ? (
            <Unlimited item={item} />
          ) : (
            <>
              {isNull(item.sfwan_license.quota_mb) && <NoQuota item={item} />}
              {!isNull(item.sfwan_license.quota_mb) && <HasQuota item={item} />}
            </>
          )}
        </>
      )}
      {!item.sfwan_license && <NoSFWanLicense item={item} />}
    </div>
  );
};
