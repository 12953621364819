import { ESIM_DATA_PLAN } from 'definitions';
import cx from 'classnames';
import React from 'react';

const EsimPlanIconUs = ({ isExpand, listViewExpand = false }) => {
  return (
    <>
      <div
        className={cx(
          'flex items-center px-2 py-1 my-2 text-sm font-medium rounded-md justify-center text-primary bg-primary_light',
          {
            'w-auto': !isExpand,
            'w-2/5': isExpand && !listViewExpand,
            'w-3/4': isExpand && listViewExpand,
          }
        )}
      >
        <i className={'icons icon_check_circle_primary'}></i>
        <div className="hidden md:block">NA & EU</div>
      </div>
    </>
  );
};

const EsimPlanIconGlobal = ({ isExpand, listViewExpand = false }) => {
  return (
    <>
      <div
        className={cx(
          'flex items-center px-2 py-1 my-2 text-sm font-medium rounded-md justify-center text-primary bg-primary_light',
          {
            'w-auto': !isExpand,
            'w-1/3': isExpand && !listViewExpand,
            'w-3/4': isExpand && listViewExpand,
          }
        )}
      >
        <i className={'icons icon_globe_primary'}></i>
        <div className="hidden md:block">Global</div>
      </div>
    </>
  );
};

export const EsimDataPlanIcon = ({
  dataRegion = '',
  hasModuleDataOnBottom = false,
  isExpand = false,
  listViewExpand = false,
  showOnTop = true,
}) => {
  if (!isExpand) {
    if (dataRegion === ESIM_DATA_PLAN.US && !hasModuleDataOnBottom) return <EsimPlanIconUs isExpand={isExpand} />;
    else if (dataRegion === ESIM_DATA_PLAN.GLOBAL && !hasModuleDataOnBottom)
      return <EsimPlanIconGlobal isExpand={isExpand} />;
    else return <></>;
  } else {
    if (dataRegion === ESIM_DATA_PLAN.US && showOnTop)
      return <EsimPlanIconUs isExpand={isExpand} listViewExpand={listViewExpand} />;
    else if (dataRegion === ESIM_DATA_PLAN.GLOBAL && showOnTop)
      return <EsimPlanIconGlobal isExpand={isExpand} listViewExpand={listViewExpand} />;
    else if (dataRegion === ESIM_DATA_PLAN.US && !showOnTop)
      return <EsimPlanIconGlobal isExpand={isExpand} listViewExpand={listViewExpand} />;
    else if (dataRegion === ESIM_DATA_PLAN.GLOBAL && !showOnTop)
      return <EsimPlanIconUs isExpand={isExpand} listViewExpand={listViewExpand} />;
    else return <></>;
  }
};
