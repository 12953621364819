import { DEFAULT_PAGESIZE, DEFAULT_VISIBILITY, SIM_STATUS } from 'definitions';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import Grid from 'components/Sim/UsageRecord/Grid';
import List from 'components/Sim/UsageRecord/List';
import React from 'react';
import semver from 'semver';
import ViewAs from 'components/common/ViewAs';

const UsageRecord = ({ userStore, postpaidStore }) => {
  const ref = React.useRef(null);
  const [view, setView] = React.useState('GRID');
  const [advancedFilters, setAdvancedFilters] = React.useState({
    from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    to: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });

  const cleanFW = (fw) => {
    const regex = /\d+\.\d+\.\d+/g;
    let match = regex.exec(fw);
    return match[0];
  };

  const filterDefaults = React.useCallback(() => {
    return { isDeviceHidden: DEFAULT_VISIBILITY };
  }, []);

  const [filters, setFilters] = React.useState(filterDefaults());

  const items = React.useMemo(() => {
    return postpaidStore.items.data?.map((i) => ({
      ...i,
      status:
        i.fw && semver.gte(cleanFW(i.fw), '8.1.3')
          ? i.carrier
            ? SIM_STATUS.ONLINE
            : SIM_STATUS.OFFLINE
          : SIM_STATUS.UNKNOWN,
      usageMb: i.usageMb ? i.usageMb : 0,
    }));
  }, [postpaidStore.items.data]);

  const getData = React.useCallback(async () => {
    await postpaidStore.invalidateItems();
    await postpaidStore.setFilters(advancedFilters);

    await postpaidStore.getData(userStore.viewAs);

    setFilters({ isDeviceHidden: DEFAULT_VISIBILITY });
  }, [advancedFilters, postpaidStore, userStore.viewAs]);

  const handleFilters = React.useCallback(
    (name, value) => {
      setFilters((prevState) => ({ ...prevState, [name]: value }));
    },
    [setFilters]
  );

  React.useEffect(() => {
    getData();
  }, [getData]);

  React.useEffect(() => {
    postpaidStore.getStatus();
  }, [postpaidStore]);

  React.useEffect(() => {
    ref.current &&
      ref.current.setAllFilters(
        Object.keys(filters)
          .map((i) => ({ id: i, value: filters[i] }))
          .filter((i) => i.value)
      );
  }, [filters]);

  React.useEffect(() => {
    if (userStore.viewAs) {
      userStore.getOtherUserData();
    } else {
      userStore.me(true);
    }
  }, [userStore.viewAs, userStore]);

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className={cx({ 'sticky-postpaid': view === 'GRID', 'sticky-usage-list-postpaid': view === 'LIST' })}>
        <div className="flex items-center justify-between">
          <h1 className="flex items-center py-4 font-medium">
            <i className="mr-3 icons icon_connect2" />
            Postpaid Service Management
          </h1>
          <ViewAs className="hidden md:flex" />
        </div>
        <div className="flex items-center mb-4">
          <div>
            <ViewAs className="flex mt-2 md:hidden" />
            Keep track of your SIM/ eSIM data usage.
          </div>
          <div className="hidden ml-auto xl:flex">
            <button
              id="grid-view"
              className={cx('btn btn-icon btn-view ml-auto', { 'text-primary_dark': view === 'GRID' })}
              onClick={() => setView('GRID')}
            >
              <i className="mr-1 text-2xl material-icons">apps</i> Grid
            </button>
            <button
              id="list-view"
              className={cx('btn btn-icon btn-view ml-4', { 'text-primary_dark': view === 'LIST' })}
              onClick={() => setView('LIST')}
            >
              <i className="mr-1 text-2xl material-icons">view_list</i> List
            </button>
          </div>
        </div>
      </div>
      {view === 'GRID' && (
        <Grid
          elemRef={ref}
          items={items}
          setAdvancedFilters={setAdvancedFilters}
          isLoading={postpaidStore.items.isLoading}
          options={{
            defaultPageSize: DEFAULT_PAGESIZE,
            advancedFilters,
          }}
          filters={filters}
          setFilters={setFilters}
          filterDefaults={filterDefaults}
          handleFilters={handleFilters}
        />
      )}
      {view === 'LIST' && (
        <List
          elemRef={ref}
          items={items}
          setAdvancedFilters={setAdvancedFilters}
          isLoading={postpaidStore.items.isLoading}
          options={{
            defaultPageSize: DEFAULT_PAGESIZE,
            advancedFilters,
          }}
          filters={filters}
          setFilters={setFilters}
          filterDefaults={filterDefaults}
          handleFilters={handleFilters}
        />
      )}
    </>
  );
};

export default inject(({ userStore, postpaidStore }) => ({ userStore, postpaidStore }))(observer(UsageRecord));
