import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { ROUTES } from 'definitions';
import cx from 'classnames';
import React from 'react';
import useToggle from 'hooks/useToggle';

const SideBar = ({ userStore, poolPlanStore }) => {
  const SF_CLOUD = JSON.parse(localStorage.getItem(process.env.REACT_APP_APP_NAME));

  const { toggle, handleToggle } = useToggle({
    sfcManagement: false,
    connectivityBar: false,
  });

  const onLogout = async () => {
    const consent = localStorage.getItem('consent');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('consent', consent);
    window.location.href = process.env.REACT_APP_LOGOUT_URL;
  };

  React.useEffect(() => {
    if (isEmpty(poolPlanStore.organizations.data)) {
      poolPlanStore.getOrganizations(userStore.viewAs);
    }
  }, [poolPlanStore, userStore.viewAs]);

  // sidenav-tab if window size is less than 768px
  React.useEffect(() => {
    if (window.innerWidth < 768) {
      document.querySelector('.sidenav').classList.add('sidenav-tab');
    }
  }, []);

  return (
    <div className="sidenav">
      <ul className="fixed sidebar menu-items">
        <li onClick={() => handleToggle({ sfcManagement: !toggle.sfcManagement })} className="list-item-only">
          <i className="mr-2 icons icon-topup md-16 md:md-14" />
          SFC Management
        </li>
        {toggle.sfcManagement && (
          <ul className="submenu">
            <li>
              <Link to={ROUTES.TOPUP} className="no-underline submenu-item">
                Check Usage & Top Up
              </Link>
            </li>
            <li>
              <Link to={ROUTES.TOPUP + ROUTES.TOPUP_BULK} className="no-underline submenu-item">
                Bulk Top Up
              </Link>
            </li>
          </ul>
        )}
        {SF_CLOUD && JSON.parse(SF_CLOUD?.isPostpaidUser) && (
          <li>
            <Link to={ROUTES.SIM} className="flex items-center w-full no-underline">
              <i className="mr-2 icons icon_connect2" />
              <span className="w-3/4">Postpaid Service Management</span>
            </Link>
          </li>
        )}
        <li>
          <Link
            to={`${ROUTES.POOL_MANAGEMENT}/${poolPlanStore.selectedOrganization}`}
            className={cx('flex items-center no-underline w-full', {
              'pointer-events-none': isEmpty(poolPlanStore.selectedOrganization),
            })}
          >
            <i className="icons icon_pool_left_bar" />
            eSIM Pool Management
          </Link>
        </li>
        <li>
          <Link
            to={{ pathname: ROUTES.FIRMWARE_UPDATES }}
            target="_blank"
            className="flex items-center w-full no-underline"
          >
            <i className="icons icon_firmware" />
            Firmware Updates
            <i className="ml-auto material-icons-outlined text-primary">launch</i>
          </Link>
        </li>
        <li>
          <Link
            to={{ pathname: ROUTES.SUPPORT_TICKET }}
            target="_blank"
            className="flex items-center w-full no-underline"
          >
            <i className="icons icon-support" />
            <span className="mr-auto">Technical Support</span>
            <i className="material-icons-outlined text-primary">launch</i>
          </Link>
        </li>
        <li>
          <Link to={{ pathname: ROUTES.SETTINGS }} className="flex items-center w-full no-underline">
            <i className="text-center icons text-primary material-icons">settings</i>
            Settings
          </Link>
        </li>

        <li onClick={onLogout} className="list-item-only">
          <i className="mr-4 icons icon-logout" />
          Logout
        </li>
      </ul>
      <ul className="sidebar menu-items side-bottom">
        <li>
          <Link to={{ pathname: 'https://peplink.com/' }} target="_blank" className="no-underline">
            Peplink.com
          </Link>
        </li>
        <li>
          <Link to={{ pathname: 'https://forum.peplink.com/' }} target="_blank" className="no-underline">
            Community
          </Link>
        </li>
        <li>
          <Link to={{ pathname: 'https://speedfusion.com/' }} target="_blank" className="no-underline">
            Marketplace
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default inject(({ userStore, poolPlanStore }) => ({ userStore, poolPlanStore }))(observer(SideBar));
