import axios from 'axios';
import axiosRetry from 'axios-retry';
import services from 'services';

const APP_NAME = process.env.REACT_APP_APP_NAME;

const headers = ({ access_token, saToken }) => {
  return {
    headers: {
      Authorization: `Bearer ${access_token}`,
      saToken,
    },
  };
};

const responseParser = (res) => res.data;
const errorParser = (res) => {
  console.error(res);
  return res.response.data;
};

export default class Http {
  constructor() {
    this.axiosInstance = axios.create({
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_API_SERVER,
      // baseURL: 'http://localhost:8080/api',
    });

    axiosRetry(this.axiosInstance, {
      retries: 5,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
    });

    this.axiosInstance.interceptors.response.use(
      function (config) {
        return config;
      },
      (error) => {
        if (error.message === 'Network Error') {
          // window.location = '/504';
        }

        const {
          response: { status = null },
        } = error;
        const originalRequest = error.config;

        switch (status) {
          case 500:
            return Promise.reject(error);
          case 406:
            localStorage.removeItem(APP_NAME);
            window.location.href = process.env.REACT_APP_LOGOUT_URL;
            return Promise.reject(error);
          case 404:
            // window.location = `/404`;
            if (originalRequest.url.substring(1, 5) === 'pool') {
              return Promise.reject(error);
            }
            break;
          case 403:
            sessionStorage.setItem('REDIRECT', window.location.pathname);
            localStorage.removeItem(APP_NAME);
            window.location = '/';
            return Promise.reject(error);
          case 401:
            const obj = localStorage.getItem(`${APP_NAME}`);
            if (obj) {
              const { refresh_token } = JSON.parse(obj);

              try {
                let is_refresh_token_valid = false;
                const refresh_token_payload = JSON.parse(window.atob(refresh_token.split('.')[1]));
                is_refresh_token_valid = new Date(refresh_token_payload.exp * 1000) > new Date();

                if (is_refresh_token_valid) {
                  return new Promise((resolve, reject) => {
                    services.authService
                      .refreshToken({ refresh_token })
                      .then((res) => {
                        originalRequest.headers['Authorization'] = `Bearer ${res.access_token}`;
                        originalRequest.headers['saToken'] = `${res.saToken}`;
                        let localData = JSON.parse(localStorage.getItem(`${APP_NAME}`));
                        localData.access_token = res.access_token;
                        localData.saToken = res.saToken;
                        localData.refresh_token = res.refresh_token;
                        localStorage.setItem(`${APP_NAME}`, JSON.stringify(localData));
                        resolve(axios(originalRequest));
                      })
                      .catch((err) => {
                        localStorage.removeItem(APP_NAME);
                        window.location.href = process.env.REACT_APP_LOGOUT_URL;
                      });
                  });
                } else {
                  localStorage.removeItem(APP_NAME);
                  window.location.href = process.env.REACT_APP_LOGOUT_URL;
                }
              } catch (e) {
                console.log(e);
              }
              return Promise.reject(error);
            }
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );
  }

  getToken = () => {
    const res = localStorage.getItem(APP_NAME);
    if (res) {
      const { access_token, saToken } = JSON.parse(res);
      return { access_token, saToken };
    }
  };

  setToken = (token) => {
    localStorage.setItem(APP_NAME, token);
  };
  removeToken = () => {
    localStorage.removeItem(APP_NAME);
  };

  get = (url, payload = {}) => {
    const config = Object.assign({ params: payload }, headers(this.getToken()));
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };

  getBlob = (url, payload = {}) => {
    const config = Object.assign({ params: payload }, headers(this.getToken()), { responseType: 'blob' });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };

  post = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };

  postFile = (url, formData) => {
    const config = Object.assign(headers(this.getToken()), { 'Content-Type': 'multipart/form-data' });
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, formData, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };

  put = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  patch = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  delete = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(url, { data: payload, ...config })
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
}
