import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'definitions';
import Callback from 'components/Callback';
import Home from 'components/Home';
import MainLayout from 'components/common/MainLayout';
import ProtectedRoute from 'components/common/ProtectedRoute';
import React from 'react';
import Sim from 'components/Sim';
import Subscription from 'components/PoolPlan';
import TopUp from 'components/TopUp';
import useMarkerIo from 'hooks/useMarkerIo';
import UserSettings from 'components/UserSettings';

export default () => {
  useMarkerIo({
    enabled: process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'prod',
  });

  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute path={ROUTES.SIM}>
          <MainLayout component={Sim} />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.TOPUP}>
          <MainLayout component={TopUp} />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.POOL_MANAGEMENT}>
          <MainLayout component={Subscription} />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.SETTINGS}>
          <MainLayout component={UserSettings} />
        </ProtectedRoute>
        <Route path={ROUTES.CALLBACK} component={Callback} />
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
};
