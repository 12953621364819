import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { isNewerVersion } from 'utils';
import { MINIMUM_FIRMWARE_VERSION } from 'definitions';
import Checkbox from 'components/common/form/Checkbox';
import React from 'react';

const NoQuota = ({ item, prepaidStore, userStore }) => {
  const fwVer = React.useMemo(() => item.fw_ver || item.fw, [item.fw, item.fw_ver]);

  return (
    <>
      {/* sfwan_license.quota_mb === null */}
      <div className="sfc-card-title">
        <div className="flex items-center justify-between">
          <div className="flex items-center">SFC Usage Quota</div>
          {isEmpty(userStore.viewAs) && (
            <Checkbox
              onChange={() => prepaidStore.onSelectCloudDevice(item)}
              checked={prepaidStore.isCloudDeviceSelected(item)}
            />
          )}
        </div>
      </div>
      <div className="sfc-card-content">
        <div className="flex items-end">
          <div className="flex items-center mt-1 leading-tight">
            <>
              <i className="mr-2 icons icon-5g-plan-dimmed"></i>
              {isNewerVersion(fwVer, MINIMUM_FIRMWARE_VERSION) ? (
                <div className="items-end sn-expire-text-gray">
                  <span className="remaining-sfc-usage-number">-</span>
                </div>
              ) : parseFloat(item.fw || item.fw_ver) >= 8.3 &&
                item.care_summary_general_type &&
                new Date() < new Date(item.care_summary_general_expiration_date) ? (
                <div className="items-end text-primary">
                  <span className="text-lg font-semibold">Fair Usage Policy</span>
                  <br />
                  <span>(up to 10Mbps)</span>
                </div>
              ) : (
                <div className="items-end mt-1 sn-expire-text-gray">
                  <span className="remaining-sfc-usage-number">0</span>
                  <span className="remaining-sfc-usage-type">MB</span>
                </div>
              )}
            </>
          </div>
        </div>
        <div className="flex sn-expire-text-gray">
          <span className="flex items-center ml-1">
            <i className="mr-2 icons icon-sfc-expiry-usage-disable" />-
          </span>
        </div>
      </div>
    </>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(NoQuota));
