import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import Consent from 'components/common/Consent';
import Header from 'components/common/Header';
import React from 'react';
import SideBar from 'components/common/SideBar';

const MainLayout = ({ component: Component, userStore, ...rest }) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  React.useEffect(() => {
    userStore.me(true);
  }, [userStore]);

  // if window size is more than 768px, sidebar default open
  React.useEffect(() => {
    if (window.innerWidth > 768) {
      setIsSidebarOpen(true);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <Consent />
            <div className="relative md:flex overflow-y-clip">
              {isSidebarOpen && <SideBar />}
              <main className="container px-4 py-6 mx-auto">
                <Component {...props} />
              </main>
            </div>
          </>
        );
      }}
    />
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(MainLayout));
