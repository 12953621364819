import './styles/default.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import stores from 'stores';
import ToastContainer from 'components/common/Toast';

ReactDOM.render(
  <Provider {...stores}>
    <App />
    <ToastContainer />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
