import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default ({ label, children, loader, isLoading, actionButton }) => {
  const Loader = loader ? loader : <Skeleton height={20} />;
  return (
    <div className="mb-4 form-group-top-up">
      <div className="flex">
        <label htmlFor="" className="font-semibold">
          {label}
        </label>
        {actionButton}
      </div>
      {isLoading ? Loader : children}
    </div>
  );
};
