import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Checkbox from 'components/common/form/Checkbox';
import React from 'react';

const NoSFWanLicense = ({ item, prepaidStore, userStore }) => {
  return (
    <div className="flex items-center">
      {isEmpty(userStore.viewAs) && (
        <Checkbox
          onChange={() => prepaidStore.onSelectCloudDevice(item)}
          checked={prepaidStore.isCloudDeviceSelected(item)}
          className="mr-4"
        />
      )}

      <div className="flex items-end">
        <i className="mr-2 icons icon-5g-plan-dimmed"></i>
        <span className="remaining-sfc-usage-number sn-expire-text-gray">0</span>
        <span className="remaining-sfc-usage-type sn-expire-text-gray">MB</span>
      </div>
    </div>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(NoSFWanLicense));
