import { dateFormat } from 'utils';
import { isEmpty } from 'lodash';
import { Modal } from 'components/common/Modal';
import React from 'react';
import ReactTable from 'components/common/ReactTable';

export default ({ onToggle, items }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'datetime',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => dateFormat(original.datetime),
      },
      { Header: 'IMEI', accessor: 'imei' },
      { Header: 'Model', accessor: 'model' },
      { Header: 'Vendor', accessor: 'vendor' },
    ],
    []
  );

  return (
    <Modal size="md" containerClass="bg-white" onToggle={onToggle}>
      <div className="sticky top-0 z-10 flex flex-row items-center w-full bg-white border-t-8 border-primary">
        <div className="flex w-full px-6 pt-4 font-bold uppercase text-primary bg-brown">
          IMEI History
          <button onClick={onToggle} className="ml-auto text-2xl text-black material-icons">
            close
          </button>
        </div>
      </div>

      <div className="p-8">
        {!isEmpty(items) ? (
          <ReactTable
            perPageText="entries per page"
            columns={columns}
            data={items}
            className="mb-5"
            options={{
              totalColumns: 4,
              hiddenColumns: [],
              filters: {
                pageSize: 10,
              },
            }}
          />
        ) : (
          <>
            <h1>No results found.</h1>
          </>
        )}

        <div className="flex items-center justify-end">
          <button className="ml-3 btn btn-outline" onClick={onToggle}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
