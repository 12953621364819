import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import Header from './Header';
import QuotaLifetime from '../QuotaLifetime';
import React from 'react';
import SIMQuotaLifetime from '../SIMQuotaLifetime';

const Grid = ({ children, item, poolPlanStore }) => {
  const isPool = React.useMemo(() => poolPlanStore.SN_LIST.includes(item.sn), [item.sn, poolPlanStore.SN_LIST]);
  const poolUsage = React.useMemo(() => {
    let totalUsage = poolPlanStore.sns.reduce((accumulator, cursor) => {
      if (cursor.sn === item.sn || cursor.masterSN === item.sn) {
        let deviceFreeKb = cursor.iccids.reduce((acc, cur) => acc + cur.freeKb, 0);
        return accumulator + deviceFreeKb;
      }

      return accumulator;
    }, 0);

    return totalUsage;
  }, [item.sn, poolPlanStore.sns]);

  if (item.errors) {
    item.errors.map((error) => {
      if (error.includes('No available plans')) delete item.errors[0];
      return item.errors;
    });
  }

  // Enable those code when 8.3.2 is released, please don't del it.
  // function compareVersions(versionA, versionB) {
  //   const segmentsA = versionA.split('.').map(Number);
  //   const segmentsB = versionB.split('.').map(Number);
  //   for (let i = 0; i < segmentsA.length; i++) {
  //     if (segmentsA[i] > segmentsB[i]) {
  //       return 1;
  //     } else if (segmentsA[i] < segmentsB[i]) {
  //       return -1;
  //     }
  //   }
  //   return 0;
  // }

  return (
    <div className="prepaid-grid-card">
      <div className="card-section">
        <Header item={item}>{children}</Header>
        <div>
          {item.product && <div className="mb-2 product-name">{item.product.name}</div>}
          <div
            className={cx({
              'flex justify-between': isPool,
            })}
          >
            <div>
              <div className="mb-2 device-name">{item.name}</div>
              {(item.fw || item.fw_ver) && (
                <>
                  {
                    parseFloat(item.fw || item.fw_ver) < 8.1 && item.support_sfc === true ? (
                      <div className="flex justify-between text-danger">
                        <div className="flex items-center cursor-pointer ">
                          {'Firmware ' + (item.fw || item.fw_ver).substring(0, 5)}
                        </div>
                        <div>
                          <span className="mr-1 material-icons icon-firmware-error md-18">error_outline</span>
                          8.1.0 Required
                        </div>
                      </div>
                    ) : (
                      // Enable those code when 8.3.2 is released, please don't del it.
                      // item?.sfwan_license?.unlimited_data_volume === true ? (
                      // <>
                      //   {compareVersions((item.fw || item.fw_ver).substring(0, 5), '8.3.2') >= 0 ? (
                      //     <div className="firmware">{'Firmware ' + (item.fw || item.fw_ver).substring(0, 5)}</div>
                      //   ) : (
                      //     <div className="flex justify-between text-danger">
                      //       <div className="flex items-center cursor-pointer">
                      //         {'Firmware ' + (item.fw || item.fw_ver).substring(0, 5)}
                      //       </div>
                      //       <div>
                      //         <span className="mr-1 material-icons icon-firmware-error md-18">error_outline</span>
                      //         8.3.2 Required
                      //       </div>
                      //     </div>
                      //   )}
                      // </>
                      // ) : (
                      <div className="firmware">{'Firmware ' + (item.fw || item.fw_ver).substring(0, 5)}</div>
                    )
                    // )
                  }
                </>
              )}
            </div>
            {isPool && <i className="icons icon-pool-device" />}
          </div>
          <div className="mt-3 sn-row"></div>
          {item.top_up_plan_eligible ? <QuotaLifetime item={item} className="pt-3" /> : <div className="mb-5" />}
          {item.support_sfc_sim ? (
            <SIMQuotaLifetime item={item} isPool={isPool} className="pt-4 pb-2" poolUsage={poolUsage} />
          ) : (
            <div className="mb-5" />
          )}
        </div>
      </div>
    </div>
  );
};

export default inject(({ userStore, prepaidStore, poolPlanStore }) => ({ userStore, prepaidStore, poolPlanStore }))(
  observer(Grid)
);
