import { Link } from 'react-router-dom';
import { snLink } from 'utils';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ data }) => {
  return (
    <div className="flex items-center">
      {/* pool plan icon */}
      {data.isPool ? <div className="icons icon-list-is-pool" /> : <div className="ml-10"></div>}
      <Link
        to={{ pathname: snLink(data) }}
        className="flex items-center font-semibold redirect-sn gap-2"
        target="_blank"
      >
        <span>{data.sn} </span> <div className="cursor-pointer icons icon-ic2-link-sm" />
      </Link>
      {data.modules && data.modules.length > 0 && (
        <Tooltip
          placement="right"
          trigger="hover"
          tooltip={
            <div>
              {data.modules.map((module, index) => (
                <div key={index} className="flex items-center">
                  <div className="text-white">{module.sn}</div>
                </div>
              ))}
            </div>
          }
        >
          <div className="flex items-center px-2 py-1 ml-1 cursor-pointer rounded-md bg-primary_light text-primary">
            <i className="icons icon-extent-modules" />
            <span> {data.modules.length}</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};
