import { computed, configure, makeObservable, observable } from 'mobx';
import { concat, first, isEmpty, isNull, sortBy } from 'lodash';
import { dateFormat } from 'utils';
import { removeEmpty } from 'utils';
import { STORE_DEFAULT_ITEMS } from 'definitions';

configure({
  enforceActions: 'never',
});

export default class PrepaidStore {
  constructor(props) {
    this.api = props.api;
    this.status = [];
    this.filters = {};
    this.isLoading = false;
    this.items = STORE_DEFAULT_ITEMS;
    this.device_cache_created = null;
    this.roles = [];
    this.selectedOrganization = null;

    this.selectedCloudDevices = [];
    this.selectedConnectDevices = [];
    this.serverErrors = [];

    makeObservable(this, {
      status: observable,
      items: observable,
      device_cache_created: observable,
      roles: observable,
      selectedOrganization: observable,
      selectedCloudDevices: observable,
      selectedConnectDevices: observable,
      serverErrors: observable,
      ORGANIZATION_OPTIONS: computed,
      DEVICE_CACHE_FORMATTED: computed,
      DATA: computed,
      ITEMS: computed,
      ORG_CODE: computed,
    });
  }

  setFilters = (filters) => {
    this.filters = removeEmpty(filters);
  };

  invalidateItems = () => {
    this.device_cache_created = null;
    this.items = STORE_DEFAULT_ITEMS;
  };

  getData = async (viewAs) => {
    this.items.isLoading = true;

    if (isNull(this.device_cache_created) && isEmpty(this.items.data)) {
      const response = await this.api.getData(this.filters, viewAs);
      const { device_cache_created, devices } = response;

      this.device_cache_created = device_cache_created;
      this.items = { isLoading: false, data: devices };

      this.setDefaultOrganization();
      this.setServerErrors([]);

      return response;
    } else {
      this.items.isLoading = false;
      return this.items;
    }
  };

  refresh = (payload, viewAs) => this.api.refresh(payload, viewAs);
  refreshIC2 = (payload, viewAs) => this.api.refreshIC2(payload, viewAs);
  download = (email) => this.api.download(email);
  getRoles = async (email) => {
    const response = await this.api.getRoles(email);

    this.roles = response;

    return response;
  };

  setSelectedOrganization = (obj) => {
    this.selectedOrganization = obj;
  };

  setDefaultOrganization = () => {
    this.setSelectedOrganization(first(this.ORGANIZATION_OPTIONS));
  };

  setSelectedCloudDevices = (selected) => {
    this.selectedCloudDevices = selected;
  };

  setSelectedConnectDevices = (selected) => {
    this.selectedConnectDevices = selected;
  };

  setServerErrors = (errors) => {
    this.serverErrors = errors;
  };

  resetSelectedDevices = () => {
    this.setSelectedCloudDevices([]);
    this.setSelectedConnectDevices([]);
  };

  onSelectCloudDevice = (item) => {
    const isSelected = this.selectedCloudDevices.some((i) => i.sn === item.sn);
    const selected = isSelected
      ? this.selectedCloudDevices.filter((i) => i.sn !== item.sn)
      : concat(this.selectedCloudDevices, item);

    this.setSelectedCloudDevices(selected);
  };

  isCloudDeviceSelected = (item) => {
    return this.selectedCloudDevices.some((i) => i.sn === item.sn);
  };

  getConnectDevicesSelectedSNs = (sn) => {
    const item = this.selectedConnectDevices.find((i) => i.sn === sn);

    return item?.selected || [];
  };

  onSelectConnectDevice = (item, selected) => {
    const isExist = this.selectedConnectDevices.some((i) => i.sn === item.sn);

    if (isExist) this.selectedConnectDevices = this.selectedConnectDevices.filter((i) => i.sn !== item.sn);

    this.selectedConnectDevices = concat(this.selectedConnectDevices, { ...item, selected: selected.devices });
  };

  onDeselectConnectDevice = (sn) => {
    this.selectedConnectDevices = this.selectedConnectDevices.filter((i) => i.sn !== sn);
  };

  isConnectDeviceSelected = (sn) => {
    return this.selectedConnectDevices.some((i) => i.sn === sn);
  };

  get ORGANIZATION_OPTIONS() {
    return sortBy(
      this.items.data.reduce((pre, cur) => {
        const added = pre?.some((i) => i?.org_id === cur?.org_id);
        return added
          ? pre
          : [...pre, { label: cur.org_name, value: cur.org_name, org_id: cur.org_id, org_code: cur.org_code }];
      }, []),
      (i) => i.label
    );
  }

  get DEVICE_CACHE_FORMATTED() {
    return dateFormat(this.device_cache_created, 'dd-MM-yyyy HH:mm');
  }

  get DATA() {
    return this.items.data.map((i) => ({
      ...i,
      usages: i.usages !== undefined ? i.usages : [],
      plan: 'activation_plan',
      sfwan_license: i.sfwan_license
        ? {
            ...i.sfwan_license,
            latest_top_up_mb: i.sfwan_license.entitled_mb
              ? i.sfwan_license.entitled_mb > i.sfwan_license.latest_top_up_mb
                ? i.sfwan_license.entitled_mb
                : i.sfwan_license.latest_top_up_mb
              : i.sfwan_license.latest_top_up_mb,
          }
        : undefined,
    }));
  }

  get ITEMS() {
    const items = !isEmpty(this.selectedOrganization)
      ? this.DATA.filter((i) => i.org_code === this.selectedOrganization.org_code)
      : this.DATA;

    return items;
  }

  get ORG_CODE() {
    const orgCode = this.selectedOrganization?.org_code || null;

    return orgCode;
  }
}
