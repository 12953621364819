import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Checkbox from 'components/common/form/Checkbox';
import cx from 'classnames';
import ExpansionModuleModal from '../ExpansionModuleModal';
import React from 'react';
import useToggle from 'hooks/useToggle';

const Selector = ({ item, prepaidStore, userStore, className, poolPlanStore, deviceDataRegion, view = 'grid' }) => {
  const { toggle, handleToggle } = useToggle({ expansionModuleModal: false });

  const selectedSNs = prepaidStore.getConnectDevicesSelectedSNs(item.sn);
  const isSelected = prepaidStore.isConnectDeviceSelected(item.sn);

  selectedSNs?.length > 1 && view === 'grid' ? (className = 'w-5/12') : (className = 'w-1/3');
  selectedSNs?.length > 1 && view === 'list' ? (className = 'w-1/2') : (className = 'w-auto');

  const onClick = () => {
    if (!isSelected) {
      if (!isEmpty(item?.modules)) handleToggle({ expansionModuleModal: true });
      else {
        prepaidStore.onSelectConnectDevice(item, { devices: [item.sn] });
        poolPlanStore.onSelectDevice(item);
      }
    } else {
      prepaidStore.onDeselectConnectDevice(item.sn);
      poolPlanStore.onDeselectConnectDevice(item.sn);
    }
  };

  return (
    <>
      {isSelected && isEmpty(userStore.viewAs) && (
        <div className={cx('flex items-center justify-end cursor-pointer', className)}>
          <div
            onClick={() => handleToggle({ expansionModuleModal: true })}
            className="flex items-center text-primary_dark"
          >
            {selectedSNs?.length > 1 && (
              <>
                {selectedSNs?.length} selected<span className="text-lg material-icons-outlined">arrow_drop_down</span>
              </>
            )}
          </div>
          <Checkbox
            onChange={() => {
              prepaidStore.onDeselectConnectDevice(item.sn);
              poolPlanStore.onDeselectConnectDevice(item);
            }}
            checked={isSelected}
          />
        </div>
      )}
      {!isSelected && isEmpty(userStore.viewAs) && (
        <div onClick={onClick} className="check-box">
          <label />
        </div>
      )}

      {toggle.expansionModuleModal && (
        <ExpansionModuleModal
          onToggle={() => handleToggle({ expansionModuleModal: !toggle.expansionModuleModal })}
          item={item}
          deviceDataRegion={deviceDataRegion}
        />
      )}
    </>
  );
};

export default inject(({ prepaidStore, userStore, poolPlanStore }) => ({ prepaidStore, userStore, poolPlanStore }))(
  observer(Selector)
);
