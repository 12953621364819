import { DEFAULT_VISIBILITY, SIM_STATUS_OPTIONS } from 'definitions';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { inject, observer } from 'mobx-react';
import { toMb } from 'utils';
import { useForm } from 'react-hook-form';
import Carrier from 'components/Sim/UsageRecord/component/Filter/Carrier';
import ConnectivityUsage from 'components/Sim/UsageRecord/component/Filter/ConnectivityUsage';
import DeviceName from 'components/Sim/UsageRecord/component/Filter/DeviceName';
import ICCID from 'components/Sim/UsageRecord/component/Filter/ICCID';
import Model from 'components/Sim/UsageRecord/component/Filter/Model';
import MonthPicker from 'components/Sim/UsageRecord/component/Filter/MonthPicker';
import React from 'react';
import SerialNumber from 'components/Sim/UsageRecord/component/Filter/SerialNumber';
import Status from 'components/Sim/UsageRecord/component/Filter/Status';
import Tag from 'components/Sim/UsageRecord/component/Filter/Tag';
import UsageStatus from 'components/Sim/UsageRecord/component/Filter/UsageStatus';

const Filter = ({
  handleFilters,
  setFilters,
  filterDefaults,
  setAdvancedFilters,
  postpaidStore,
  userStore,
  filters,
}) => {
  const [maxQuotaSim, setMaxQuotaSim] = React.useState({ type: 'TB' });
  const [selectedMonth, setSelectedMonth] = React.useState(userStore.POSTPAID_RANGE.max);
  const [selectedStatus, setSelectedStatus] = React.useState(DEFAULT_VISIBILITY);
  const [selectedUsageStatus, setSelectedUsageStatus] = React.useState(SIM_STATUS_OPTIONS[0].value);

  const isLoading = React.useMemo(() => postpaidStore.items.isLoading, [postpaidStore.items.isLoading]);
  const items = React.useMemo(() => postpaidStore.items.data, [postpaidStore.items.data]);

  const defaultValues = () => ({
    iccid: '',
    sn: '',
    status: '',
    carrier: '',
    model: '',
    'device-name': '',
    tag: '',
    isDeviceHidden: DEFAULT_VISIBILITY,
  });

  const methods = useForm();
  const { register, reset } = methods;

  const handleQuota = (which, quota) => {
    const quotaMb = quota ? toMb(quota) : '0';
    handleFilters(which, quotaMb);
    setMaxQuotaSim((prevState) => ({
      ...prevState,
      [which]: quota.amount >= 1 ? Number(quota.amount) : null,
    }));
  };

  const handleType = (type) => {
    const { usage_mb_from, usage_mb_to } = maxQuotaSim;

    setFilters((prevState) => ({
      ...prevState,
      ...(usage_mb_from && { usage_mb_from: toMb({ amount: usage_mb_from, type }) }),
      ...(usage_mb_to && { usage_mb_to: toMb({ amount: usage_mb_to, type }) }),
    }));

    setMaxQuotaSim((prevState) => ({ ...prevState, type }));
  };

  const onChangeMonth = (value) => {
    setSelectedMonth(value);
    setAdvancedFilters({
      from: format(startOfMonth(new Date(value)), 'yyyy-MM-dd'),
      to: format(endOfMonth(new Date(value)), 'yyyy-MM-dd'),
    });
  };

  const onChangeStatus = (value) => {
    setSelectedStatus(value);
    handleFilters('isDeviceHidden', value);
  };

  const onChangeUsageStatus = (value) => {
    setSelectedUsageStatus(value);
    handleFilters('status', value);
  };

  const handleResetFilters = () => {
    reset(defaultValues());
    setFilters(filterDefaults());
    setMaxQuotaSim({ type: 'TB', amount: '' });
    onChangeMonth(userStore.POSTPAID_RANGE.max);
    onChangeStatus(DEFAULT_VISIBILITY);
    onChangeUsageStatus(SIM_STATUS_OPTIONS[0].value);
  };

  React.useEffect(() => {
    if (filters.isDeviceHidden !== selectedStatus) {
      setFilters((prevState) => ({
        ...prevState,
        isDeviceHidden: selectedStatus,
      }));
    }
  }, [filters, selectedStatus, setFilters]);

  return (
    <div className="p-6 bg-white">
      <div className="flex sidebar-title">
        <i className="mr-1 material-icons material-icons-outlined md-20">filter_list</i> Filter
        <button className="ml-auto btn btn-filter" type="button" disabled={isLoading} onClick={handleResetFilters}>
          Reset Filter
        </button>
      </div>
      <MonthPicker isLoading={isLoading} onChange={onChangeMonth} value={selectedMonth} />
      <Model isLoading={isLoading} register={register} handleFilters={handleFilters} items={items} />
      <DeviceName isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <SerialNumber isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <ICCID isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <Carrier isLoading={isLoading} register={register} handleFilters={handleFilters} items={items} />
      <Status isLoading={isLoading} onChange={onChangeStatus} value={selectedStatus} items={items} />
      <Tag isLoading={isLoading} register={register} handleFilters={handleFilters} />
      <UsageStatus isLoading={isLoading} onChange={onChangeUsageStatus} value={selectedUsageStatus} />
      <ConnectivityUsage
        isLoading={isLoading}
        maxQuotaSim={maxQuotaSim}
        handleQuota={handleQuota}
        handleType={handleType}
      />
    </div>
  );
};

export default inject(({ postpaidStore, userStore }) => ({ postpaidStore, userStore }))(observer(Filter));
