import { makeObservable, observable } from 'mobx';

export default class SaStore {
  constructor(props) {
    this.api = props.api;

    this.connect5GLTE = [];
    this.connect5GLteUs = [];
    this.connect5GLteGlobal = [];
    this.connectProtect = [];
    this.poolPlan = [];
    this.isLoading = false;
    this.productId = '';
    this.sns = [];
    this.sfcPlanDetails = [];

    makeObservable(this, {
      connect5GLTE: observable,
      connect5GLteUs: observable,
      connect5GLteGlobal: observable,
      connectProtect: observable,
      isLoading: observable,
      productId: observable,
      sns: observable,
      sfcPlanDetails: observable,
    });
  }

  setProductId = (productId) => {
    this.productId = productId;
  };

  setSNS = (sns) => {
    this.sns = sns;
  };

  setSfcPlanDetails = (sfcPlanDetails) => {
    this.sfcPlanDetails = sfcPlanDetails;
  };

  get = async (payload) => {
    this.isLoading = true;

    const response = await this.api.get(payload);
    // const { connect5GLTE, connectProtect } = response;
    const { connect5GLteUs, connect5GLteGlobal, connectProtect } = response;

    // this.connect5GLTE = connect5GLTE.data;
    this.connect5GLteUs = connect5GLteUs.data;
    this.connect5GLteGlobal = connect5GLteGlobal.data;
    this.connectProtect = connectProtect.data;

    this.isLoading = false;

    return response;
  };

  getPoolProducts = async (payload) => {
    this.isLoading = true;

    const response = await this.api.getPoolProducts(payload);

    const { data } = response;
    this.poolPlan = data;
    this.isLoading = false;

    return response;
  };

  post = async (proceed = false) => {
    this.isLoading = true;
    const payload = { productId: this.productId, sns: this.sns, proceed };
    const response = await this.api.post(payload);
    this.isLoading = false;
    return response;
  };
}
