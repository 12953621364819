import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, register, handleFilters }) => {
  return (
    <FilterGroup label="Device Name" isLoading={isLoading}>
      <input
        id="input-device-name"
        name="device-name"
        ref={register()}
        className="w-full p-2 border-transparent"
        placeholder="Please Enter ..."
        onChange={(e) => handleFilters('name', e.target.value)}
      />
    </FilterGroup>
  );
};
