import { inject, observer } from 'mobx-react';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

const Organizations = ({ isLoading, prepaidStore, poolPlanStore }) => {
  const onChange = async (e) => {
    const value = prepaidStore.ORGANIZATION_OPTIONS.find((i) => i.org_code === e.target.value);

    await prepaidStore.setSelectedOrganization(value);
    await poolPlanStore.setSelectedOrganization(value.org_code);
  };

  return (
    <FilterGroup isLoading={isLoading} label="Organizations">
      <select onChange={onChange} value={prepaidStore.selectedOrganization?.org_code}>
        {prepaidStore.ORGANIZATION_OPTIONS.map((i) => (
          <option key={i.org_code} value={i.org_code}>
            {i.label}
          </option>
        ))}
      </select>
    </FilterGroup>
  );
};

export default inject(({ prepaidStore, poolPlanStore }) => ({ prepaidStore, poolPlanStore }))(observer(Organizations));
