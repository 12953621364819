import { quotaGBSizeFromKB } from 'utils';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

export default ({ item }) => {
  return (
    <div className="flex items-center ml-6">
      {item.totalUsage > 0 ? (
        <div className="flex items-center ml-4">
          <i className="mr-2 icons icon-sim-data-normal" />
          <div className="items-end">
            <span className="remaining-sfc-usage-number text-primary">
              {quotaGBSizeFromKB(item.totalUsage).resultNum + ' '}
            </span>
            <span className="remaining-sfc-usage-type text-primary">
              {quotaGBSizeFromKB(item.totalUsage).resultDim}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex items-center ml-4">
          <i className="mr-2 icons icon-sim-data" />
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={
              <div className="tooltip-firmware">
                <span>Your quota is used up.</span>
                <br />
                <span> Top up Pooled Plan now!</span>
              </div>
            }
          >
            <div className="items-end cursor-pointer">
              <span className="remaining-sfc-usage-number sn-expire-text-gray">0</span>
              <span className="remaining-sfc-usage-type sn-expire-text-gray">MB</span>
              <span className="my-2 mr-1 material-icons icon-firmware-error md-12 text-danger">error_outline</span>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
