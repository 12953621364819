import { calculatePagesCount } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import React from 'react';
import Table from './Table';

const DEFAULT_PAGESIZE = 20;

const DeviceTable = ({ tableRef, columns, filterBy = '', filterByLabel = '', poolPlanStore }) => {
  const [search, setSearch] = React.useState('');
  const [balance, setBalance] = React.useState([]);

  const baseData = React.useMemo(() => poolPlanStore.balance, [poolPlanStore.balance]);

  const isLoading = React.useMemo(() => baseData.isLoading, [baseData.isLoading]);

  const data = React.useMemo(() => {
    return !isEmpty(search)
      ? baseData.data.filter((i) => i?.[filterBy]?.toLowerCase().includes(search.toLowerCase()))
      : baseData.data;
  }, [baseData.data, filterBy, search]);

  const totalRow = React.useMemo(() => data.length, [data.length]);
  const totalPage = React.useMemo(() => calculatePagesCount(DEFAULT_PAGESIZE, data.length), [data.length]);

  const getData = React.useCallback(async () => {
    const callback = poolPlanStore.getBalance;
    callback();
  }, [poolPlanStore]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const setData = React.useCallback(
    ({ page, pageSize }) => {
      setBalance(() => data.slice((page - 1) * pageSize, page * pageSize));
    },
    [data]
  );

  const onSearch = (e) => {
    e.persist();
    setSearch(e.target.value);
  };

  return (
    <>
      {isLoading ? (
        <div className="relative">
          <div className="loading" style={{ position: 'relative', background: 'none' }} />
        </div>
      ) : (
        <>
          <div className="pool-plan-history-bar">
            <div className="search-bar focus-within:text-gray-400">
              <span className="icon">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </span>
              <input
                className="focus:outline-none focus:bg-white focus:text-gray-900 pool-placeholder"
                placeholder={filterByLabel}
                onChange={onSearch}
                value={search}
              />
              {search && search.length > 0 && (
                <span className="del" onClick={() => setSearch('')}>
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.5 0C4.73962 0 0.083374 4.47 0.083374 10C0.083374 15.53 4.73962 20 10.5 20C16.2605 20 20.9167 15.53 20.9167 10C20.9167 4.47 16.2605 0 10.5 0ZM15.7084 13.59L14.2396 15L10.5 11.41L6.76046 15L5.29171 13.59L9.03129 10L5.29171 6.41L6.76046 5L10.5 8.59L14.2396 5L15.7084 6.41L11.9688 10L15.7084 13.59Z"
                      fill="black"
                      fill-opacity="0.26"
                    />
                  </svg>
                </span>
              )}
            </div>
          </div>
          <div className="pool-plan-history">
            <Table
              manualPagination
              ref={tableRef}
              data={balance}
              columns={columns}
              fetchData={setData}
              pageCount={totalPage}
              totalRow={totalRow}
              isHistoryTable={true}
            />
          </div>
        </>
      )}
    </>
  );
};

export default inject(({ poolPlanStore, userStore }) => ({ poolPlanStore, userStore }))(observer(DeviceTable));
