import FilterGroup from 'components/common/FilterGroup';
import HelpBubble from 'components/common/HelpBubble';
import React from 'react';

export default ({ isLoading, handleFilters, register }) => {
  return (
    <FilterGroup
      label={
        <>
          ICCID{' '}
          <HelpBubble tooltip="An ICCID is a unique number assigned to your SIM card used in the cellular device." />
        </>
      }
      isLoading={isLoading}
    >
      <input
        id="input-iccid"
        name="iccid"
        ref={register()}
        type="number"
        className="w-full p-4 border-transparent"
        placeholder="Please Enter ..."
        onChange={(e) => handleFilters('iccid', e.target.value)}
      />
    </FilterGroup>
  );
};
