import { find, get, includes } from 'lodash';
import { inject, observer } from 'mobx-react';
import Checkbox from 'components/common/form/Checkbox';
import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

const VISIBILITY = {
  ALL: 'All Devices',
  VISIBLE_ONLY: 'Visible Devices',
  HIDDEN_ONLY: 'Hidden Devices',
  SUSPENDED_ONLY: 'Suspended Devices',
};

const Status = ({ isLoading, onChange, value, items, userStore, postpaidStore }) => {
  const count = React.useMemo(
    () => ({
      VISIBLE_ONLY: items.filter((i) => !includes(userStore.hiddenDevices.postpaid, i.iccid)).length,
      HIDDEN_ONLY: items.filter((i) => includes(userStore.hiddenDevices.postpaid, i.iccid)).length,
      ALL: items.length,
      SUSPENDED_ONLY: items.filter((i) => get(find(postpaidStore.status, { iccid: i.iccid }), 'status') === 'Suspended')
        .length,
    }),
    [items, postpaidStore.status, userStore.hiddenDevices.postpaid]
  );

  return (
    <FilterGroup isLoading={isLoading} label="Status">
      {Object.keys(VISIBILITY).map((key) => (
        <Checkbox value={key} key={key} onChange={(e) => onChange(e.target.value)} checked={value === key}>
          {VISIBILITY[key]} ({count[key]})
        </Checkbox>
      ))}
    </FilterGroup>
  );
};

export default inject(({ userStore, postpaidStore }) => ({ userStore, postpaidStore }))(observer(Status));
