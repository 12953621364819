import { find, get, includes, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import Dropdown from 'components/common/Dropdown';
import HistoryModal from './HistoryModal';
import React from 'react';
import TagModal from './TagModal';
import useToggle from 'hooks/useToggle';

const OPTIONS = {
  TAG: 'Tag(s)',
  ACTIVE: 'Active',
  SUSPEND: 'Suspend',
  SHOW: 'Show',
  HIDE: 'Hide',
  HISTORY: 'ICCID History',
};

const Option = ({ label, icon = null, className = 'material-icons' }) => (
  <div className="flex items-center">
    {icon && <i className={cx('mr-2 md-16', className)}>{icon}</i>}
    {label}
  </div>
);

const SimOptions = ({ item, className = 'ml-auto', userStore, postpaidStore }) => {
  const [items, setItems] = React.useState([]);
  const { toggle, handleToggle } = useToggle({ historyModal: false, tagModal: false });

  const onToggleHistoryModal = () => handleToggle({ historyModal: !toggle.historyModal });
  const onToggleTagModal = () => handleToggle({ tagModal: !toggle.tagModal });

  const onUpdateAllPostpaidStatus = React.useCallback(async () => {
    await postpaidStore.getStatus();
  }, [postpaidStore]);

  const onGetIMEIHistory = (res) => {
    setItems(res);
    onToggleHistoryModal();
  };

  const onClick = async (value) => {
    switch (value?.key) {
      case OPTIONS.TAG:
        onToggleTagModal();
        break;
      case OPTIONS.ACTIVE:
        await postpaidStore.resume(item.iccid).then(onUpdateAllPostpaidStatus);
        break;
      case OPTIONS.SUSPEND:
        await postpaidStore.suspend(item.iccid).then(onUpdateAllPostpaidStatus);
        break;
      case OPTIONS.HISTORY:
      default:
        await postpaidStore.getIMEIHistory(item.iccid).then(onGetIMEIHistory);
        break;
    }
  };

  const onShowOrHide = async (iccid) => await userStore.updateHiddenDevices({ type: 'postpaid', value: iccid });

  const options = React.useMemo(() => {
    const options = [
      ...(isEmpty(userStore.viewAs)
        ? [
            { key: OPTIONS.TAG, label: <Option label={OPTIONS.TAG} icon="label" /> },
            get(find(postpaidStore.status, { iccid: item.iccid }), 'status') === OPTIONS.ACTIVE
              ? { key: OPTIONS.SUSPEND, label: <Option label={OPTIONS.SUSPEND} icon="cancel" /> }
              : { key: OPTIONS.ACTIVE, label: <Option label="Resume" icon="check_circle" /> },
          ]
        : []),
      { key: OPTIONS.HISTORY, label: <Option label={OPTIONS.HISTORY} icon="receipt_long" /> },
    ];

    return options;
  }, [item.iccid, postpaidStore.status, userStore.viewAs]);

  return (
    <div className="flex items-center ml-auto">
      {isEmpty(userStore.viewAs) && (
        <i
          className={`ml-2 cursor-pointer icons icon-${
            includes(userStore.hiddenDevices.postpaid, item.iccid) ? 'visible' : 'hidden'
          }`}
          onClick={() => onShowOrHide(item.iccid)}
        />
      )}
      <Dropdown
        items={options}
        onClick={onClick}
        className={cx(className, 'flex items-center')}
        menuClassname="sim-options"
      >
        <span className="cursor-pointer material-icons-outlined sim-menu-icon ">more_vert</span>
      </Dropdown>
      {toggle.historyModal && <HistoryModal items={items} onToggle={onToggleHistoryModal} />}
      {toggle.tagModal && <TagModal item={item} onToggle={onToggleTagModal} />}
    </div>
  );
};

export default inject(({ userStore, postpaidStore }) => ({ userStore, postpaidStore }))(observer(SimOptions));
