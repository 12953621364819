import { formatDateTime } from 'utils';
import { inject, observer } from 'mobx-react';
import DeviceTable from '../Components/DeviceTable';
import React from 'react';

const Device = ({ tableRef }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Time',
        accessor: 'createdAt',
        Cell: (props) => formatDateTime(props?.cell?.value),
        className: 'col-time',
      },
      {
        Header: 'Detail',
        accessor: 'action',
        className: 'col-details',
        Cell: (props) =>
          props.cell.value && props.row.original.deviceSn
            ? (props.cell.value === 'add' ? 'Added' : 'Removed') + ' Device: ' + props.row.original.deviceSn
            : '-',
      },
      {
        Header: 'ICCID',
        accessor: 'iccids',
        className: 'col-history-top-up',
        Cell: (props) =>
          props.cell.value ? (
            <div>
              {props.cell.value.map((iccId, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <br />}
                  {iccId}
                </React.Fragment>
              ))}
            </div>
          ) : (
            '-'
          ),
      },
    ],
    []
  );

  return <DeviceTable tableRef={tableRef} filterBy="deviceSn" filterByLabel="Serial Number" columns={columns} />;
};

export default inject(({ poolPlanStore, userStore }) => ({ poolPlanStore, userStore }))(observer(Device));
