import { isEmpty } from 'lodash';
import Card from './Card';
import NoResultsFound from 'components/common/NoResultsFound';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

export default ({ children, defaultOptions, prepareRow, selectedFlatRows, rows }) => {
  const { onSelectionChanged } = defaultOptions;

  useEffect(() => {
    onSelectionChanged(selectedFlatRows.filter((i) => !i.original.disabled).map(({ original }) => original));
  }, [onSelectionChanged, selectedFlatRows]);

  const n = 24;

  return (
    <div className="pl-20">
      {defaultOptions.isLoading ? (
        <div className="my-8 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-12">
          {[...Array(n)].map((i, index) => (
            <div className="px-6 pt-6 pb-5 text-sm bg-white" key={index}>
              <>
                <Skeleton height={25} />
                <Skeleton height={25} />
                <Skeleton height={25} />
                <Skeleton height={75} />
                <Skeleton height={25} />
              </>
            </div>
          ))}
        </div>
      ) : (
        <>
          {!isEmpty(rows) && (
            <div
              id="grid-view"
              className="mb-8 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10"
            >
              {rows.map((row, key) => {
                prepareRow(row);
                return <Card item={row.original} key={`tr-body-${key}`} />;
              })}
              {children}
            </div>
          )}
          {!rows.length && !defaultOptions.isLoading && <div>{<NoResultsFound />}</div>}
        </>
      )}
    </div>
  );
};
