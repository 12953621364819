import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import React from 'react';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem(`${process.env.REACT_APP_APP_NAME}`);
  return isAuthenticated ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{ pathname: '/' }} />
  );
};

export default ProtectedRoute;
