import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader } from 'components/common/Modal';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import Select from 'components/common/Select';
import SelectedSNList from './SelectedSNList';

const OPTIONS = {
  SN: 'sn',
  NAME: 'name',
  GROUP: 'grp_name',
  MODEL: 'model',
};

const ADD_BY_OPTIONS = [
  { label: 'Serial Number', value: OPTIONS.SN, errMsg: 'This Serial Number is already entry.' },
  { label: 'Device Name', value: OPTIONS.NAME, errMsg: 'This Device Name is already entry.' },
  { label: 'Group', value: OPTIONS.GROUP },
  { label: 'Model', value: OPTIONS.MODEL },
];

const AddDevice = ({ onToggle, poolPlanStore }) => {
  const [addBy, setAddBy] = useState(ADD_BY_OPTIONS[0]);
  const [selectType, setSelectType] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [filterOpts, setFilterOpts] = useState([]);
  const [step2, setStep2] = useState(false);

  // columns for react table (group or model)
  const columns = useMemo(
    () => [
      {
        Header: 'Serial Number',
        accessor: 'sn',
        sortable: false,
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div className=" whitespace-no-wrap">{data.value}</div>
            {data?.row?.original?.type === 'module' && (
              <div
                className="text-gray-500 whitespace-no-wrap cursor-default hover:text-primary transition-all duration-300"
                style={{
                  fontSize: 10,
                }}
              >
                Expansion module
              </div>
            )}
          </div>
        ),
      },
      { Header: 'Model', accessor: 'model', sortable: false, Cell: (props) => props?.cell?.value || '-' },
      {
        Header: 'Device Name',
        accessor: 'name',
        sortable: false,
        Cell: (props) => props?.cell?.value || '-',
      },
    ],
    []
  );

  const sn = React.useMemo(() => {
    switch (addBy?.value) {
      case OPTIONS.SN:
        return poolPlanStore.devices.data;
      case OPTIONS.NAME:
        return poolPlanStore.devices.data
          .filter((device, index, self) => index === self.findIndex((d) => d && d.name === device.name))
          .filter((device) => device?.name);
      case OPTIONS.GROUP:
        return poolPlanStore.devices.data.filter((i) => {
          return i.grp_name && i.grp_name === selectType?.value;
        });
      case OPTIONS.MODEL:
        return poolPlanStore.devices.data.filter((i) => i.model === selectType?.value);
      default:
        return poolPlanStore.devices.data;
    }
  }, [addBy, poolPlanStore.devices.data, selectType]);

  const genFilterByOptions = (items, by) => {
    return sortBy(
      items.reduce((pre, cur) => {
        const added = pre?.some((i) => i?.value === cur[by]);
        // won't add if the value is undefined
        return added || !cur[by] ? pre : [...pre, { label: cur[by], value: cur[by] }];
      }, []),
      (i) => i.label
    );
  };

  // init data when change option
  const handleChange = (item) => {
    setAddBy(item);
    setSelectedData([]);

    let newFilterOpts = [];
    if ([OPTIONS.GROUP, OPTIONS.MODEL].includes(item.value)) {
      newFilterOpts = genFilterByOptions(poolPlanStore.devices.data, item.value);
      setSelectType(newFilterOpts[0] || null);
    }

    setFilterOpts(newFilterOpts);
  };

  const onConfirm = () => {
    setStep2(true);
  };

  const getData = React.useCallback(async () => {
    await poolPlanStore.getDevices();
  }, [poolPlanStore]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Modal size={step2 ? 'lg' : 'md'} containerClass="bg-black" onToggle={onToggle}>
        <ModalHeader onToggle={onToggle} classNames={{ modal: 'px-6 pt-5', closeBtn: 'px-6 pt-5' }}>
          <h3 className="add-modal-title">{step2 ? 'add device' : 'search device'}</h3>
        </ModalHeader>
        {!step2 ? (
          <div className="add-modal">
            <div className="select-bar">
              {/* select add by */}
              <Select
                label="Add by"
                options={ADD_BY_OPTIONS}
                onChange={(item) => handleChange(item)}
                defaultValue={addBy}
                value={addBy}
                labelClassName="text-sm font-medium text-white mb-1"
                getOptionLabel={(item) => item.label}
                getOptionValue={(item) => item.value}
                remark="Router compatible with eSIM will be displayed."
              />
              {/* select group or model */}
              {(addBy.value === OPTIONS.GROUP || addBy.value === OPTIONS.MODEL) && (
                <Select
                  label={addBy.label}
                  options={filterOpts}
                  onChange={(item) => setSelectType(item)}
                  defaultValue={selectType}
                  value={selectType}
                  labelClassName="text-sm font-medium text-white mb-1"
                  getOptionLabel={(item) => item.label}
                  getOptionValue={(item) => item.value}
                />
              )}
            </div>
            <div className="content">
              {/* add by SN or Device Name */}
              {(addBy.value === OPTIONS.SN || addBy.value === OPTIONS.NAME) && (
                <Select
                  label={
                    addBy.value === OPTIONS.NAME
                      ? `Device Name${selectedData?.length > 1 ? 's' : ''}`
                      : `Serial Number${selectedData?.length > 1 ? 's' : ''}`
                  }
                  onChange={(value) => setSelectedData(value)}
                  options={sn}
                  defaultValue={selectedData}
                  value={selectedData}
                  labelClassName="text-sm font-medium text-white mb-1"
                  getOptionLabel={(item) => (addBy.value === OPTIONS.SN ? item.sn : item.name)}
                  getOptionValue={(item) => item.sn}
                  isMulti={true}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                />
              )}
              {/* add by Group or Model */}
              {(addBy.value === OPTIONS.GROUP || addBy.value === OPTIONS.MODEL) && (
                <div className="modal-react-table">
                  <ReactTable
                    data={sn}
                    customKey={`${addBy.value}-${JSON.stringify(sn)}`}
                    columns={columns}
                    withSelectionColumn
                    isModal={true}
                    options={{
                      hiddenColumns: [],
                      pager: false,
                      rowPerPage: false,
                      pageSize: 1,
                      defaultPageSize: sn.length,
                      isMulti: true,
                      onSelectionChanged: setSelectedData,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="btn-bar">
              <div className="flex items-center mr-6 text-primary">
                {selectedData?.length > 0 ? `${selectedData?.length} selected` : ''}
              </div>
              <button className="btn btn-outline-primary" onClick={onConfirm} disabled={selectedData?.length === 0}>
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className="add-modal">
            <SelectedSNList
              onToggle={onToggle}
              data={selectedData}
              onBackBtn={() => {
                setStep2(false);
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default inject(({ poolPlanStore }) => ({ poolPlanStore }))(observer(AddDevice));
