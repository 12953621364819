import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { isNewerVersion } from 'utils';
import { MINIMUM_FIRMWARE_VERSION } from 'definitions';
import Checkbox from 'components/common/form/Checkbox';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const NoQuota = ({ item, prepaidStore, userStore }) => {
  return (
    <div className="flex items-center">
      {isEmpty(userStore.viewAs) && (
        <Checkbox
          onChange={() => prepaidStore.onSelectCloudDevice(item)}
          checked={prepaidStore.isCloudDeviceSelected(item)}
          className="mr-4"
        />
      )}

      {!isNewerVersion(item?.fw_ver || item?.fw, MINIMUM_FIRMWARE_VERSION) ? (
        <div className="flex items-center">
          <i className="mr-2 icons icon-5g-plan-dimmed" />
          <div className="flex items-end">
            <span className="remaining-sfc-usage-number sn-expire-text-gray">
              {!isNewerVersion(item?.fw_ver || item?.fw, MINIMUM_FIRMWARE_VERSION) ? 0 : '-'}
            </span>
            <span className="remaining-sfc-usage-type sn-expire-text-gray">MB</span>
          </div>
        </div>
      ) : parseFloat(item.fw || item.fw_ver) >= 8.3 &&
        item.care_summary_general_type &&
        new Date() < new Date(item.care_summary_general_expiration_date) ? (
        <div className="flex items-center">
          <i className="mr-2 icons icon-5g-plan" />
          <div className="sfc-list-view-content">
            <span className="remaining-sfc-usage-type text-primary">Fair Usage Policy</span>
            <br />
            <span className="text-primary">(up to 10Mbps)</span>
          </div>
        </div>
      ) : (
        <Tooltip
          placement="top"
          trigger="hover"
          tooltip={<span className="leading-tight text-white">Unable to access data</span>}
        >
          <div className="flex items-center cursor-pointer">
            <i className="mr-2 icons icon-5g-plan-dimmed" />
            <div className="flex items-end">
              <span className="remaining-sfc-usage-number sn-expire-text-gray">-</span>
              <span className="remaining-sfc-usage-type sn-expire-text-gray">MB</span>
              <span className="pb-1 mr-1 material-icons icon-firmware-error md-12 text-danger">error_outline</span>
            </div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(NoQuota));
