import { isEmpty } from 'lodash';
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import cx from 'classnames';
import DownloadErrorLog from '../../TopUp/TopUp/components/DownloadErrorLog';
import PageCountSelector from './controls/PageCountSelector';
import Pager from './controls/Pager';
import parse from 'react-html-parser';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import TableComponent from './controls/TableComponent';
import useCheckbox from './controls/useCheckbox';

const PagerCom = ({ defaultOptions, gotoPage, setPageSize, pageSize, tableProps }) => {
  const itemsFrom = tableProps?.state?.pageIndex * tableProps?.state?.pageSize + 1;
  const itemsTo =
    (tableProps?.state?.pageIndex + 1) * tableProps?.state?.pageSize < tableProps.rows.length
      ? (tableProps?.state?.pageIndex + 1) * tableProps?.state?.pageSize
      : tableProps.rows.length;

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center">
          {defaultOptions.totalText && !isEmpty(tableProps.rows) && (
            <span className="text-base font-bold">
              {itemsFrom} - {itemsTo} of {tableProps.rows.length} {defaultOptions.totalText}
            </span>
          )}
        </div>
        {!isEmpty(defaultOptions.serverErrors) &&
          defaultOptions.serverErrors?.map((error, index) => {
            return (
              <div
                className="flex items-center p-4 mt-2 rounded text-custom-warning bg-custom-warning bg-opacity-50"
                key={index}
              >
                <i className="mr-2 text-lg material-icons">error_outline</i>
                <div>
                  <div> {parse(error)} </div>
                  <DownloadErrorLog serverError={error} />
                </div>
              </div>
            );
          })}
      </div>

      {defaultOptions.rowPerPage && (
        <PageCountSelector
          onSetPageSize={(value) => {
            gotoPage(0);
            setPageSize(value);
          }}
          options={defaultOptions}
          pageSize={pageSize}
        />
      )}

      {tableProps.pageCount > 1 && <Pager onChange={(page) => gotoPage(page)} {...tableProps} className="top-pager" />}
    </>
  );
};

export default ({
  template,
  defaultTableProps,
  defaultOptions,
  tableRef,
  children,
  setTotalNumber,
  setFilteredData,
  header = null,
  className,
}) => {
  const tableProps = useTable(
    {
      ...defaultTableProps,
      manualGlobalFilter: false,
      globalFilter: React.useMemo(
        () => (rows, columns, value) => {
          return value.length
            ? rows.filter((row) => {
                const rowValue = row.values['sn'];
                return value[0].value.includes(rowValue);
              })
            : rows;
        },
        []
      ),
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useCheckbox(defaultOptions)
  );

  const {
    gotoPage,
    setPageSize,
    state: { pageSize },
    setHiddenColumns,
  } = tableProps;

  useEffect(() => {
    if (defaultTableProps.initialState.hiddenColumns) setHiddenColumns(defaultTableProps.initialState.hiddenColumns);
  }, [setHiddenColumns, defaultTableProps.initialState.hiddenColumns]);

  useEffect(() => {
    if (tableRef) {
      tableRef.current = { ...tableProps };
    }
  }, [tableProps, tableRef]);

  useEffect(() => {
    if (tableProps.rows && setTotalNumber) {
      setTotalNumber(tableProps.rows.length); //total (enabled+disabled)
      // setTotalNumber(tableProps.rows.filter((i) => !i.original.disabled).length); // checkbox enabled
    }
  }, [setTotalNumber, tableProps.rows]);

  useEffect(() => {
    if (tableProps.filteredRows && setFilteredData) {
      setFilteredData(tableProps.filteredRows);
    }
  }, [setFilteredData, tableProps.filteredRows]);

  // useEffect(() => {
  //   if (setSelectedData) {
  //     if (tableProps.selectedFlatRows.length > 0) {
  //       setSelectedData(tableProps.selectedFlatRows);
  //     } else {
  //       setSelectedData([]);
  //     }
  //   }
  // }, [tableProps, tableProps.selectedFlatRows]);

  const Template = template;
  const Component = template ? Template : TableComponent;

  React.useEffect(() => {
    var wrapper1 = document.getElementById('wrapper1');
    var wrapper2 = document.getElementById('wrapper2');
    if (wrapper1)
      wrapper1.onscroll = function () {
        wrapper2.scrollLeft = wrapper1.scrollLeft;
      };
    if (wrapper2)
      wrapper2.onscroll = function () {
        wrapper1.scrollLeft = wrapper2.scrollLeft;
      };
  }, []);

  return (
    <div className="relative">
      <div
        className={cx({
          'sticky-content': header && !defaultOptions?.isSimUsage && !defaultOptions?.isUsageList,
          'sticky-usage-list-content': defaultOptions?.isUsageList && !header && !defaultOptions?.isSimUsage,
        })}
        style={{ borderBottom: defaultOptions?.isModal ? '1px solid #ffb81c' : 'none' }}
      >
        {header}
        {!defaultOptions.isLoading && (
          <TableFooter className="flex items-center pb-3 mb-3 text-sm">
            <PagerCom
              defaultOptions={defaultOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              tableProps={tableProps}
            />
          </TableFooter>
        )}
        {!defaultOptions.isLoading && !isEmpty(tableProps.page) && (
          <div className={cx({ wrapper1: defaultOptions.isList })} id="wrapper1">
            <div
              className={cx({ div1: defaultOptions.isList })}
              style={defaultOptions.isList && { width: 'auto', minWidth: '1480px' }}
              id="div1"
            />
          </div>
        )}
        {/* {!defaultOptions.isLoading && isUndefined(template) && (
          <TableWrapperStyled className={cx('table-responsive overflow-y-hidden header-only')}>
            <Component {...tableProps} defaultOptions={defaultOptions} rows={tableProps.page}>
              {children}
            </Component>
          </TableWrapperStyled>
        )} */}
      </div>
      <div className={cx({ wrapper2: defaultOptions.isList })} id="wrapper2">
        <div
          className={cx({ div2: defaultOptions.isList })}
          // style={defaultOptions.isList && { width: 'auto', minWidth: '1480px' }}
          id="div2"
        >
          <TableWrapperStyled
            // className={cx('table-responsive overflow-y-hidden', { 'content-only': isUndefined(template) }, className)}
            className={cx('table-responsive overflow-y-hidden', className)}
          >
            <Component {...tableProps} defaultOptions={defaultOptions} rows={tableProps.page}>
              {children}
            </Component>
          </TableWrapperStyled>
        </div>
      </div>
    </div>
  );
};

const TableWrapperStyled = styled.div`
  position: relative;
  min-height: 150px;

  &.header-only {
    max-height: 50px;
    min-height: unset;
    overflow: hidden;

    tbody {
      opacity: 0;
      background: transparent;
    }
  }

  &.content-only {
    margin-top: -50px;
    thead {
      opacity: 0;
    }
  }

  th {
    span {
      display: flex;
      .material-icons {
        min-height: 18px;
      }
    }
    &.text-right {
      span {
        justify-content: flex-end;
      }
    }
    &.text-center {
      span {
        justify-content: center;
      }
    }
  }
  th,
  td {
    &.collapsed {
      width: 0.0000000001%;
    }
  }

  .table-footer {
    select,
    input {
      border: none;
    }
    input {
      transition: all 0.3s ease-out;
      width: 30px;
      text-align: center;
      &:focus {
        width: 50px;
      }
    }
    button:focus {
      box-shadow: none;
    }
  }
`;

const TableFooter = styled.div`
  select,
  input {
    border: none;
  }
  input {
    transition: all 0.3s ease-out;
    width: 30px;
    text-align: center;
    &:focus {
      width: 50px;
    }
  }
  button:focus {
    box-shadow: none;
  }
`;
