import React from 'react';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

function CustomSelect({
  label,
  options,
  onChange,
  defaultValue,
  value,
  getOptionLabel,
  getOptionValue,
  labelClassName,
  isLoading = false,
  remark,
  ...rest
}) {
  return (
    <div className="mb-5">
      <div className={labelClassName}>{label}</div>
      {isLoading ? (
        <Skeleton height={34} />
      ) : (
        <Select
          classNamePrefix="react-select"
          onChange={onChange}
          options={options}
          defaultValue={defaultValue}
          value={value}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          styles={selectStyles}
          menuPosition={'fixed'}
          {...rest}
        />
      )}
      {remark && <div className="mt-2 text-sm text-white">{remark}</div>}
    </div>
  );
}

export default CustomSelect;

const selectStyles = {
  option: (styles, state) => ({
    ...styles,
    color: 'black',
    background: state.isFocused ? '#FFB81C' : state.isSelected ? '#d0d0d0' : undefined,
    zIndex: 10,
    ':active': {
      backgroundColor: state.isSelected ? '#FFB81C' : 'none',
    },
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: state.isFocused ? '#FFB81C' : undefined,
    boxShadow: state.isFocused ? '0 0 0 1px #FFB81C' : undefined,

    '&:hover': {
      borderColor: state.isFocused ? '#FFB81C' : '#FFB81C',
      boxShadow: '0 0 0 1px #FFB81C',
    },
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#808080',
    ':hover': {
      backgroundColor: data.color,
      color: '#FFB81C',
    },
  }),
};
