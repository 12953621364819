import FilterGroup from 'components/common/FilterGroup';
import React from 'react';

export default ({ isLoading, handleFilters, register }) => {
  return (
    <FilterGroup label="Serial Number" isLoading={isLoading}>
      <input
        id="input-sn"
        name="sn"
        ref={register()}
        maxLength="14"
        className="w-full p-2 border-transparent"
        placeholder="Please Enter ..."
        onChange={(e) => handleFilters('sn', e.target.value)}
      />
    </FilterGroup>
  );
};
