import { dateFormat, dayDifference } from 'utils';
import cx from 'classnames';
import React from 'react';

export default ({ data }) => {
  const checkUnlimited = (item) => {
    if (
      item?.care_summary_general_type !== null &&
      new Date() < new Date(item?.care_summary_general_expiration_date) &&
      parseFloat(item?.fw || item?.fw_ver) >= 8.3
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {data?.sfwan_license ? (
        data?.sfwan_license?.quota_mb === null ? (
          <>-</>
        ) : (
          <>
            <div className="flex items-center">
              <div className="flex">
                <span
                  className={cx(
                    !(
                      data?.sfwan_license?.exp &&
                      dayDifference(data?.sfwan_license?.exp) < 0 &&
                      data?.sfwan_license?.quota_mb < 0
                    ) &&
                      data?.sfwan_license?.unlimited_data_volume === false &&
                      'text-danger'
                  )}
                >
                  {(data?.sfwan_license?.exp &&
                    dayDifference(data?.sfwan_license?.exp) >= 0 &&
                    data?.sfwan_license?.quota_mb > 0) ||
                  data?.sfwan_license?.unlimited_data_volume === true
                    ? dateFormat(data?.sfwan_license?.exp)
                    : checkUnlimited(data)
                    ? '-'
                    : 'Expired'}
                </span>
              </div>
            </div>
          </>
        )
      ) : (
        <>-</>
      )}
    </div>
  );
};
