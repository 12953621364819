import { find, get } from 'lodash';
import { inject, observer } from 'mobx-react';
import { quotaDimension } from 'utils';
import { SIM_STATUS } from 'definitions';
import cx from 'classnames';
import React from 'react';

const SUSPENDED = 'Suspend';

const Usage = ({ item, postpaidStore }) => {
  return (
    <div className="flex items-center">
      <i
        className={cx('ml-1 mr-2 icons ', {
          'icon-upload-download': quotaDimension(item.usageMb).quota > 0,
          'icon-upload-download-dimmed': quotaDimension(item.usageMb).quota === 0,
        })}
      ></i>
      <div className="items-end">
        <span
          className={cx('remaining-sfc-usage-number', {
            'text-primary': quotaDimension(item.usageMb).quota > 0,
            'sn-expire-text-gray': quotaDimension(item.usageMb).quota === 0,
          })}
        >
          {item.status !== SIM_STATUS.UNKNOWN ? quotaDimension(item.usageMb).quota : '-'}
        </span>
        <span
          className={cx('remaining-sfc-usage-type', {
            'text-primary': quotaDimension(item.usageMb).quota > 0,
            'sn-expire-text-gray': quotaDimension(item.usageMb).quota === 0,
          })}
        >
          {quotaDimension(item.usageMb).type}
        </span>
      </div>
      {get(find(postpaidStore.status, { iccid: item.iccid }), 'status') === SUSPENDED && (
        <div className="px-2 py-1 ml-2 border border-solid rounded border-danger bg-danger bg-opacity-25 text-danger">
          Suspended
        </div>
      )}
    </div>
  );
};

export default inject(({ postpaidStore }) => ({ postpaidStore }))(observer(Usage));
