import { formatDate, quotaGBSizeFromKB } from 'utils';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Modal, ModalFooter, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import React from 'react';
import { findDataRegion } from 'components/TopUp/helper/findDataRegion';

const ExpansionModuleModal = ({ onToggle, item, prepaidStore, poolPlanStore, deviceDataRegion }) => {
  const selectedSNs = prepaidStore.getConnectDevicesSelectedSNs(item.sn);

  const methods = useForm({ defaultValues: { devices: selectedSNs } });
  const { handleSubmit, register, watch } = methods;
  const { devices } = watch();

  const onSave = async (data) => {
    prepaidStore.onSelectConnectDevice(item, data);
    poolPlanStore.onNestedSelectDevice(item, data.devices);
    onToggle();
  };

  return (
    <Modal size="md" containerClass="bg-black" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalHeader onToggle={onToggle}></ModalHeader>
        <div className="px-8 text-white">
          <h3 className="modal-title">Remaining eSIM Quota</h3>
          <div className="modal-react-table pt-16">
            <table className="w-full mb-12">
              <thead>
                <tr className="border-b border-solid border-primary">
                  <td className="py-2" style={{ width: '60%' }}>
                    <div className="flex items-center font-bold text-primary">
                      <input type="checkbox" name="devices" ref={register({ required: true })} value={item.sn} />
                      <DeviceIcon />
                      Device - {item.sn}
                    </div>
                  </td>
                  {item?.usages?.length > 0 && (
                    <>
                      <td style={{ width: '20%' }}>
                        <div className="flex flex-col items-start">
                          <div>Remaining Quota</div>
                          <div>
                            {deviceDataRegion === 'us'
                              ? '(NA & EU eSIM Data)'
                              : deviceDataRegion === 'global'
                              ? '(Global eSIM Data)'
                              : ''}
                          </div>
                        </div>
                      </td>
                      <td style={{ width: '20%' }}>Expiry Date</td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {item?.usages?.length > 0 ? (
                  <>
                    {item?.usages?.map((data, index) => (
                      <tr key={index} className="font-light border-b border-gray-700 border-solid">
                        <td className="flex items-center py-2 pl-16" style={{ width: '60%' }}>
                          <div className="icons icon-sim-tag" />
                          {data?.iccid || '-'}
                        </td>
                        <td style={{ width: '20%' }}>
                          {quotaGBSizeFromKB(data?.freeKb).resultNum} {quotaGBSizeFromKB(data?.freeKb).resultDim}
                        </td>
                        <td style={{ width: '20%' }}>{formatDate(item?.expiresAt)}</td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr className="border-b border-gray-700 border-solid">
                      <td className="w-full px-8 py-3 font-light text-center text-gray-400">No eSIM Quota</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {item?.modules?.map((i, idx) => (
              <div key={idx}>
                <div className="w-full mb-12">
                  <div>
                    <div className="flex items-center w-full border-b border-solid border-primary">
                      <div className="py-2" style={{ width: '60%' }}>
                        <div className="flex items-center font-bold text-primary">
                          <input type="checkbox" name="devices" ref={register({ required: true })} value={i.sn} />{' '}
                          <i className="mx-4 text-xl material-icons-outlined">add_box</i>Expansion Module - {i.sn}
                        </div>
                      </div>
                      {i?.usages?.length > 0 && (
                        <>
                          <div className="flex flex-col items-start" style={{ width: '20%' }}>
                            <div>Remaining Quota</div>
                            <div>
                              {findDataRegion(i.iccid_details) === 'us'
                                ? '(NA & EU eSIM Data)'
                                : findDataRegion(i.iccid_details) === 'global'
                                ? '(Global eSIM Data)'
                                : ''}
                            </div>
                          </div>
                          <div style={{ width: '20%' }}>Expiry Date</div>
                        </>
                      )}
                    </div>
                  </div>

                  <div>
                    {i?.usages?.length > 0 ? (
                      <>
                        {i?.usages?.map((data, index) => (
                          <div
                            key={index}
                            className="flex items-center font-light border-b border-gray-700 border-solid"
                          >
                            <div className="flex items-center px-8 py-2 pl-16" style={{ width: '60%' }}>
                              <div className="icons icon-sim-tag" /> {data?.iccid}
                            </div>
                            <div style={{ width: '20%' }}>
                              {quotaGBSizeFromKB(data?.freeKb).resultNum} {quotaGBSizeFromKB(data?.freeKb).resultDim}
                            </div>
                            <div style={{ width: '20%' }}>{formatDate(i?.expiresAt)}</div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="border-b  border-gray-700 border-solid">
                          <div className="w-full px-8 py-3 font-normal text-center text-gray-400">No eSIM Quota</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <ModalFooter>
          <div className="flex items-center p-8">
            <div className="text-lg font-semibold text-primary">{devices?.length || 0} Serial Number</div>
            <button className="ml-6 uppercase btn btn-modal" disabled={isEmpty(devices)}>
              Select
            </button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const DeviceIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 19 19"
    style={{
      fill: '#FFB81C',
      height: 13,
      margin: '0 1rem',
    }}
  >
    <g>
      <path d="M19,19H0V8.9H19V19z M1.3,17.7h16.4v-7.6H1.3V17.7z" />
      <rect x="4.7" y="0.1" transform="matrix(0.9657 -0.2597 0.2597 0.9657 -1.072 1.5488)" width="1.2" height="9.5" />
      <rect x="8.9" y="4.2" transform="matrix(0.2594 -0.9658 0.9658 0.2594 5.4624 16.7847)" width="9.5" height="1.2" />
      <g>
        <rect x="6.8" y="13.3" width="1.7" height="1.2" />
        <rect x="3.3" y="13.3" width="1.7" height="1.2" />
        <rect x="14" y="13.3" width="1.7" height="1.2" />
        <rect x="10.4" y="13.3" width="1.7" height="1.2" />
      </g>
    </g>
  </svg>
);

export default inject(({ prepaidStore, poolPlanStore }) => ({ prepaidStore, poolPlanStore }))(
  observer(ExpansionModuleModal)
);
