import { dateFormat } from 'utils';
import { inject, observer } from 'mobx-react';
import { maybePluralize, quotaDimension } from 'utils';
import AddICCID from '../AddICCID';
import CreateTicket from 'components/common/CreateTicket';
import ExportCSV from 'components/Sim/UsageRecord/ExportCSV';
import Filter from './components/Filter';
import FilterMobile from 'components/common/FilterMobile';
import React from 'react';
import Refresh from 'components/Sim/UsageRecord/Refresh';
import useToggle from 'hooks/useToggle';
import View from './components/View';

const Grid = ({
  setAdvancedFilters,
  options,
  filters,
  filterDefaults,
  setFilters,
  postpaidStore,
  elemRef,
  handleFilters,
  items,
}) => {
  const { toggle, handleToggle } = useToggle({ mobileFilter: false, addICCID: false });
  const [totalUsage, setTotalUsage] = React.useState(0);
  const [filteredData, setFilteredData] = React.useState([]);

  const onAddICCID = () => {
    handleToggle({ addICCID: !toggle.addICCID });
  };

  React.useEffect(() => {
    setTotalUsage(
      filteredData
        .map((i) => i.original.usageMb)
        .filter((i) => i !== undefined)
        .reduce((a, c) => a + c, 0)
    );
  }, [filteredData]);

  return (
    <>
      <div className="flex">
        <div className="hidden mr-4 sidebar lg:block">
          <div className="sidebar-usage-sticky">
            <Filter
              handleFilters={handleFilters}
              setFilters={setFilters}
              filterDefaults={filterDefaults}
              setAdvancedFilters={setAdvancedFilters}
              filters={filters}
            />
            <CreateTicket className="mt-4 text-sm" />
            {!postpaidStore.items.isLoading && postpaidStore.iccid_cache_created && (
              <div className="last-update">
                Updated: {dateFormat(postpaidStore.iccid_cache_created, 'dd-MM-yyyy HH:mm')}
              </div>
            )}
          </div>
        </div>
        <div className="w-full">
          <div
            className="visible inline-block mt-4 cursor-pointer btn btn-compact-filter sidebar-title lg:hidden md:mt-0"
            onClick={() => handleToggle({ mobileFilter: !toggle.mobileFilter })}
          >
            <i className="mr-1 material-icons material-icons-outlined md-20">filter_list</i> Filter
          </div>
          <View
            tableRef={elemRef}
            setFilteredData={setFilteredData}
            defaultPageSize={options.defaultPageSize}
            items={items}
          >
            <div className="mb-3 font-semibold md:flex md:items-center md:flex-row">
              <div className="flex items-center mb-3 md:mb-0">
                Total: {postpaidStore.totalNumber} {maybePluralize(postpaidStore.totalNumber, 'SIM')}
                <span className="total-divider">|</span>
                {quotaDimension(totalUsage).quota + ' ' + quotaDimension(totalUsage).type} SIM/ eSIM Usage
              </div>
              <button
                className="flex items-center justify-center md:ml-2 btn btn-secondary"
                onClick={() => onAddICCID()}
              >
                <div className="icons icon-plus"></div>
                Add ICCID
              </button>
              <div className="flex items-center justify-between ml-auto">
                <Refresh />
                <ExportCSV />
              </div>
            </div>
          </View>
        </div>
      </div>
      {toggle.mobileFilter && (
        <FilterMobile
          onToggle={() => handleToggle({ mobileFilter: !toggle.mobileFilter })}
          onSave={() => handleToggle({ mobileFilter: !toggle.mobileFilter })}
        >
          <Filter
            handleFilters={handleFilters}
            setFilters={setFilters}
            filterDefaults={filterDefaults}
            setAdvancedFilters={setAdvancedFilters}
            filters={filters}
          />
        </FilterMobile>
      )}
      {toggle.addICCID && <AddICCID onToggle={() => handleToggle({ addICCID: !toggle.addICCID })} />}
    </>
  );
};

export default inject(({ postpaidStore }) => ({ postpaidStore }))(observer(Grid));
