import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import CreateTicket from 'components/common/CreateTicket';
import ExportCSV from '../components/ExportCSV';
import Filter from './components/Filter';
import FilterMobile from 'components/common/FilterMobile';
import Notification from 'components/common/notification';
import React from 'react';
import Refresh from '../components/Refresh';
import TopupButtons from '../components/TopupButtons';
import useToggle from 'hooks/useToggle';
import View from './components/View';

const Grid = ({
  data,
  items,
  filterDefaults,
  isLoading,
  options,
  prepaidStore,
  elemRef,
  handleFilters,
  setFilters,
  userStore,
}) => {
  const { toggle, handleToggle } = useToggle({ mobileFilter: false });
  const [setTotalNumber] = React.useState(0);

  return (
    <>
      <div className="grid-view">
        <div className="hidden sidebar lg:relative">
          <div className="absolute z-0 w-screen h-full bg-secondary"></div>
          <div className="absolute z-10 sidebar-postpaid-sticky">
            <Filter
              data={data}
              items={items}
              handleFilters={handleFilters}
              setFilters={setFilters}
              filterDefaults={filterDefaults}
              isLoading={isLoading}
            />
            <CreateTicket className="mt-4 text-sm" />
            {!isLoading && prepaidStore.DEVICE_CACHE_FORMATTED && (
              <div className="last-update">Updated: {prepaidStore.DEVICE_CACHE_FORMATTED}</div>
            )}
          </div>
        </div>
        <div className="relative z-10 w-full">
          <div className="flex flex-col justify-between pl-20 mb-0 md:flex-row">
            <Notification />
          </div>
          <div className="flex flex-col justify-between w-full md:flex-row">
            <div
              className="visible inline-block mb-4 cursor-pointer btn btn-compact-filter tab-filter-btn lg:hidden md:mt-0 md:mb-0"
              onClick={() => handleToggle({ mobileFilter: !toggle.mobileFilter })}
            >
              <i className="mr-1 material-icons material-icons-outlined md-20">filter_list</i> Filter
            </div>
            <div className="flex lg:hidden">
              <Refresh />
              <ExportCSV />
            </div>
          </div>
          <View
            defaultSelected={options.defaultSelected}
            items={items}
            onSelect={options.handleSelect}
            tableRef={elemRef}
            isLoading={isLoading}
            setTotalNumber={setTotalNumber}
            defaultPageSize={options.defaultPageSize}
          >
            <div className="items-center py-2 mb-3 md:flex">{isEmpty(userStore.viewAs) && <TopupButtons />}</div>
          </View>
        </div>
      </div>

      {toggle.mobileFilter && (
        <FilterMobile
          onToggle={() => handleToggle({ mobileFilter: !toggle.mobileFilter })}
          onSave={() => handleToggle({ mobileFilter: !toggle.mobileFilter })}
        >
          <Filter
            data={data}
            items={items}
            handleFilters={handleFilters}
            setFilters={setFilters}
            filterDefaults={filterDefaults}
            isLoading={isLoading}
          />
        </FilterMobile>
      )}
    </>
  );
};

export default inject(({ userStore, prepaidStore }) => ({ userStore, prepaidStore }))(observer(Grid));
