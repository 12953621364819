import { dateFormat, dayDifference } from 'utils';
import ConnectSelector from 'components/TopUp/TopUp/components/Connect/Selector';
import React from 'react';

export default ({ item }) => {
  return (
    <>
      {/* totalPeriodFreeKb === 0 */}
      <div className="sfc-card-title">
        <div className="flex items-center justify-between">
          <div className="flex items-center">eSIM Quota</div>
          <ConnectSelector item={item} />
        </div>
      </div>
      <div className="sfc-card-content">
        <div className="flex items-center">
          <i className="mr-2 icons icon-sim-data" />
          <div className="items-end cursor-pointer">
            <span className="remaining-sfc-usage-number sn-expire-text-gray">-</span>
          </div>
        </div>
        {item.expiresAt && dayDifference(item.expiresAt) >= 0 ? (
          <div className="flex items-center">
            <i className="mr-2 icons icon-sfc-expiry-usage-normal"></i>
            <span> {dateFormat(item.expiresAt)}</span>
          </div>
        ) : (
          <div className="flex items-center">
            <i className="icons icon-sfc-expiry-usage-disable" />
            <div className="ml-2 sn-expire-text-gray">-</div>
          </div>
        )}
      </div>
    </>
  );
};
