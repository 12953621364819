import { DEFAULT_PAGESIZE, DEFAULT_VISIBILITY } from 'definitions';
import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { isEmpty, isEqual } from 'lodash';
import cx from 'classnames';
import ExportCSV from './components/ExportCSV';
import Grid from './Grid';
import List from './List';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Refresh from './components/Refresh';
import ViewAs from 'components/common/ViewAs';

const Topup = ({ userStore, prepaidStore, poolPlanStore }) => {
  const filterDefaults = useCallback(() => {
    return { isDeviceHidden: DEFAULT_VISIBILITY };
  }, []);

  const [view, setView] = useState('GRID');
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState(filterDefaults());

  const ref = useRef(null);

  const onChangeTab = (tab) => {
    setFilters(filterDefaults());
    setView(tab);
  };

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const getData = useCallback(async () => {
    await prepaidStore.invalidateItems();
    await prepaidStore.getRoles(userStore.viewAs);
    await prepaidStore.getData(userStore.viewAs);
  }, [prepaidStore, userStore.viewAs]);

  const handleSelect = useCallback(
    (selection) => {
      if (!isEqual(selected, selection)) {
        const filtered = selection.filter((i) => !i.disabled);
        localStorage.setItem(prepaidStore.ORG_CODE, JSON.stringify(filtered));
        setSelected(filtered);
      }
    },
    [prepaidStore.ORG_CODE, selected]
  );

  const defaultSelected = useMemo(() => {
    const storage = JSON.parse(localStorage.getItem(prepaidStore.ORG_CODE)) || [];

    return view && storage.length
      ? storage.reduce((acc, val) => {
          return {
            ...acc,
            [prepaidStore.ITEMS.findIndex((i) => {
              return i.sn === val.sn;
            })]: true,
          };
        }, {})
      : null;
  }, [prepaidStore.ITEMS, prepaidStore.ORG_CODE, view]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (userStore.viewAs) {
      userStore.getOtherUserData();
    } else {
      userStore.me(true);
    }
  }, [userStore.viewAs, userStore]);

  useEffect(() => {
    ref.current &&
      ref.current.setAllFilters(
        Object.keys(filters)
          .map((i) => ({ id: i, value: filters[i] }))
          .filter((i) => i.value)
      );
  }, [filters]);

  const selectedOrg = React.useMemo(() => prepaidStore.ORG_CODE, [prepaidStore.ORG_CODE]);
  // Get pool plan device
  const getPoolData = React.useCallback(async () => {
    await poolPlanStore.getOrganizations();
    await poolPlanStore.setSelectedOrganization(selectedOrg);
    if (!isEmpty(selectedOrg)) await poolPlanStore.getPool();
  }, [poolPlanStore, selectedOrg]);

  React.useEffect(() => {
    getPoolData();
  }, [getPoolData]);

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <div className="sticky-postpaid">
        <div className="md:flex md:items-center md:justify-between">
          <h1 className="flex items-center py-4 font-medium ">
            <i className="mr-2 icons icon-topup" />
            SFC Management
          </h1>
          <ViewAs className="hidden md:flex" />
        </div>

        <div className="flex items-start mb-4">
          <div>
            <div className="w-full px-6 py-2 bg-white border-2 md:w-4/5 border-primary">
              <div className="flex gap-3">
                <i className="mt-2 icons icon-sfc-reminder" />
                <div>
                  Enjoy speeds of <span className="font-semibold text-primary_dark"> up to 200Mbps</span> with
                  SpeedFusion Connect Usage Plan. Stay topped up and make sure your quota doesn’t run out. If your quota
                  runs out, devices that are updated to <span className="font-semibold">firmware 8.3.0</span> and have a{' '}
                  <span className="font-semibold">valid care plan</span> can{' '}
                  <span className="font-semibold text-primary_dark">stay connected</span> with speeds of{' '}
                  <span className="font-semibold text-primary_dark">up to 10Mbps.</span>
                </div>
              </div>
            </div>
            <ViewAs className="flex mt-2 md:hidden" />
          </div>
          <div className="flex flex-col justify-between">
            <div className="hidden mb-5 ml-auto xl:flex">
              <button
                id="grid-view"
                className={cx('btn btn-icon btn-view ml-auto', { 'text-primary_dark': view === 'GRID' })}
                onClick={() => onChangeTab('GRID')}
              >
                <i className="mr-1 text-2xl material-icons">apps</i> Grid
              </button>
              <button
                id="list-view"
                className={cx('btn btn-icon btn-view ml-4', { 'text-primary_dark': view === 'LIST' })}
                onClick={() => onChangeTab('LIST')}
              >
                <i className="mr-1 text-2xl material-icons">view_list</i> List
              </button>
            </div>
            <div className="items-center hidden w-full lg:flex lg:justify-end">
              <Refresh />
              <ExportCSV />
            </div>
          </div>
        </div>
      </div>

      {view === 'GRID' && (
        <Grid
          elemRef={ref}
          filters={filters}
          setFilters={setFilters}
          handleFilters={handleFilters}
          filterDefaults={filterDefaults}
          data={prepaidStore.DATA}
          items={prepaidStore.ITEMS}
          isLoading={prepaidStore.items.isLoading}
          options={{
            defaultPageSize: DEFAULT_PAGESIZE,
            defaultSelected,
            handleSelect,
            selected,
          }}
        />
      )}

      {view === 'LIST' && (
        <List
          elemRef={ref}
          filters={filters}
          setFilters={setFilters}
          handleFilters={handleFilters}
          filterDefaults={filterDefaults}
          data={prepaidStore.DATA}
          items={prepaidStore.ITEMS}
          isLoading={prepaidStore.items.isLoading}
          options={{
            defaultPageSize: DEFAULT_PAGESIZE,
            defaultSelected,
            handleSelect,
            selected,
          }}
        />
      )}
    </>
  );
};

export default inject(({ userStore, prepaidStore, poolPlanStore }) => ({ userStore, prepaidStore, poolPlanStore }))(
  observer(Topup)
);
