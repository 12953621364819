import ConnectSelector from 'components/TopUp/TopUp/components/Connect/Selector';
import React from 'react';

export default ({ item }) => {
  return (
    <div className="flex items-center">
      <ConnectSelector item={item} />
      <div className="flex items-center ml-4">
        <i className="mr-2 icons icon-sim-data" />
        <div className="items-end cursor-pointer">
          <span className="remaining-sfc-usage-number sn-expire-text-gray">-</span>
        </div>
      </div>
    </div>
  );
};
