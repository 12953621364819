import { includes, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { snLink } from 'utils';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

const Header = ({ children, item, userStore }) => {
  const onSetDeviceVisibility = async () => {
    await userStore.updateHiddenDevices({ type: 'prepaid', value: item.sn });
  };

  return (
    <div className="flex items-end mb-5">
      <div className="icons icon_card_router" />
      {item.sn && (
        <div className="flex pb-1">
          <Link to={{ pathname: snLink(item) }} className="redirect-sn" target="_blank">
            <div className="product-sn hover:text-primary mx-1 mt-2">{item.sn}</div>
          </Link>
          <Link to={{ pathname: snLink(item) }} target="_blank">
            <div className="cursor-pointer icons icon-ic2-link mx-1 mt-1" />
          </Link>
        </div>
      )}
      {item.modules && item.modules.length > 0 && (
        <div className="px-2 py-1 mx-1 cursor-pointer rounded-md bg-primary_light text-primary">
          <Tooltip
            placement="bottom"
            trigger="hover"
            tooltip={
              <div>
                {item.modules.map((module, index) => (
                  <div key={index} className="flex items-center mx-1">
                    <div className="text-white">{module.sn}</div>
                  </div>
                ))}
              </div>
            }
          >
            <div className="flex items-center ml-auto">
              <i className="icons icon-extent-modules" />
              <span> {item.modules.length}</span>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="flex items-center pb-1 ml-auto">
        {isEmpty(userStore.viewAs) && (
          <span
            className="text-2xl text-gray-500 cursor-pointer material-icons-outlined"
            onClick={onSetDeviceVisibility}
          >
            {includes(userStore.hiddenDevices.prepaid, item.sn) ? 'visibility' : 'visibility_off'}
          </span>
        )}
        {children}
      </div>
    </div>
  );
};

export default inject(({ userStore }) => ({ userStore }))(observer(Header));
