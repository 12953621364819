import { encodedEmail } from 'utils';
import { isEmpty } from 'lodash';

export default class PostpaidServices {
  constructor(props) {
    this.url = '/sim';
    this.http = props.http;
  }

  refresh = (payload, email) =>
    this.http.post(
      !isEmpty(email) ? `${this.url}/clearCache?email=${encodedEmail(email)}` : `${this.url}/clearCache`,
      payload
    );

  refreshPartnerICCID = (payload, email) =>
    this.http.post(
      !isEmpty(email)
        ? `${this.url}/clearPartnerICCIDCache?email=${encodedEmail(email)}`
        : `${this.url}/clearPartnerICCIDCache`,
      payload
    );

  getData = (payload, email) =>
    this.http.get(
      !isEmpty(email) ? `${this.url}/postpaid?email=${encodedEmail(email)}` : `${this.url}/postpaid`,
      payload
    );

  download = (email, payload) =>
    this.http.getBlob(
      !isEmpty(email) ? `${this.url}/postpaidReport?email=${encodedEmail(email)}` : `${this.url}/postpaidReport`,
      payload
    );

  getStatus = () => this.http.get(`${this.url}/postpaidAllStatus`);

  resume = (iccid) => this.http.post(`${this.url}/postpaid/${iccid}/resume`);
  suspend = (iccid) => this.http.post(`${this.url}/postpaid/${iccid}/suspend`);
  status = (iccid) => this.http.get(`${this.url}/postpaid/${iccid}/status`);
  getIMEIHistory = (iccid) => this.http.get(`${this.url}/postpaid/${iccid}/imeihistory`);
  setPrepaidToPostpaid = (payload, email) =>
    this.http.post(
      !isEmpty(email)
        ? `${this.url}/postpaid/prepaidToPostpaid?email=${encodedEmail(email)}`
        : `${this.url}/postpaid/prepaidToPostpaid`,
      payload
    );
}
