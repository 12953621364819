import { debounce } from 'lodash';
import NumberInput from 'components/common/ReactTable/controls/NumberInput';
import React, { useEffect, useRef, useState } from 'react';

const TableFooter = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
  pageSize,
  totalRow,
}) => {
  const [page, setPage] = useState(0);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);

  const debounceRef = useRef(
    debounce((newValue) => {
      gotoPage(newValue);
    }, 500)
  );

  useEffect(() => {
    setPage(pageIndex || 0);
    setFrom(pageIndex * pageSize + 1);
    setTo((pageIndex + 1) * pageSize > totalRow ? totalRow : (pageIndex + 1) * pageSize);
  }, [pageIndex, pageSize, totalRow]);

  useEffect(() => {
    debounceRef.current(page);
  }, [page]);

  return (
    <div className="pool-plan-management-table-footer">
      <div>{`${from}-${to} of ${totalRow} record(s)`}</div>
      <div className="flex items-center">
        <div className="page-item">
          <button
            className="page-link material-icons md-dark md-24"
            onClick={() => {
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            keyboard_arrow_left
          </button>
        </div>
        <form className="pagination">
          <NumberInput
            value={page + 1}
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                const page = value >= 1 ? Number(value) - 1 : 0;
                setPage(page < pageCount ? page : pageCount - 1);
              }
            }}
          />
          <span className="mx-2">of</span>
          {pageCount}
        </form>
        <div className="page-item">
          <button
            className="page-link material-icons md-dark md-24"
            onClick={() => {
              nextPage();
            }}
            disabled={!canNextPage}
          >
            keyboard_arrow_right
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
