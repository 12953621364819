import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'definitions';
import Bulk from './Bulk';
import React from 'react';
import TopUp from './TopUp';

export default () => (
  <Switch>
    <Route path={`/:root${ROUTES.TOPUP_BULK}`} component={Bulk} />
    <Route path="/:root" component={TopUp} />
  </Switch>
);
