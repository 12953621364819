import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Modal, ModalHeader } from 'components/common/Modal';
import { useForm } from 'react-hook-form';
import Loading from 'components/common/Loading';
import React from 'react';

const ConfirmPurchaseModal = ({ onToggle, saStore, prepaidStore }) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  const autoRenewalSNs = React.useMemo(
    () => saStore.sfcPlanDetails?.filter((plan) => plan.activeAutoRenewal).map((plan) => plan.serialNumber),
    [saStore.sfcPlanDetails]
  );

  const tableData = React.useMemo(
    () => saStore.sfcPlanDetails?.map((i) => ({ sn: i.serialNumber, expiry: i.expirationDate })),
    [saStore.sfcPlanDetails]
  );

  const isLoading = React.useMemo(() => saStore.isLoading, [saStore.isLoading]);

  const onSubmit = () => {
    saStore
      .post(true)
      .then(() => {
        window.location.href = process.env.REACT_APP_ESTORE_CART;
        onToggle();
      })
      .catch((err) => {
        prepaidStore.setServerErrors(err.errors.map((i) => i.message));
        onToggle();
      });
  };

  return (
    <Modal containerClass="bg-black with-scroll" onToggle={onToggle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader onToggle={onToggle}></ModalHeader>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div className="px-8 text-white">
              <p>
                Upon purchasing the Unlimited Plan, any remaining SpeedFusion Connect Usage data from the original plan
                will be overwritten by the unlimited data.
              </p>
              {!isEmpty(tableData) && (
                <div className="my-8">
                  <table className="table w-full simple-responsive data-plans">
                    <thead>
                      <tr>
                        <th scope="col" className="text-xl text-left">
                          Serial Number
                        </th>
                        <th scope="col" className="text-xl text-left">
                          Expiration Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="mb-6">
                      {tableData.map((item, index) => (
                        <tr key={index} className="border-b border-gray-400 border-solid">
                          <td data-label="Serial Number" className="text-lg">
                            {item.sn}
                          </td>
                          <td data-label="Expiration Date" className="text-lg">
                            {item.expiry}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <p>
                {!isEmpty(autoRenewalSNs) && (
                  <>
                    Please note that the auto-renewal setting for your {autoRenewalSNs.length > 1 ? 'SNs' : 'SN'} :{' '}
                    <span className="text-primary">{autoRenewalSNs.join(', ')}</span> will also be turned off.
                  </>
                )}
              </p>
            </div>
            <div className="flex items-center justify-end mr-6">
              <div className="p-6">
                <button className="ml-auto btn btn-modal" onClick={onToggle} type="button">
                  Cancel
                </button>
                <button onClick={onSubmit} className="ml-2 btn btn-modal" type="submit">
                  Proceed
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export default inject(({ saStore, prepaidStore }) => ({ saStore, prepaidStore }))(observer(ConfirmPurchaseModal));
