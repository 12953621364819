import BaseServices from './base';

export default class MeServices extends BaseServices {
  constructor(props) {
    super(props);
    this.url = '/me';
    this.http = props.http;
  }
  put = (payload) => this.http.put(`${this.url}`, payload);
  updateHiddenDevices = (payload) => this.http.put(`${this.url}/hidden-device`, payload);
}
