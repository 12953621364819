import { isEmpty } from 'lodash';
import cx from 'classnames';
import NoResultsFound from 'components/common/NoResultsFound';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default React.memo(
  ({
    children,
    defaultOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    rows,
  }) => {
    const n = defaultOptions.totalColumns - defaultOptions.hiddenColumns.length;
    const { onRowClick, onSelectionChanged } = defaultOptions;

    React.useEffect(() => {
      onSelectionChanged &&
        onSelectionChanged(selectedFlatRows.filter((i) => !i.original.disabled).map(({ original }) => original));
    }, [onSelectionChanged, selectedFlatRows]);

    return (
      <>
        {!isEmpty(rows) && (
          <table {...getTableProps()} className={cx('table w-full', defaultOptions.tableClassName)}>
            <thead>
              {headerGroups.map((headerGroup, key) => (
                <tr
                  key={`tr-header-${key}`}
                  {...headerGroup.getHeaderGroupProps()}
                  className="p-5 border-b border-primary"
                >
                  {headerGroup.headers.map((column, key) => (
                    <th
                      key={`th-header-${key}`}
                      {...column.getHeaderProps([
                        {
                          className: cx({ collapsed: column.id === 'check_box' }, column.className),
                          style: column.style,
                          // width: `${column.width}%`,
                        },
                      ])}
                    >
                      <span
                        {...column.getSortByToggleProps()}
                        className={cx({ 'cursor-pointer': column.accessor && column.sortable && column.canSort })}
                      >
                        {column.render('Header')}
                        {column.id !== 'check_box' && column.sortable && (
                          <span className="material-icons md-18">
                            {column.isSorted ? (column.isSortedDesc ? 'arrow_drop_down' : 'arrow_drop_up') : ''}
                          </span>
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, key) => {
                prepareRow(row);

                return (
                  <tr
                    key={`tr-body-${key}`}
                    {...row.getRowProps()}
                    onClick={(e) => {
                      if (!e.target.closest('.no-row-click')) {
                        onRowClick && onRowClick(row);
                      }
                    }}
                    className={cx({ 'cursor-pointer': onRowClick })}
                  >
                    {defaultOptions.isLoading
                      ? [...Array(n)].map((i, index) => (
                          <td key={`td-body-${index}`}>
                            <div className="pt-6 pb-5">
                              <Skeleton width={100} />
                            </div>
                          </td>
                        ))
                      : row.cells.map((cell, key) => {
                          return (
                            <td
                              key={`td-body-${key}`}
                              {...cell.getCellProps([
                                {
                                  className: cell.column.className
                                    ? cell.column.className + ' p-5 border-b border-gray-400'
                                    : 'p-5 border-b border-gray-400',
                                  style: cell.column.style,
                                },
                              ])}
                            >
                              {cell.isGrouped ? (
                                // If it's a grouped cell, add an expander and row count
                                <>
                                  <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? '👇' : '👉'}</span>{' '}
                                  {cell.render('Cell', { editable: false })} ({row.subRows.length})
                                </>
                              ) : cell.isAggregated ? (
                                // If the cell is aggregated, use the Aggregated
                                // renderer for cell
                                cell.render('Aggregated')
                              ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                // Otherwise, just render the regular cell
                                cell.render('Cell', { editable: true })
                              )}
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
              {children}
            </tbody>
          </table>
        )}
        {!rows.length && !defaultOptions.isLoading && <NoResultsFound className="p-4" />}
      </>
    );
  }
);
