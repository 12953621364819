import { dateFormat, snLink } from 'utils';
import { DEFAULT_SN } from 'definitions';
import React from 'react';

export default ({ item }) => {
  return (
    <>
      <div className="py-4">
        <div>
          <p className="mb-2 text-sm font-semibold">Device</p>
          {item.product && <div className="mb-2 product-name">{item.product.name}</div>}
          <div className="flex items-center py-2">
            <span className="mr-2 text-lg font-medium">{item.sn}</span>
            {item.sn !== DEFAULT_SN && (
              <a href={snLink(item)} target="_blank" rel="noopener noreferrer" className="no-underline">
                <i className="icons icon-sn-link"></i>
              </a>
            )}
          </div>

          {item.name && <div className="mb-2 device-name">{item.name}</div>}
          {(item.fw || item.fw_ver) && (
            <>
              {parseFloat(item.fw || item.fw_ver) < 8.1 && item.support_sfc === true ? (
                <div className="flex justify-between text-danger">
                  <div className="flex items-center cursor-pointer ">
                    {'Firmware ' + (item.fw || item.fw_ver).substring(0, 5)}
                  </div>
                  <div>
                    <span className="mr-1 material-icons icon-firmware-error md-18">error_outline</span>
                    8.1.0 Required
                  </div>
                </div>
              ) : (
                <div className="firmware">{'Firmware ' + (item.fw || item.fw_ver).substring(0, 5)}</div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex items-center footnote-firmware-lastseen">
        <div className="flex ml-auto text-center firmware">
          Last Online:
          {item.last_seen ? ` ${dateFormat(item.last_seen, 'd MMM yyyy HH:mm')}` : ' -'}
        </div>
      </div>
    </>
  );
};
