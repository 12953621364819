import { inject, observer } from 'mobx-react';
import { Modal, ModalHeader } from 'components/common/Modal';
import { toast } from 'react-toastify';
import Loading from 'components/common/Loading';
import React, { useEffect, useState } from 'react';
import Select from 'components/common/Select';

// options
const AutoTopUpOptions = [
  { name: '500 MB', val: 512000 },
  { name: '1 GB', val: 1048576 },
  { name: '2 GB', val: 2097152 },
  { name: '2.5 GB', val: 2621440 },
  { name: '3 GB', val: 3145728 },
  { name: '3.5 GB', val: 3670016 },
  { name: '4 GB', val: 4194304 },
  { name: '4.5 GB', val: 4718592 },
  { name: '5 GB', val: 5242880 },
  { name: '10 GB', val: 10485760 },
  { name: '20 GB', val: 20971520 },
];

const ThresholdOptions = [
  { name: '100 MB', val: 102400 },
  { name: '200 MB', val: 204800 },
  { name: '300 MB', val: 307200 },
  { name: '400 MB', val: 409600 },
  { name: '500 MB', val: 512000 },
  { name: '600 MB', val: 614400 },
  { name: '700 MB', val: 716800 },
  { name: '800 MB', val: 819200 },
  { name: '900 MB', val: 921600 },
  { name: '1 GB', val: 1048576 },
  { name: '5 GB', val: 5242880 },
  { name: '10 GB', val: 10485760 },
];

const EditPooledSetting = ({ onToggle, poolPlanStore }) => {
  const data = React.useMemo(() => poolPlanStore.poolPlan.item, [poolPlanStore.poolPlan]);
  const [autoTopupAmountKb, setAutoTopupAmountKb] = useState();
  const [thresholdTopupKb, setThresholdTopupKb] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    const response = await poolPlanStore.updatePooledSetting({ autoTopupAmountKb, thresholdTopupKb });

    !response.error
      ? toast.success(`Your top up settings are updated.`)
      : toast.error(`We failed to update your top up settings. Please try again.`);

    onToggle();
    setLoading(false);
  };

  useEffect(() => {
    if (data?.autoTopupAmountKb && data?.thresholdTopupKb) {
      setAutoTopupAmountKb(data?.autoTopupAmountKb);
      setThresholdTopupKb(data?.thresholdTopupKb);
    }
  }, [data]);

  return (
    <>
      <Modal size="sm" containerClass="bg-black" onToggle={onToggle}>
        {data?.autoTopupAmountKb && data?.thresholdTopupKb && !loading ? (
          <>
            <ModalHeader onToggle={onToggle} classNames={{ modal: 'px-6 pt-5', closeBtn: 'px-6 pt-5' }}>
              <h3 className="add-modal-title">Top Up Settings</h3>
            </ModalHeader>
            <div className="px-12 edit-pooled-modal">
              {/* autoTopupAmountKb */}
              <div className="item">
                <h4 className="title">Auto Top Up Amount</h4>
                <div className="w-1/3">
                  <Select
                    options={AutoTopUpOptions}
                    onChange={(item) => setAutoTopupAmountKb(item.val)}
                    defaultValue={AutoTopUpOptions.filter((item) => item.val === data?.autoTopupAmountKb)[0]}
                    labelClassName="text-sm font-medium text-white mb-1"
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.val}
                  />
                </div>
              </div>
              {/* thresholdTopupKb */}
              <div className="item">
                <h4 className="title">Trigger Auto Top Up when ICCID remaining quota is below</h4>
                <div className="w-1/3">
                  <Select
                    options={ThresholdOptions}
                    onChange={(item) => setThresholdTopupKb(item.val)}
                    defaultValue={ThresholdOptions.filter((item) => item.val === data?.thresholdTopupKb)[0]}
                    labelClassName="text-sm font-medium text-white mb-1"
                    getOptionLabel={(item) => item.name}
                    getOptionValue={(item) => item.val}
                  />
                </div>
              </div>
              <div className="btn-bar">
                <button className="btn btn-outline-primary" onClick={onToggle} disabled={loading}>
                  Cancel
                </button>
                <button className="btn btn-outline-primary" onClick={onSubmit} disabled={loading}>
                  Update
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <ModalHeader onToggle={onToggle} classNames={{ modal: 'px-6 pt-5', closeBtn: 'px-6 pt-20' }}></ModalHeader>
            <Loading />
          </>
        )}
      </Modal>
    </>
  );
};

export default inject(({ poolPlanStore }) => ({ poolPlanStore }))(observer(EditPooledSetting));
