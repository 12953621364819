import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import React from 'react';
import Tooltip from 'components/common/Tooltip';

export const handleRefresh = async (postpaidStore, userStore) => {
  await postpaidStore.invalidateItems();
  await postpaidStore.refreshPartnerICCID({}, userStore.viewAs);
  await postpaidStore.refresh({}, userStore.viewAs);
  await postpaidStore.getData(userStore.viewAs);
};

const Refresh = ({ postpaidStore, userStore }) => {
  return (
    <Tooltip
      placement="top"
      trigger="hover"
      tooltip={<div className="tooltip-refresh">Refresh to view the newly added devices</div>}
    >
      <button
        className={cx('btn btn-refresh flex items-center bg-white text-primary', {
          'cursor-not-allowed': postpaidStore.items.isLoading,
        })}
        disabled={postpaidStore.items.isLoading}
        onClick={() => handleRefresh(postpaidStore, userStore)}
      >
        <i className="mr-1 material-icons md-22">sync</i>
        <span className="lg:block xs:hidden">Refresh Devices</span>
      </button>
    </Tooltip>
  );
};

export default inject(({ postpaidStore, userStore }) => ({ postpaidStore, userStore }))(observer(Refresh));
