import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Checkbox from 'components/common/form/Checkbox';
import React from 'react';

const NoSFWanLicense = ({ item, prepaidStore, userStore }) => {
  return (
    <>
      {/* sfwan_license === null */}
      <div className="flex items-center justify-between sfc-card-title">
        <div className="flex items-center">SFC Usage Quota</div>
        {isEmpty(userStore.viewAs) && (
          <Checkbox
            onChange={() => prepaidStore.onSelectCloudDevice(item)}
            checked={prepaidStore.isCloudDeviceSelected(item)}
          />
        )}
      </div>
      <div className="sfc-card-content">
        <div className="flex items-end">
          <i className="mb-2 mr-2 icons icon-5g-plan-dimmed"></i>
          <span className="mt-2 remaining-sfc-usage-number sn-expire-text-gray">0</span>
          <span className="mt-2 remaining-sfc-usage-type sn-expire-text-gray">MB</span>
        </div>
        {item.service_exp && (
          <div className="flex items-center">
            <i className="mr-2 icons icon-sfc-expiry-usage-disable" />
            <div className="sn-expire-text-gray">-</div>
          </div>
        )}
      </div>
    </>
  );
};

export default inject(({ prepaidStore, userStore }) => ({ prepaidStore, userStore }))(observer(NoSFWanLicense));
