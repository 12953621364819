import { Helmet } from 'react-helmet';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import BulkConnectTopUpModal from '../components/BulkConnectTopUpModal';
import BulkEsimTopUpModal from '../components/BulkEsimTopUpModal';
import ConfirmModal from '../components/ConfirmModal';
import ConfirmPurchaseModal from '../TopUp/components/ConfirmPurchaseModal';
import cx from 'classnames';
import DownloadErrorLog from '../../TopUp/TopUp/components/DownloadErrorLog';
import FileUpload from 'components/common/FileUpload';
import parse from 'react-html-parser';
import React from 'react';
import useToggle from 'hooks/useToggle';
import XLSX from 'xlsx';

const Bulk = ({ userStore, prepaidStore }) => {
  const { toggle, handleToggle } = useToggle({
    connectTopUpModal: false,
    eSimTopUpModal: false,
    confirmModal: false,
    confirmUnlimitedPurchase: false,
  });
  const [activeTab, setActiveTab] = React.useState('MANUAL');
  const [serverErrors, setServerErrors] = React.useState([]);
  const [errorSN, setErrorSN] = React.useState([]);
  const [file, setFile] = React.useState(undefined);
  const methods = useForm();
  const [selected, setSelected] = React.useState([]);
  const { register, reset, watch } = methods;
  const { sns } = watch();

  React.useEffect(() => {
    if (serverErrors.length) {
      const regex = /\[(.*?)\]/g;
      let match = regex.exec(serverErrors[0]);
      setErrorSN(match[1].split(','));
    }
  }, [serverErrors]);

  const onChangeTab = (tab) => {
    handleReset();
    setActiveTab(tab);
  };

  const download = (filename, text) => {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleUpload = async (values, cb) => {
    var isInvalidFile = false;
    let formAttachment = new FormData();
    Object.keys(values.files).forEach((key) => {
      if (
        //check by type
        // values.files.item(key).type !== 'application/vnd.ms-excel' &&
        // values.files.item(key).type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        //check by extension
        values.files[key].name.split('.').pop() !== 'csv' &&
        values.files[key].name.split('.').pop() !== 'xls' &&
        values.files[key].name.split('.').pop() !== 'xlsx'
      ) {
        isInvalidFile = true;
        alert('Please upload .CSV or .XLS file');
      } else if (Math.round(values.files.item(key).size / 1024) > 3000) {
        // Empty
      } else {
        formAttachment.append('file', values.files[key]);
        cb(values.files[key].name);

        var files = values.files,
          f = files[0];
        var reader = new FileReader();

        reader.onload = function (e) {
          var data = e.target.result;
          let readedData = XLSX.read(data, { type: 'binary' });
          const wsname = readedData.SheetNames[0];
          const ws = readedData.Sheets[wsname];

          /* Convert array to json*/
          const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
          let arr = [];
          dataParse.map((i) => i[0] !== 'SNS' && arr.push({ sn: i[0] }));

          setSelected(arr);
        };
        reader.readAsBinaryString(f);
      }
    });

    if (isInvalidFile) setFile(undefined);
    else setFile(formAttachment);
  };

  const handleReset = () => {
    setSelected([]);
    setServerErrors([]);
    setFile(undefined);
    reset({});
  };

  const handleSubmit = (usageSource) => {
    if (activeTab === 'MANUAL') {
      let snsString = sns
        .replace(/[, ]+/g, ' ')
        .trim()
        .replace(/(\r\n|\n|\r|\t)/gm, ' ')
        .trim()
        .replace(/\\|\//g, '')
        .trim()
        .replace(/\s+/g, ' ')
        .split(' ');
      setSelected(snsString.map((i) => ({ sn: i })));
    }
    userStore.SA_TOKEN && usageSource === 'sfc'
      ? handleToggle({ connectTopUpModal: !toggle.connectTopUpModal })
      : userStore.SA_TOKEN && usageSource === 'esim'
      ? handleToggle({ eSimTopUpModal: !toggle.eSimTopUpModal })
      : handleToggle({ confirmModal: !toggle.confirmModal });
  };

  // const disableBody = (target) => disableBodyScroll(target);
  // const enableBody = (target) => enableBodyScroll(target);

  // const onCloseHelp = async () => {
  //   let SF_CLOUD = localStorage.getItem('SF_CLOUD');
  //   const data = JSON.parse(SF_CLOUD);
  //   localStorage.setItem('SF_CLOUD', JSON.stringify({ ...data, isNew: 'false' }));
  //   tour.setShow(false);
  // };

  React.useEffect(() => {
    if (isEmpty(prepaidStore.DATA)) {
      prepaidStore.getData();
    }
  }, [prepaidStore]);

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <h1 className="flex items-center py-4 font-medium">
        <i className="mr-2 icons icon-topup" />
        Bulk Top Up
      </h1>
      <p className="mb-6 font-medium">
        <b>Note:</b> All devices must be updated to Firmware 8.1.0 or higher to support SpeedFusion Cloud
      </p>
      <div className="mb-12 bulk-upload-description">
        <p className="font-semibold underline">Enter Serial Number(s)</p>
        <p className="font-medium">Here are two ways you can enter your serial numbers.</p>
        <div className=" font-medium leading-0">
          <ul>
            <li>• Manual submission separated by commas</li>
            <li>• Upload via excel or csv format</li>
          </ul>
        </div>
        {!isEmpty(prepaidStore.serverErrors) &&
          prepaidStore.serverErrors?.map((error, index) => {
            return (
              <div
                className="flex items-center p-4 mt-8 rounded text-custom-warning bg-custom-warning bg-opacity-50"
                key={index}
              >
                <i className="mr-2 text-lg material-icons">error_outline</i>
                <div>
                  <div> {parse(error)} </div>
                  <DownloadErrorLog serverError={error} />
                </div>
              </div>
            );
          })}
      </div>
      <button
        className={cx('tab-form', {
          'bg-white': activeTab !== 'MANUAL',
          'bg-primary': activeTab === 'MANUAL',
        })}
        onClick={() => onChangeTab('MANUAL')}
      >
        Input Serial Number(s)
      </button>
      <button
        className={cx('tab-form', {
          'bg-white': activeTab !== 'CSV',
          'bg-primary': activeTab === 'CSV',
        })}
        onClick={() => onChangeTab('CSV')}
      >
        Excel / CSV Upload
      </button>
      <hr className="m-0 border-primary" />
      <div className="relative mb-4 form-group">
        <>
          {activeTab === 'MANUAL' && (
            <textarea name="sns" rows="10" ref={register({ required: true })} className="w-full p-4 mb-2" />
          )}
          {activeTab === 'CSV' && <FileUpload name="csv" className="w-2/3 mt-8 md:w-1/2" onFileSet={handleUpload} />}
        </>
      </div>
      {!!serverErrors.length &&
        (errorSN.length > 1 ? (
          <div className="text-danger">
            <i className="text-lg material-icons">error_outline</i> There are errors on the serial numbers. Please check
            the
            <button
              id="download-log"
              className="px-1 underline cursor-pointer"
              onClick={() => download('log.txt', Array.isArray(serverErrors) ? serverErrors.join('\n') : serverErrors)}
            >
              log
            </button>
            for details.
          </div>
        ) : (
          <div className="text-danger">
            <i className="text-lg material-icons">error_outline</i> Serial number is invalid or does not match the
            product, please verify ({errorSN})
          </div>
        ))}
      <div className="flex justify-between">
        {activeTab === 'MANUAL' && (
          <div className="mb-24">
            <div>
              <button
                disabled={!sns}
                sid="input-sn-reset"
                onClick={handleReset}
                className="btn btn-outline btn-reset btn-lg"
              >
                Reset
              </button>
            </div>
          </div>
        )}

        <div className="ml-auto">
          <button
            disabled={!sns && !file}
            id="submit-sn"
            className="w-32 h-16 px-2 py-2 font-bold bg-black md:px-4 md:mr-4 text-primary"
            onClick={() => handleSubmit('sfc')}
          >
            <i className="md:mr-2 material-icons">cloud</i>
            <span className="text-base">SFC</span>
          </button>
          <button
            disabled={!sns && !file}
            id="submit-sn"
            // className="ml-4 btn btn-lg btn-secondary btn-submit"
            className="w-32 h-16 px-2 py-2 font-bold bg-black md:px-4 md:mr-4 text-primary"
            onClick={() => handleSubmit('esim')}
          >
            <i className="md:mr-2 material-icons">sim_card</i>
            <span className="text-base">{`eSIM`}</span>
          </button>
        </div>
      </div>
      {toggle.confirmModal && <ConfirmModal onToggle={() => handleToggle({ confirmModal: !toggle.confirmModal })} />}
      {toggle.connectTopUpModal && (
        <BulkConnectTopUpModal
          onToggle={() => handleToggle({ connectTopUpModal: !toggle.connectTopUpModal })}
          setServerErrors={setServerErrors}
          selected={selected}
          onToggleConfirmPurchaseModal={() =>
            handleToggle({ confirmUnlimitedPurchase: !toggle.confirmUnlimitedPurchase })
          }
        />
      )}
      {toggle.eSimTopUpModal && (
        <BulkEsimTopUpModal
          onToggle={() => handleToggle({ eSimTopUpModal: !toggle.eSimTopUpModal })}
          setServerErrors={setServerErrors}
          selected={selected}
        />
      )}
      {toggle.confirmUnlimitedPurchase && (
        <ConfirmPurchaseModal
          onToggle={() => handleToggle({ confirmUnlimitedPurchase: !toggle.confirmUnlimitedPurchase })}
        />
      )}
    </>
  );
};

export default inject(({ userStore, prepaidStore, saStore }) => ({ userStore, prepaidStore, saStore }))(observer(Bulk));
