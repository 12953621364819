import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import AddToPoolModal from 'components/TopUp/components/AddToPoolModal';
import ConfirmModal from 'components/TopUp/components/ConfirmModal';
import ConfirmPurchaseModal from './ConfirmPurchaseModal';
import Connect5GLTETopup from './Connect5GLTETopup';
import ConnectProtectTopup from './ConnectProtectTopup';
import React from 'react';
import useToggle from 'hooks/useToggle';

const TopupButtons = ({ userStore, prepaidStore, poolPlanStore }) => {
  const { toggle, handleToggle } = useToggle({
    mobileFilter: false,
    cloudTopup: false,
    connectTopup: false,
    expansionModule: false,
    confirmModal: false,
    addToPool: false,
    confirmUnlimitedPurchase: false,
  });

  const onToggleModal = (target) =>
    userStore.user.saToken
      ? handleToggle({ [target]: !toggle[target] })
      : handleToggle({ confirmModal: !toggle.confirmModal });

  return (
    <>
      <div className="items-center w-full md:flex">
        <div className="hidden w-20 mr-4 text-lg font-bold md:block">TOP UP</div>
        <div className="flex justify-between mb-2 md:hidden">
          <div className="text-lg font-bold">TOP UP</div>
          <div
            className="ml-5 text-lg font-bold cursor-pointer md:mt-1 text-disabled_text"
            onClick={() => {
              poolPlanStore.resetSelectedDevices();
              prepaidStore.resetSelectedDevices();
            }}
          >
            RESET
          </div>
        </div>
        <div className="flex">
          <button
            className="flex items-center w-auto px-2 py-2 mr-2 font-bold bg-black md:px-4 md:mr-4 text-primary justify-items-center"
            disabled={!prepaidStore.selectedCloudDevices.length}
            onClick={() => onToggleModal('cloudTopup')}
          >
            <i className="md:mr-2 material-icons">cloud</i>
            <div className="hidden md:block">
              SFC
              <br className="lg:hidden" />
              <span>{` (${prepaidStore.selectedCloudDevices.length})`}</span>
            </div>
            <span className="md:hidden">{`\u00A0Protect (${prepaidStore.selectedCloudDevices.length})`}</span>
          </button>
          <button
            className="flex items-center w-auto px-2 py-2 mr-2 font-bold bg-black md:px-4 md:mr-4 text-primary justify-items-center"
            disabled={!prepaidStore.selectedConnectDevices.length}
            onClick={() => onToggleModal('connectTopup')}
          >
            <i className="md:mr-2 material-icons">sim_card</i>
            <div className="hidden md:block">
              {`eSIM`}
              <br className="lg:hidden" />
              <span>{` (${
                prepaidStore.selectedConnectDevices.length
                  ? prepaidStore.selectedConnectDevices.map((device) => device.selected.length).reduce((a, b) => a + b)
                  : 0
              })`}</span>
            </div>
            <span className="md:hidden">{` (${
              prepaidStore.selectedConnectDevices.length
                ? prepaidStore.selectedConnectDevices.map((device) => device.selected.length).reduce((a, b) => a + b)
                : 0
            })`}</span>
          </button>
          {!isEmpty(poolPlanStore.poolPlan) && (
            <button
              className="w-auto py-2 font-bold bg-black md:px-4 text-primary md:w-auto"
              disabled={!poolPlanStore.selectedDevices.length}
              onClick={() => onToggleModal('addToPool')}
            >
              {/* lg size */}
              <div className="items-center hidden lg:flex">
                <i className="ml-2 icons icon-pool-plan-btn" />
                <span className="ml-2">Add to eSIM Pooled Plan ({poolPlanStore.selectedDevices.length})</span>
              </div>
              {/* md size */}
              <div className="items-center hidden md:flex lg:hidden">
                <i className="mx-2 icons icon-pool-plan-btn" />
                <div className="whitespace-no-wrap">
                  Add to eSIM <br className="md:hidden" />
                  Pooled Plan ({poolPlanStore.selectedDevices.length})
                </div>
              </div>
              {/* sm size */}
              <div className="flex items-center px-3 md:hidden gap-2">
                <i className="icons icon-pool-plan-btn" />
                <div className="whitespace-no-wrap">Pooled Plan ({poolPlanStore.selectedDevices.length})</div>
              </div>
            </button>
          )}
        </div>
        <div className="hidden md:flex">
          <div
            className="mt-4 ml-5 text-lg font-bold cursor-pointer md:mt-0 text-disabled_text"
            onClick={() => {
              poolPlanStore.resetSelectedDevices();
              prepaidStore.resetSelectedDevices();
            }}
          >
            RESET
          </div>
        </div>
      </div>
      {toggle.cloudTopup && (
        <ConnectProtectTopup
          onToggle={() => handleToggle({ cloudTopup: !toggle.cloudTopup })}
          onToggleConfirmPurchaseModal={() =>
            handleToggle({ confirmUnlimitedPurchase: !toggle.confirmUnlimitedPurchase })
          }
        />
      )}
      {toggle.connectTopup && (
        <Connect5GLTETopup onToggle={() => handleToggle({ connectTopup: !toggle.connectTopup })} />
      )}
      {toggle.confirmModal && <ConfirmModal onToggle={() => handleToggle({ confirmModal: !toggle.confirmModal })} />}
      {toggle.addToPool && (
        <AddToPoolModal onToggle={() => handleToggle({ addToPool: !toggle.addToPool })} fromOtherPage={true} />
      )}
      {toggle.confirmUnlimitedPurchase && (
        <ConfirmPurchaseModal
          onToggle={() => handleToggle({ confirmUnlimitedPurchase: !toggle.confirmUnlimitedPurchase })}
        />
      )}
    </>
  );
};

export default inject(({ userStore, prepaidStore, poolPlanStore }) => ({ userStore, prepaidStore, poolPlanStore }))(
  observer(TopupButtons)
);
