import { usePagination, useRowSelect, useTable } from 'react-table';
import cx from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import TableCheckbox from './TableCheckbox';
import TableFooter from './TableFooter';

const Table = forwardRef(
  (
    {
      columns,
      data,
      fetchData,
      pageCount: controlledPageCount,
      totalRow,
      defaultPageSize = 20,
      withSelectionColumn = false,
      tableType,
      manualPagination = false,
      onSelect,
      selectDisabledFunc,
      isHistoryTable = false,
    },
    ref
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      canPreviousPage,
      canNextPage,
      gotoPage,
      nextPage,
      previousPage,
      selectedFlatRows,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        pageCount: controlledPageCount,
        manualPagination: manualPagination,
        initialState: { pageSize: defaultPageSize },
      },
      usePagination,
      useRowSelect,
      // (useRowSelect = withSelection ? useRowSelect : null)
      (hooks) => {
        hooks.visibleColumns.push((columns) =>
          withSelectionColumn
            ? [
                {
                  id: 'selection',
                  Header: ({ toggleRowSelected, rows, selectedFlatRows }) => {
                    // code here will override all the select logic
                    //
                    const style = {
                      cursor: 'pointer',
                    };
                    let checked, oveRiddenOnChange, indeterminate, disabled;

                    if (!selectDisabledFunc) {
                      // original logic
                      checked = rows.length >= 1 && rows.length - selectedFlatRows.length === 0;
                      indeterminate = !checked && selectedFlatRows.length > 0;
                      disabled = rows.length === 0;
                      oveRiddenOnChange = (event) => {
                        rows.forEach((row) => {
                          toggleRowSelected(row.id, event.currentTarget.checked);
                        });
                      };
                    } else {
                      // code below is for fixing the bug which select all will select the disabled row
                      let rowsAvailable = rows.filter((row) => {
                        return !selectDisabledFunc(row);
                      });
                      checked = rowsAvailable.length >= 1 && rowsAvailable.length - selectedFlatRows.length === 0;
                      indeterminate = !checked && selectedFlatRows.length > 0;
                      disabled = rowsAvailable.length === 0;

                      // oveRiddenOnChange only work on select all
                      oveRiddenOnChange = (event) => {
                        rowsAvailable.forEach((row) => {
                          toggleRowSelected(row.id, event.currentTarget.checked);
                        });
                      };
                    }

                    const modifiedToggleAllRowsProps = {
                      onChange: oveRiddenOnChange,
                      style: style,
                      checked: checked,
                      indeterminate: indeterminate,
                      disabled: disabled,
                    };
                    return (
                      <div>
                        <TableCheckbox {...modifiedToggleAllRowsProps} />
                      </div>
                    );
                  },
                  Cell: ({ row }) => {
                    return (
                      <div>
                        <TableCheckbox
                          {...row.getToggleRowSelectedProps()}
                          disabled={selectDisabledFunc ? selectDisabledFunc(row) : false}
                        />
                      </div>
                    );
                  },
                  className: 'selection-box',
                },
                ...columns,
              ]
            : columns
        );
      }
    );

    useImperativeHandle(
      ref,
      () => ({
        selectedFlatRows: selectedFlatRows,
      }),
      [selectedFlatRows]
    );

    useEffect(() => {
      if (onSelect) {
        onSelect(selectedFlatRows);
      }
    }, [onSelect, selectedFlatRows]);

    useEffect(() => {
      if (fetchData) {
        fetchData({ pageSize, page: pageIndex + 1 });
      }
    }, [fetchData, pageSize, pageIndex]);

    return (
      <>
        <TableWrapperStyled>
          {rows.length > 0 ? (
            <table {...getTableProps()} className={cx('table', { 'add-device-table': tableType === 'addDevice' })}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <th {...column.getHeaderProps()} className={`header ${column.className}`} key={index}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps({ className: 'table-body align-text-top' })}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td {...cell.getCellProps()} className={`cell ${cell.column.className}`} key={index}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="pool-plan-empty-table">{isHistoryTable ? 'No record' : 'No subscribed device'}</div>
          )}
        </TableWrapperStyled>
        {manualPagination && rows.length > 0 && (
          <TableFooter
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={controlledPageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRow={totalRow}
          />
        )}
      </>
    );
  }
);

export default Table;

const TableWrapperStyled = styled.div({
  width: '100%',
  maxHeight: '70vh',
  overflow: 'scroll',

  '::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },

  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#fafafa',
  },

  '::-webkit-scrollbar-corner': {
    display: 'none',
  },

  '.table': {
    width: '100%',
  },

  '.header': {
    width: '100px',
    color: 'black',
    padding: '5px 10px 2px 10px',
    textAlign: 'start',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
    borderBottom: '1px solid #E5990D',
  },

  '.cell': {
    backgroundColor: 'white',
    padding: '15px 10px',
    borderBottom: '0.5px solid #B1B9C0',
    maxHeight: '40px',
    fontSize: 12,
  },

  '.add-device-table': {
    '.header': {
      color: '#DCDCDC',
    },

    '.table-body': {
      maxHeight: '50vh !important',
      overflowY: 'auto !important',
    },

    '.cell': {
      backgroundColor: 'black !important',
      height: '18px',
    },
  },

  '.ReactModal__Overlay--after-open': {
    zIndex: '100',
  },

  '.selection-box': {
    width: '20px !important',
  },
});
