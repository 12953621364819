import { dateFormat, dayDifference } from 'utils';
import cx from 'classnames';
import React from 'react';

const Unlimited = ({ item }) => {
  const exp = React.useMemo(() => item.sfwan_license.exp, [item.sfwan_license.exp]);

  return (
    <>
      <div className="sfc-card-title">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            SFC Usage Quota
            <div className="ml-2 text-sm text-primary">(up to 400Mbps)</div>
          </div>
        </div>
      </div>
      <div className="sfc-card-content">
        <div className="flex items-end">
          <div className="flex items-center mt-1 leading-tight">
            <i className="mr-2 icons icon-5g-plan" />
            <div className="items-end text-primary">
              <span className="remaining-sfc-usage-type">Unlimited</span>
            </div>
          </div>
        </div>
        <div className={cx('flex items-center', exp && dayDifference(exp) >= 0 ? dateFormat(exp) : 'text-danger')}>
          <i
            className={cx(
              'icons mr-2',
              exp && dayDifference(exp) >= 0 ? 'icon-sfc-expiry-usage-normal' : 'icon-sfc-expiry-usage-red'
            )}
          />
          {exp && dayDifference(exp) >= 0 ? dateFormat(exp) : 'Expired'}
        </div>
      </div>
    </>
  );
};

export default Unlimited;
