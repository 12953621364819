import { inject, observer } from 'mobx-react';
import { isEmpty, sortBy } from 'lodash';
import { Modal, ModalHeader } from 'components/common/Modal';
import Loading from 'components/common/Loading';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import Select from 'components/common/Select';
import SelectedSNList from './SelectedSNList';

const OPTIONS = {
  SN: 'sn',
  NAME: 'name',
  GROUP: 'grp_name',
  MODEL: 'product.name',
};

const ADD_BY_OPTIONS = [
  { label: 'Serial Number', value: OPTIONS.SN, errMsg: 'This Serial Number is already entry.' },
  { label: 'Device Name', value: OPTIONS.NAME, errMsg: 'This Device Name is already entry.' },
  { label: 'Group', value: OPTIONS.GROUP },
  { label: 'Model', value: OPTIONS.MODEL },
];

const AddICCID = ({ onToggle, userStore, prepaidStore, poolPlanStore }) => {
  const [addBy, setAddBy] = useState(ADD_BY_OPTIONS[0]);
  const [selectType, setSelectType] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [filterOpts, setFilterOpts] = useState([]);
  const [step, setStep] = useState(1);
  const [data, setData] = useState();

  // columns for react table (group or model)
  const columns = useMemo(
    () => [
      {
        Header: 'Serial Number',
        accessor: 'sn',
        sortable: false,
        Cell: (data) => (
          <div
            style={{
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
            }}
          >
            <div className=" whitespace-no-wrap">{data.value}</div>
            {data?.row?.original?.type === 'module' && (
              <div
                className="text-gray-500 whitespace-no-wrap cursor-default hover:text-primary transition-all duration-300"
                style={{
                  fontSize: 10,
                }}
              >
                Expansion module
              </div>
            )}
          </div>
        ),
      },
      { Header: 'Model', accessor: 'product', sortable: false, Cell: (props) => props?.cell?.value?.name || '-' },
      {
        Header: 'Device Name',
        accessor: 'name',
        sortable: false,
        Cell: (props) => props?.cell?.value || props?.row?.original?.masterName || '-',
      },
    ],
    []
  );

  const sn = useMemo(() => {
    switch (addBy?.value) {
      case OPTIONS.SN:
        return data;
      case OPTIONS.NAME:
        return data
          .filter((device, index, self) => index === self.findIndex((d) => d && d.name === device.name))
          .filter((device) => device?.name);
      case OPTIONS.GROUP:
        return data.filter((i) => {
          return i?.grp_name && i?.grp_name === selectType?.value;
        });
      case OPTIONS.MODEL:
        return data.filter((i) => i.product?.name && i.product?.name === selectType?.value);
      default:
        return data;
    }
  }, [addBy, selectType, data]);

  const genFilterByOptions = (items, by) => {
    return sortBy(
      items.reduce((pre, cur) => {
        const parts = by.split('.');
        let value = cur;
        for (let part of parts) {
          if (value[part]) {
            value = value[part];
          }
        }
        const added = pre?.some((i) => i?.value === value);
        return added || value === undefined
          ? pre
          : typeof value === 'string'
          ? [...pre, { label: value, value: value }]
          : pre;
      }, []),
      (i) => i.label
    );
  };

  // init data when change option
  const handleChange = (item) => {
    setAddBy(item);
    setSelectedData([]);

    let newFilterOpts = [];
    if ([OPTIONS.GROUP, OPTIONS.MODEL].includes(item.value)) {
      newFilterOpts = genFilterByOptions(data, item.value);
      setSelectType(newFilterOpts[0] || null);
    }

    setFilterOpts(newFilterOpts);
  };

  const onConfirm = () => {
    setStep(2);
  };

  const getData = useCallback(async () => {
    await prepaidStore.invalidateItems();
    await prepaidStore.getRoles(userStore.viewAs);
    await prepaidStore.getData(userStore.viewAs);
  }, [prepaidStore, userStore.viewAs]);

  const getPoolData = React.useCallback(async () => {
    await poolPlanStore.invalidateItems();
    if (!isEmpty(poolPlanStore.organizations.data) && !isEmpty(poolPlanStore.selectedOrganization)) {
      await poolPlanStore.getPool();
      await poolPlanStore.getDevices(false, userStore.viewAs);
    }
  }, [poolPlanStore, userStore.viewAs]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (userStore.viewAs) {
      userStore.getOtherUserData();
    } else {
      userStore.me(true);
    }
  }, [userStore.viewAs, userStore]);

  useEffect(() => {
    if (prepaidStore.DATA.length > 0) {
      const restructuredData = prepaidStore.DATA.flatMap((device) => {
        const masterDevice = { ...device, type: 'master' };
        if (masterDevice.modules && masterDevice.modules.length > 0) {
          masterDevice.modules.forEach((module) => {
            module.type = 'module';
            module.masterName = masterDevice?.name;
            module.grp_name = masterDevice?.grp_name;
          });
          const moduleList = masterDevice.modules.map((module) => ({
            ...module,
            type: 'module',
            masterName: masterDevice?.name,
            grp_name: masterDevice?.grp_name,
          }));
          return [masterDevice, ...moduleList];
        } else {
          return [masterDevice];
        }
      });
      setData(restructuredData);
    }
  }, [prepaidStore.DATA, onToggle]);

  useEffect(() => {
    getPoolData();
  }, [getPoolData]);

  return (
    <>
      <Modal size={step > 1 ? 'lg' : 'md'} containerClass="bg-black" onToggle={onToggle}>
        <ModalHeader onToggle={onToggle} classNames={{ modal: 'px-6 pt-5', closeBtn: 'px-6 pt-5' }}>
          <h3 className="add-modal-title">add iccid</h3>
        </ModalHeader>
        {sn ? (
          <>
            {step === 1 ? (
              <div className="add-modal">
                <div className="select-bar">
                  {/* select add by */}
                  <Select
                    label="Convert by"
                    options={ADD_BY_OPTIONS}
                    onChange={(item) => handleChange(item)}
                    defaultValue={addBy}
                    value={addBy}
                    labelClassName="text-sm font-medium text-white mb-1"
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.value}
                    remark=""
                  />
                  {/* select group or model */}
                  {(addBy.value === OPTIONS.GROUP || addBy.value === OPTIONS.MODEL) && (
                    <Select
                      label={addBy.label}
                      options={filterOpts}
                      onChange={(item) => setSelectType(item)}
                      defaultValue={selectType}
                      value={selectType}
                      labelClassName="text-sm font-medium text-white mb-1"
                      getOptionLabel={(item) => item.label}
                      getOptionValue={(item) => item.value}
                    />
                  )}
                </div>
                <div className="content">
                  {/* add by SN or Device Name */}
                  {(addBy.value === OPTIONS.SN || addBy.value === OPTIONS.NAME) && (
                    <Select
                      label={
                        addBy.value === OPTIONS.NAME
                          ? `Device Name${selectedData?.length > 1 ? 's' : ''}`
                          : `Serial Number${selectedData?.length > 1 ? 's' : ''}`
                      }
                      onChange={(value) => setSelectedData(value)}
                      options={sn}
                      defaultValue={selectedData}
                      value={selectedData}
                      labelClassName="text-sm font-medium text-white mb-1"
                      getOptionLabel={(item) => (addBy.value === OPTIONS.SN ? item.sn : item.name)}
                      getOptionValue={(item) => item.sn}
                      isMulti={true}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                  )}
                  {/* add by Group or Model */}
                  {(addBy.value === OPTIONS.GROUP || addBy.value === OPTIONS.MODEL) && (
                    <div className="modal-react-table">
                      <ReactTable
                        data={sn}
                        customKey={`${addBy?.value}-${sn?.length}`}
                        columns={columns}
                        withSelectionColumn
                        isModal={true}
                        options={{
                          hiddenColumns: [],
                          pager: false,
                          rowPerPage: false,
                          pageSize: 1,
                          defaultPageSize: sn.length,
                          isMulti: true,
                          onSelectionChanged: setSelectedData,
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="btn-bar">
                  <div className="flex items-center mr-6 text-primary">
                    {selectedData?.length > 0 ? `${selectedData?.length} selected` : ''}
                  </div>
                  <button className="btn btn-outline-primary" onClick={onConfirm} disabled={selectedData?.length === 0}>
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div className="add-modal">
                <SelectedSNList
                  onToggle={onToggle}
                  data={selectedData}
                  onBackBtn={() => {
                    setStep(1);
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div style={{ width: '100%', height: '200px' }}>
            <Loading />
          </div>
        )}
      </Modal>
    </>
  );
};

export default inject(({ userStore, prepaidStore, poolPlanStore }) => ({ userStore, prepaidStore, poolPlanStore }))(
  observer(AddICCID)
);
